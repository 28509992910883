.registration-page .white-block {
  padding: 16px;
  margin-bottom: 20px;
}

.registration-page .white-block h4 {
  color: #4A403F;
  font-size: 18px;
  font-weight: 600;
  line-height: 16px;
  padding-bottom: 15px;
  border-bottom: 2px solid #999;
  margin: 0 0 15px;
}

.registration-page .white-block ul {
  padding-left: 40px;
  margin-top: 17px;
}

.registration-page .white-block .registration-price {
  text-align: center;
  margin: 22px 0 6px;
}

.registration-page .policy {
  text-align: right;
}

.registration-page .policy button {
  margin: 0 0 20px;
}

.conditions.custom-checkbox-wrapper {
  margin-bottom: 20px;
}

.conditions.custom-checkbox-wrapper label {
  padding-left: 20px;
  margin-bottom: 0;
}

.conditions.custom-checkbox-wrapper label:hover {
  color: #999;
}

.conditions.custom-checkbox-wrapper label a {
  color: #3C83B0;
  font-weight: 700;
}

.conditions.custom-checkbox-wrapper label a:hover {
  text-decoration: underline;
}

.registration-price {
  position: relative;
  margin-bottom: 20px;
}

.registration-price span:first-child {
  font-size: 48px;
  font-weight: 800;
  letter-spacing: 2px;
  line-height: 40px;
}

.registration-price span:last-child {
  height: 17px;
  display: block;
}

.registration-price small {
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 16px;
}

.registration-price small.crm {
  color: #999999;
  font-weight: 600;
}

.registration-price .info-popup {
  position: absolute;
  top: 0;
}

.registration-price .strike {
  text-decoration: line-through;
}

.already-member {
  color: #999;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
}

.already-member a {
  color: #3C83B0;
}

.already-member a:hover {
  text-decoration: underline;
}

.registration-tile {
  max-width: 424px;
  margin: 20px auto;
  border-radius: 0 4px 4px 4px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  color: #4A403F;
  text-align: center;
}

.registration-tile h3 {
  margin: 0 0 20px;
  padding: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.registration-tile h3.logo-style {
  font-size: 24px;
  margin: 0 0 5px;
  line-height: 38px;
}

.registration-tile .btn {
  width: 100%;
  margin-bottom: 20px;
}

.registration-tile .crm-logo {
  margin-bottom: 5px;
}

.registration-tile-new {
  max-width: 500px;
  margin: 100px auto 0;
  border-radius: 4px;
  background: url("/themes/photo-club/images//auth/new/login-register-background.jpg") no-repeat center center;
  background-size: cover;
  padding: 10px 30px 15px 40px;
  color: #4A403F;
}

.registration-tile-new .price-badge {
  float: right;
}

.registration-tile-new .logo {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 220px;
}

.registration-tile-new .logo :not(.activate_mf) {
  float: left;
}

.registration-tile-new h1 {
  font-size: 27px;
  font-weight: 300;
  color: #211B1B;
}

.registration-tile-new h1 img {
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 11px;
}

.registration-tile-new .price-text {
  text-align: right;
  font-size: 41px;
  line-height: 40px;
  font-weight: 900;
  color: #211B1B;
  margin-bottom: 0;
}

.registration-tile-new .price-text span {
  font-size: 40px;
  font-weight: bold;
}

.registration-tile-new .price-description {
  color: #211B1B;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
}

.registration-tile-new .btn-primary {
  padding: 16px 12px;
  margin: 20px 0 40px;
  width: 100%;
}

.registration-tile-new ul {
  list-style-type: circle;
  padding-left: 20px;
  margin-top: 40px;
}

.registration-tile-new ul li {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}

.password-form-group {
  position: relative;
}

.password-form-group a {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  text-align: center;
  opacity: 0.5;
  font-size: 14px;
}

.password-form-group a:before {
  padding-top: 9px;
  display: block;
}

.password-form-group a:hover {
  opacity: 1;
}

#payment-form .label-title, #my-data-form .label-title {
  color: #999999;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  height: 32px;
  margin: 8px 0;
  padding-right: 0;
  display: table;
}

#payment-form .label-title span, #my-data-form .label-title span {
  display: table-cell;
  vertical-align: middle;
}

#payment-form .form-group, #my-data-form .form-group {
  margin-bottom: 0;
}

#payment-form .form-control, #my-data-form .form-control {
  margin: 8px 0;
  color: #555 !important;
}

#payment-form select, #my-data-form select {
  padding-right: 0;
}

#payment-form select.form-control, #my-data-form select.form-control {
  text-transform: uppercase;
  color: #555;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
}

#payment-form select.form-control option:disabled, #my-data-form select.form-control option:disabled {
  color: #C9C9C9;
}

#payment-form .custom-radio, #my-data-form .custom-radio {
  color: #4A403F;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  margin: 4px 10px 4px 0;
}

#payment-form .custom-radio label, #my-data-form .custom-radio label {
  cursor: pointer;
  line-height: 16px;
}

#payment-form .custom-radio label:before, #my-data-form .custom-radio label:before {
  margin-right: 0;
}

#payment-form ::-webkit-input-placeholder, #my-data-form ::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: uppercase;
  color: #C9C9C9;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
}

#payment-form :-moz-placeholder, #my-data-form :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: uppercase;
  color: #C9C9C9;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
}

#payment-form ::-moz-placeholder, #my-data-form ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: uppercase;
  color: #C9C9C9;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
}

#payment-form :-ms-input-placeholder, #my-data-form :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: uppercase;
  color: #C9C9C9;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
}

@media screen and (max-width: 550px) {
  .registration-tile-new {
    margin: 60px 0 20px;
    padding: 20px;
  }
  .registration-tile-new .logo {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    float: unset;
  }
  .registration-tile-new .price-badge {
    width: 100px;
    position: absolute;
    right: 30px;
    top: 115px;
  }
  .registration-tile-new h1 {
    width: 150px;
    margin: 20px auto;
    font-size: 18px;
    line-height: 30px;
  }
  .registration-tile-new h1 img {
    margin-right: unset;
    margin-left: unset;
    margin-bottom: 5px;
  }
  .registration-tile-new .price-text {
    text-align: center;
  }
  .registration-tile-new .price-description {
    text-align: center;
  }
  .registration-tile-new ul {
    margin-top: 0;
  }
}

@media screen and (max-width: 768px) {
  .conditions.custom-checkbox-wrapper {
    margin: 20px 0;
  }
  .registration-tile h3 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px;
  }
  .registration-price span:first-child {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 40px;
  }
  .registration-price .info-popup {
    font-size: 18px;
  }
  .registration-page .white-block {
    margin-bottom: 30px;
    margin-top: 10px;
  }
  .registration-page .white-block h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .registration-page .white-block ul {
    margin-top: 10px;
    padding-left: 23px;
  }
  .registration-page .policy {
    text-align: left;
    margin-top: -15px;
  }
  .registration-page .policy button {
    margin: 20px 0;
    display: block;
    width: 100%;
  }
  #payment-form .label-title, #my-data-form .label-title {
    height: 30px;
    line-height: 30px;
    padding-top: 7px;
    margin: 0;
  }
  #payment-form .form-control, #my-data-form .form-control {
    margin: 0 0 8px;
  }
}

.modal-header {
  display: none;
}

.payment-page {
  max-width: 435px;
  margin: auto;
}

.payment-page .white-block {
  margin: 0 0 16px;
  border: 1px solid transparent;
}

.payment-page .white-block .indicator {
  display: none;
}

.payment-page .white-block .loading {
  position: relative;
}

.payment-page .white-block .loading:after {
  content: '';
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
}

.payment-page .white-block .loading .indicator {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
  left: 0;
  right: 0;
  width: 45px;
  height: 45px;
  text-align: center;
}

.payment-page .white-block .form-content {
  display: none;
}

.payment-page .white-block .custom-radio label {
  cursor: pointer;
  width: 100%;
  position: relative;
  padding: 28px;
}

.payment-page .white-block .custom-radio label img {
  position: absolute;
  right: 28px;
}

.payment-page .white-block.active {
  border: 1px solid #3C83B0;
  padding: 28px;
}

.payment-page .white-block.active .form-content {
  display: block;
}

.payment-page .white-block.active .custom-radio label {
  padding: 0;
}

.payment-page .white-block.active .custom-radio img {
  right: 0;
}

.payment-page .white-block p {
  padding-left: 35px;
  margin: 16px 0 16px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
}

.payment-page .white-block form {
  padding-left: 35px;
  margin-bottom: 20px;
}

.payment-page .white-block form#adyenForm {
  margin-bottom: 0;
}

.payment-page .white-block form .cols-inline .row {
  padding: 0 15px;
}

.payment-page .white-block form .cols-inline .row .col-xs-6 {
  padding: 0;
}

.payment-page .white-block form .cols-inline .row .col-xs-6:first-child {
  padding-right: 8px;
}

.payment-page .white-block form a {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 45px;
  color: #3C83B0;
}

.payment-page .white-block form .payment-btn {
  padding-left: 0;
}

.payment-page .white-block .payment-btn {
  padding-left: 35px;
}

.payment-page .white-block .payment-btn .btn {
  width: 100%;
  display: block;
}

.payment-page .cvc-modal .modal-container p {
  padding-left: 0;
}

.payment-page .cvc-modal .modal-container .card {
  text-align: center;
  border: 1px solid #ccc;
  padding: 20px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.payment-page .cvc-modal .modal-container .card p {
  padding: 20px 0 0;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .payment-page .white-block {
    margin-bottom: 24px;
  }
  .payment-page .white-block #payment-form.credit-card-form {
    margin-top: -10px;
  }
  .payment-page .white-block #payment-form .form-control {
    margin: 0;
  }
  .payment-page .white-block label img {
    height: 16px;
  }
  .payment-page .white-block p {
    padding-left: 0;
  }
  .payment-page .white-block form {
    padding-left: 0;
  }
  .payment-page .white-block form a {
    line-height: 32px;
  }
  .payment-page .white-block .payment-btn {
    padding-left: 0;
  }
  .payment-page .white-block .payment-btn .btn {
    white-space: normal;
  }
}

.confirm-page {
  max-width: 435px;
  margin: auto;
}

.confirm-page .white-block {
  padding: 24px 24px 20px;
  margin-bottom: 20px;
}

.confirm-page .white-block img {
  display: block;
  width: 48px;
  height: 48px;
  margin: 0 auto 27px;
}

.confirm-page .white-block p {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  margin-bottom: 20px;
}

.confirm-page .white-block ul {
  padding-left: 24px;
}

.confirm-page .white-block ul li {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  padding: 4px;
}

.confirm-page .white-block ul li a {
  color: #3C83B0;
}

.confirm-page .white-block ul li a:hover {
  text-decoration: underline;
}

.confirm-page.welcome p {
  text-align: center;
}

.membership-hint {
  color: #999999;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
  margin: 16px 0 0;
}

.award2018-content-image {
  background: url("/themes/photo-club/images//award2018/auth-background.jpg") no-repeat center center;
  background-size: cover;
  background-position: 50% 20%;
}

.picanova-award-bg-image {
  background: url("/themes/photo-club/images//award2018/picanova_award_bg.jpg") no-repeat center center;
  background-size: cover;
}

.mountain-background {
  background: url("/themes/photo-club/images/award2018/picanova_award_bg.jpg") no-repeat center center;
  background-size: cover;
  width: 100%;
}

.top-100-background {
  background: url("/themes/photo-club/images/award2018/photokina_blue.jpg") no-repeat center center;
  background-size: cover;
  width: 100%;
}

.award2018-default-container {
  background: #F7F7F9;
  width: 100%;
}

.award2018-modal-title {
  color: #FFFFFF;
  font-size: 28px;
  font-weight: bold;
}

.award2018-modal-lists {
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 120px;
  color: #ffffff;
}

.award2018-modal-lists li {
  margin-bottom: 10px;
  padding-left: 5px;
}

.award2018-modal-logo {
  width: 250px;
}

.modal-description {
  line-height: 60px;
  font-size: 28px;
  font-weight: 300;
}

.modal-description .bold {
  font-weight: 700;
  display: block;
}

.modal-logo {
  width: 250px;
}

.award2018-index .title-line {
  font-size: 28px;
  font-weight: bold;
  margin-top: 30px;
}

.award2018-index .subtitle {
  font-size: 22px;
}

.award2018-index label.btn {
  padding: 15px 75px;
}

.award2018-index ul.bullets {
  font-size: 16px;
  list-style: none;
  padding: 40px 0 0 15px;
}

.award2018-index ul.bullets li {
  margin-bottom: 40px;
}

.award2018-index ul.bullets li:before {
  color: #FA6362;
  content: "\f00c";
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -19px;
  margin-right: 10px;
  float: left;
  height: 23px;
}

.award2018-index .note {
  font-size: 12px;
}

.award2018-index.award2018-info {
  min-height: 1000px;
}

@media (max-width: 767px) {
  .photokina-terms h2 {
    font-size: 18px;
  }
}

.award2018 {
  position: relative;
  max-width: 620px;
  margin: 100px auto 100px;
  border-radius: 4px;
  background: url("/themes/photo-club/images//auth/new/login-register-background.jpg") no-repeat center center;
  background-size: cover;
  padding: 10px 0 15px;
  color: #4A403F;
}

.award2018 .small-header-image {
  width: 100%;
  margin: 10px 0;
}

.award2018.prizes-page {
  background: transparent;
  max-width: 700px;
  margin: 10px auto 0;
}

.award2018.prizes-page a {
  color: #3f86b3;
}

.award2018.prizes-page .prizes-title {
  font-size: 28px;
  margin-bottom: 25px;
}

.award2018.prizes-page .prizes-description {
  font-size: 16px;
  margin-bottom: 25px;
}

.award2018.prizes-page ol {
  font-size: 16px;
  padding-left: 0;
  margin-bottom: 80px;
}

.award2018.prizes-page ol li {
  margin-top: 10px;
  padding-left: 5px;
}

.award2018.top-100 {
  background: transparent;
  max-width: 1000px;
  margin: 50px auto 50px;
}

.award2018.top-100 .top-100-title {
  font-size: 28px;
  margin-bottom: 35px;
}

.award2018.top-100 ul li {
  max-width: 520px;
  color: #FFFFFF;
}

.award2018.top-100 .content-color {
  color: #454553;
}

.award2018.worth-page {
  background: transparent;
  max-width: 1150px;
  margin-top: 70px;
}

.award2018.worth-page .worth-title {
  font-size: 28px;
  margin-bottom: 35px;
}

.award2018.worth-page .worth-description {
  font-size: 16px;
  margin-bottom: 35px;
}

.award2018.worth-page ul li {
  color: #49A0D5;
}

.award2018.worth-page .content-color {
  color: #454553;
}

.award2018.worth-page .worth-register {
  margin-top: 40px;
  max-width: 240px;
}

.award2018.worth-page .deadline {
  margin-bottom: 70px;
}

.award2018.info-page {
  background: transparent;
  max-width: 1150px;
  margin-top: 40px;
}

.award2018.info-page .info-title {
  font-weight: bold;
  font-size: 28px;
}

.award2018.info-page .info-logo {
  margin-top: 50px;
  margin-bottom: 35px;
}

.award2018.info-page .info-logo.picanova {
  width: 150px;
}

.award2018.info-page .info-logo.photo-club {
  width: 120px;
}

.award2018.info-page .info-description {
  font-size: 16px;
  text-align: center;
  margin-bottom: 70px;
}

.award2018.landing-page {
  background: transparent;
  max-width: none;
  margin: 30px auto 0;
}

.award2018.landing-page .logos img {
  margin: 10px 30px 0;
}

.award2018.landing-page .photokina-logo {
  width: 200px;
}

.award2018.landing-page .landing-title {
  font-weight: 900;
  margin-top: 100px;
  font-size: 72px;
}

.award2018.landing-page .landing-description {
  font-size: 18px;
  font-weight: bold;
}

.award2018.landing-page .landing-register {
  margin-top: 120px;
  max-width: 400px;
}

.award2018.landing-page .btn {
  font-size: 14px;
}

.award2018 .deadline {
  margin-top: 15px;
  font-size: 16px;
  margin-bottom: 20px;
}

.award2018 .deadline a {
  text-decoration: underline;
  color: #4A403F;
}

.award2018 .logos {
  text-align: center;
}

.award2018 .logos img {
  display: inline-block;
  margin: 10px 0 0;
}

.award2018 .pc-logo {
  float: right;
  margin: 0 20px 0;
}

.award2018 .award-content {
  padding: 0 20px;
}

.award2018.picanova-award-bg {
  background: rgba(247, 247, 247, 0.6);
  max-width: 620px;
  margin: 30px auto;
}

.award2018.picanova-award-bg .award-pc-logo {
  display: block;
  margin: 20px auto 30px;
  width: 100%;
  max-width: 337px;
  padding: 0 10px;
}

.award2018 .inside-circle {
  border-radius: 50%;
  background: white;
  width: 105px;
  height: 105px;
  position: absolute;
  left: 20px;
  top: 20px;
  display: table;
}

.award2018 .inside-circle p {
  transform: rotate(-20deg);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  width: 107px;
  display: table-cell;
  vertical-align: middle;
}

.award2018 .inside-circle p span {
  font-size: 16px;
  font-weight: 900;
}

.award2018 .inside-circle p span.euro {
  font-size: 22px;
  line-height: 0;
}

.award2018 .outside-circle {
  border-radius: 50%;
  background: transparent;
  width: 145px;
  height: 145px;
  border: 1px solid white;
  position: absolute;
  top: -40px;
  left: -60px;
  z-index: 100;
}

.award2018 ul {
  list-style-type: circle;
  padding-left: 20px;
  margin-top: 10px;
}

.award2018 ul li {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 9px;
}

.award2018 ul li.picanova-award {
  color: #26252D;
}

.award2018 .btn-primary {
  padding: 14px 12px;
  width: 100%;
}

.award2018 .btn-primary.href-label {
  width: auto;
  padding: 10px 30px;
}

.award2018 .logo {
  margin-top: 30px;
  margin-bottom: 20px;
  width: 220px;
}

.award2018 h1 {
  color: #211B1B;
  font-size: 46px;
  font-weight: 300;
}

.award2018 h1 span {
  display: block;
  font-weight: 900;
  font-size: 76px;
}

.award2018 h3 {
  font-size: 28px;
  color: #454553;
  font-weight: bold;
}

.award2018 .text {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

.award2018 .text.picanova-award {
  color: #26252D;
}

.award2018 .text.small {
  font-size: 12px;
}

.award2018 .title {
  color: #26252D;
  font-size: 22px;
  font-weight: 600;
}

.award2018 .info {
  padding: 0 20px;
}

.award2018 .href {
  color: #49A0D5;
  font-weight: 600;
}

@media (max-width: 767px) {
  .top-100-background {
    background: url("/themes/photo-club/images/award2018/photokina_blue.jpg") no-repeat;
    background-size: cover;
  }
  .award2018.landing-page .logos {
    display: flex;
    justify-content: space-between;
    height: 40px;
  }
  .award2018.landing-page .logos img {
    margin: 10px 7px 0;
  }
  .award2018.landing-page .logos .photokina-logo {
    width: 120px;
  }
  .award2018.landing-page .landing-title {
    font-size: 40px;
    text-align: left;
    margin-top: 40px;
  }
  .award2018.landing-page .landing-description {
    font-size: 14px;
  }
  .award2018.landing-page .award-content {
    text-align: left;
  }
  .award2018.landing-page .landing-register {
    margin-top: 50px;
  }
  .top-100-page .top-100-title {
    text-align: center;
    font-size: 22px;
  }
  .top-100-page ul {
    padding-left: 20px;
    margin-bottom: 40px;
  }
  .top-100-page ul li {
    font-size: 14px !important;
  }
  .top-100-page img {
    width: 500px;
  }
  .worth-page {
    margin-top: 10px;
    margin-bottom: 0;
  }
  .worth-page .worth-title {
    font-size: 24px;
    text-align: center;
  }
  .worth-page .worth-description {
    font-size: 14px;
    text-align: center;
  }
  .worth-page ul {
    font-size: 14px;
  }
  .worth-page .worth-register {
    margin-left: 40px;
  }
  .worth-page .btn {
    font-size: 14px;
  }
  .info-page {
    margin-bottom: 0;
  }
  .info-page .info-description {
    margin-bottom: 0;
  }
  .modal-description {
    line-height: 40px;
  }
  .award2018 ul li {
    font-size: 14px;
  }
  .award2018.prizes-page ol {
    font-size: 14px;
    padding-left: 15px;
  }
  .award2018.prizes-page .prizes-title {
    font-size: 24px;
  }
  .award2018.prizes-page .prizes-description {
    font-size: 14px;
  }
  .award2018 .deadline {
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
  }
  .award2018 .outside-circle {
    width: 100px;
    height: 100px;
    left: 0;
    top: -60px;
  }
  .award2018 .outside-circle .inside-circle {
    width: 85px;
    height: 85px;
    left: 6px;
    top: 7px;
  }
  .award2018 .outside-circle .inside-circle p {
    width: 90px;
    font-size: 10px;
  }
  .award2018 .outside-circle .inside-circle p span {
    font-size: 13px;
  }
  .award2018 .outside-circle .inside-circle p span.euro {
    font-size: 15px;
  }
  .award2018 h1 {
    font-size: 26px;
  }
  .award2018 h1 span {
    display: block;
    font-weight: 900;
    font-size: 46px;
  }
  .picanova-award-bg-image {
    background: url("/themes/photo-club/images//award2018/picanova_award_bg_mob.jpg") no-repeat center center;
    background-size: cover;
  }
}

.award2018-top100-container {
  padding: 0;
}

.award2018-top100-container .top100-button {
  margin-bottom: 250px;
}

.award2018-top100-container .img-responsive {
  transition: all .4s ease-in-out;
}

.award2018-top100-container .img-responsive:hover {
  transform: scale(1.5);
  z-index: 9999;
  position: relative;
}

@media (max-width: 375px) {
  .award2018.landing-page .logos img {
    margin: 10px 3px 0;
    width: 80px;
  }
  .award2018.landing-page .logos .photokina-logo {
    width: 80px;
  }
}

.auto-fill-transparent-input input:-webkit-autofill, .auth-box.auth-box-new input:-webkit-autofill,
.auto-fill-transparent-input input:-webkit-autofill:hover,
.auth-box.auth-box-new input:-webkit-autofill:hover,
.auto-fill-transparent-input input:-webkit-autofill:focus,
.auth-box.auth-box-new input:-webkit-autofill:focus,
.auto-fill-transparent-input input:-webkit-autofill:active,
.auth-box.auth-box-new input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.full-content-height {
  background: url("/themes/photo-club/images//auth/new_auth_background_10_06.jpg") no-repeat center center;
  background-size: cover;
}

.full-content-height .auth-content {
  padding: 80px 0 150px;
  background-position: bottom;
  min-height: inherit;
}

.full-content-height .auth-content.container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}

.rewardo-bg {
  background: url("/themes/photo-club/images/rewardo/bg.png") no-repeat center center;
  background-size: cover;
}

.radio-auth-modal .custom-radio-button {
  border: 2px solid #454553;
  color: #454553;
  padding: 4px 18px;
  font-size: 14px;
  opacity: 0.5;
  margin-right: 17px;
}

.radio-auth-modal input:checked + .custom-radio-button {
  border-color: #3C83B0;
  color: #3C83B0;
}

.meinfoto-content-image {
  background: url("/themes/photo-club/images//auth/new/mf-background.jpg") no-repeat center center;
  background-size: cover;
}

.auth-modal-container {
  /* General Modal */
  /* Login Side*/
  /* Info Side */
}

.auth-modal-container .modal-wrapper {
  padding-left: 10px;
  padding-right: 10px;
}

.auth-modal-container .modal-container, .auth-modal-container .modal-wrapper .close-modal {
  max-width: 905px !important;
  width: 100%;
}

.auth-modal-container .modal-container {
  padding: 0 !important;
  border-radius: 6px;
}

.auth-modal-container .modal-header {
  display: none;
}

.auth-modal-container .modal-body {
  margin: 0;
  padding: 0;
}

.auth-modal-container .lato-h1 {
  font-size: 28px;
  font-weight: bold;
}

.auth-modal-container .lato-h2 {
  font-size: 22px;
}

.auth-modal-container .lato-h3 {
  font-size: 16px;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .auth-modal-container .col-md-7 {
    width: 54%;
  }
}

@media screen and (min-width: 769px) {
  .auth-modal-container .col-md-5 {
    width: 46%;
  }
}

.auth-modal-container .modal-login-header {
  margin-top: 20px;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  color: #26252D;
  line-height: 36px;
  margin-bottom: 30px;
}

@media screen and (max-width: 769px) {
  .auth-modal-container .modal-login-header {
    margin: auto;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}

.auth-modal-container .modal-login-header.right-side-head {
  margin-bottom: 80px;
}

.auth-modal-container .modal-login-header span {
  color: #b8b9bd !important;
  font-weight: normal !important;
}

.auth-modal-container .login-split div {
  display: inline-block;
}

.auth-modal-container .login-form-div {
  padding: 20px;
}

.auth-modal-container .login-input-style {
  width: 395px;
  padding: 25px !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none;
  border-bottom: solid 1px #D5D5DB !important;
  font-weight: 400;
}

@media screen and (max-width: 825px) {
  .auth-modal-container .login-input-style {
    width: 325px;
  }
}

@media screen and (max-width: 769px) {
  .auth-modal-container .login-input-style {
    width: 100%;
  }
}

.auth-modal-container .login-custom-label {
  font-size: 12px;
  color: rgba(69, 69, 83, 0.5);
  padding-left: 25px;
  margin-bottom: -10px;
  font-weight: 400;
  transition: transform 0.3s;
}

.auth-modal-container .eye-icon {
  color: #26252D;
  margin-top: 34px;
  background-image: url("/themes/photo-club/images/auth/eye-icon.svg");
  background-repeat: no-repeat;
  width: 32px;
  height: 16px;
  transform: scale(0.6);
  transition: opacity .3s;
  opacity: 1;
}

.auth-modal-container .eye-icon.active {
  opacity: 0.7;
}

.auth-modal-container .form-control {
  color: #26252D !important;
  font-size: 16px !important;
  font-weight: 400;
}

.auth-modal-container .login-button {
  display: inline-block;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  width: 100%;
}

.auth-modal-container .facebook-button {
  border: 3px solid #9aa9c9;
  border-radius: 40px;
  color: #3B5998;
  padding: 9px;
  transition: color .3s, border-color .3s;
  background-color: transparent;
}

.auth-modal-container .facebook-button:hover {
  border-color: #3B5998;
  color: #263961 !important;
}

.auth-modal-container .submit-group {
  margin-bottom: 20px;
  width: 100%;
}

.auth-modal-container .submit-group .text-center {
  margin: auto;
  margin-top: 5px;
  margin-bottom: 20px;
  width: 100%;
}

.auth-modal-container .submit-group p {
  margin: auto;
}

.auth-modal-container .fa-facebook {
  padding-right: 5px;
}

.auth-modal-container .already-member {
  font-weight: 400;
  margin: auto auto 17px;
  color: #B7B7BC;
}

.auth-modal-container .already-member a {
  font-weight: 700;
}

.auth-modal-container .password-form-group {
  margin-bottom: 45px;
}

.auth-modal-container .container-content {
  margin: auto;
}

.auth-modal-container .custom-label-translate {
  transform: scale(1.2) translateY(25px);
}

.auth-modal-container .login-half-size {
  width: 185px;
}

@media screen and (max-width: 825px) {
  .auth-modal-container .login-half-size {
    width: 145px;
  }
}

@media screen and (max-width: 769px) {
  .auth-modal-container .login-half-size {
    width: 100%;
  }
}

.auth-modal-container .name-input {
  padding-left: 0;
}

@media screen and (max-width: 769px) {
  .auth-modal-container .name-input {
    padding-right: 0;
  }
}

.auth-modal-container .lastname-input {
  padding-right: 0;
}

@media screen and (max-width: 769px) {
  .auth-modal-container .lastname-input {
    padding-left: 0;
  }
}

.auth-modal-container .custom-checkbox-wrapper {
  margin: auto auto 20px;
}

.auth-modal-container .custom-checkbox-wrapper label:before {
  border-width: 2px;
  border-radius: 3px;
  background-color: #fff;
}

@media screen and (max-width: 400px) {
  .auth-modal-container .custom-checkbox-wrapper label {
    max-width: 250px;
  }
}

.auth-modal-container .salutation-select {
  text-indent: 25px;
  height: 57px !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 !important;
}

.auth-modal-container .photo-club-background {
  background-image: url("/themes/photo-club/images/auth/new/login-register-background.jpg");
  height: 672px;
  margin-top: -2px;
  margin-bottom: -1px;
  margin-left: -2px;
  -webkit-background-size: cover;
  background-size: cover;
}

.auth-modal-container .photo-club-background.registration_free {
  height: 743px;
}

@media screen and (max-width: 769px) {
  .auth-modal-container .photo-club-background {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 450px !important;
    margin: -3px 0 0 0;
  }
}

@media screen and (max-width: 420px) {
  .auth-modal-container .photo-club-background {
    height: 440px !important;
  }
}

@media screen and (max-width: 370px) {
  .auth-modal-container .photo-club-background {
    height: 490px !important;
  }
}

.auth-modal-container .logo-motto {
  background-image: url("/themes/photo-club/images/auth/new/login-logo.svg");
  background-repeat: no-repeat;
  height: 67px;
  width: 236px;
  margin: 50px auto auto;
  background-size: contain;
}

.auth-modal-container .logo-motto.en {
  background-image: url("/themes/photo-club/images/auth/new/login-logo-en.svg") !important;
}

.auth-modal-container .welcome-paragraph {
  font-size: 36px;
  font-weight: 300;
  color: #211B1B;
  padding-left: 25px;
  margin-top: 50px;
}

.ul-features li {
  list-style-image: url("/themes/photo-club/images/auth/new/checkmark-circle.svg");
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px;
  margin-top: 10px;
}

.ul-features li a {
  color: #fff;
}

.ul-features li.li-blue {
  list-style-image: url("/themes/photo-club/images/auth/new/checkmark-circle-blue.svg");
}

.logout {
  border: 1px solid #4A403F;
  border-radius: 2px;
  padding: 8px 15px;
  word-spacing: 2px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.not-registered, .count-down-timer {
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.not-registered a, .count-down-timer a {
  color: #3C83B0;
}

.not-registered .text, .count-down-timer .text {
  margin-right: 15px;
}

.nopadding {
  padding: 0;
}

.pc-logo {
  max-width: 265px;
  margin: 50px 0 25px;
}

.telekom-logo {
  max-width: 152px;
  margin: 0 20px;
}

.rewardo-logo {
  max-width: 200px;
  margin: 0 20px;
}

.kik-logo {
  max-width: 150px;
  margin: 0 20px;
}

.sparkasse-logo {
  max-width: 100px;
  margin: 0 30px;
}

@media screen and (max-width: 1200px) {
  .sparkasse-logo {
    margin-top: -30px !important;
    padding: 10px;
  }
}

.haspa-logo {
  max-width: 230px;
  margin: 0 30px;
}

@media screen and (max-width: 1200px) {
  .haspa-logo {
    margin-top: -30px !important;
    padding: 10px;
  }
}

.douglas-logo {
  max-width: 230px;
  margin: 0 20px;
}

.fti_touristik-logo {
  max-width: 120px;
}

@media screen and (min-width: 1200px) {
  .fti_touristik-logo {
    margin-bottom: -20px;
    margin-left: 30px;
  }
}

.mobilcomdebitel-logo {
  max-width: 220px;
  margin: 0 20px;
}

.tui_cruises-logo {
  max-width: 220px;
  margin: 0 20px;
}

.weltbild-logo {
  max-width: 220px;
  margin: 0 20px;
}

.xing-logo {
  max-width: 220px;
  margin: 0 20px;
}

.wuestenrot-logo {
  max-width: 220px;
  margin: 0 20px;
}

.picturepeople-logo {
  max-width: 220px;
  margin: 20px 20px 0;
}

.photokina-logo {
  max-width: 240px;
  margin: 20px 10px 0;
}

.volksbank-logo {
  max-width: 240px;
  margin: 20px 10px 0;
}

.messefrankfurt-logo {
  max-width: 240px;
  margin: 20px 10px 0;
}

.glossybox-logo {
  max-width: 240px;
  margin: 20px 10px 0;
}

.extend-logo {
  max-width: 240px;
  margin: 20px 10px 0;
}

.page-block {
  background-color: #F7F7F7;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  color: #4A403F;
}

.page-block .information {
  margin-left: 40px;
  padding-bottom: 40px;
}

.page-block .description {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
}

.page-block .img-articles {
  height: 270px;
}

.page-block .product {
  padding-top: 15px;
  font-size: 14px;
}

.page-block .product .fa-check-circle {
  font-size: 24px;
  color: #FA6362;
  float: left;
  padding-right: 20px;
}

.page-block .product span {
  font-weight: 800;
}

.page-block .product img {
  float: left;
  height: 35px;
  width: 35px;
  margin-right: 15px;
}

.free-registration-title {
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 2px;
  line-height: 40px;
  margin: 0;
  text-align: center;
  padding: 20px 0 45px;
  max-width: 580px;
  margin: auto;
}

.mein-extend-page .free-registration-title {
  font-size: 28px;
}

.auth-box {
  width: 100%;
  max-width: 450px;
  opacity: 0.9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 0;
  padding: 20px;
  border: 1px solid white;
  background-color: white;
  margin: auto;
  font-size: 11px;
  font-weight: 600;
  position: relative;
}

.auth-box.auth-box-new {
  max-width: 905px;
  opacity: 1;
  border-radius: 8px;
  padding: 0;
  background-color: #F7F7F9;
  border-color: #F7F7F9;
}

.auth-box.auth-box-new #login-form-div {
  padding: 20px 40px 0 47px;
}

.auth-box.auth-box-new .eye-icon {
  position: absolute;
  right: 0;
}

.auth-box.auth-box-new .login-or-facebook {
  font-size: 12px;
  color: #B7B7BC;
}

.auth-box.auth-box-new .conditions label {
  color: #B7B7BC;
}

.auth-box.auth-box-new .form-group .login-input-style {
  background-color: transparent;
}

.auth-box .description-second {
  letter-spacing: 1px !important;
  font-size: 12px !important;
  line-height: 20px !important;
  font-weight: bold !important;
}

@media screen and (max-width: 768px) {
  .auth-box {
    border: none;
  }
}

.auth-box .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.auth-box .text {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin: 20px;
}

.auth-box .bottom-text {
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
}

.auth-box .telekom-link {
  color: white;
  font-weight: 900;
  text-decoration: underline;
}

.auth-box .telekom-register {
  margin: 20px 0 0 0;
}

.auth-box .auth-register {
  margin: 20px 0 0 0;
}

.auth-box.free-registration {
  margin-bottom: 40px;
}

.auth-box .already-member {
  padding-top: 20px;
}

.auth-box .for-free-label {
  position: absolute;
  height: 96px;
  width: 96px;
  background-color: #FA6362;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  color: #fff;
  right: -48px;
  top: -48px;
  text-align: center;
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  -o-transform: rotate(10deg);
  transform: rotate(10deg);
}

.auth-box .for-free-label .for-free-wrapper {
  display: table;
  box-sizing: border-box;
  height: 84.2px;
  width: 84.2px;
  border: 0.79px solid #FFFFFF;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  position: absolute;
  top: 6px;
  bottom: 6px;
  left: 6px;
  right: 6px;
  margin: inherit;
}

.auth-box .for-free-label .for-free-wrapper span {
  font-size: 14.16px;
  font-weight: 800;
  line-height: 18px;
  text-align: center;
  display: block;
  border-bottom: 1px solid #fff;
  margin: 10px 10px 5px;
  padding-bottom: 5px;
  text-decoration: line-through;
}

.auth-box .for-free-label .for-free-wrapper p {
  font-size: 9.44px;
  font-weight: 600;
  line-height: 12px;
  margin: 0;
  padding: 0;
}

.auth-box .for-free-label .for-free-wrapper p.coop {
  display: table-cell;
  vertical-align: middle;
  line-height: 17px;
  font-size: 12px;
}

.auth-box .for-free-label .for-free-wrapper p strong {
  display: block;
  font-size: 14.16px;
  font-weight: 800;
  line-height: 18px;
  text-align: center;
}

.auth-box.free-registration h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 20px;
  text-align: center;
  padding: 0 60px;
}

.auth-box.free-registration h3.advantages-title {
  padding: 0;
}

.auth-box.free-registration h3.membership-later-title {
  margin: 20px 0 10px;
}

.auth-box.free-registration .email-info {
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 16px;
  margin-bottom: 20px;
}

.auth-box.free-registration .conditions {
  margin-bottom: 15px;
  white-space: normal;
}

.auth-box.free-registration .submit-group {
  margin-bottom: 20px;
}

.auth-box.free-registration ul {
  margin-bottom: 0;
}

.auth-box .remember-me {
  margin: 0;
  padding-left: 0;
}

.auth-box a.password {
  color: #999999;
  font-size: 12px;
  font-weight: 600;
}

.auth-box a:hover {
  color: #3f86b3;
}

.auth-box .checkbox {
  margin-top: 0;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .auth-box {
    width: 100%;
    padding: 10px;
    margin-bottom: 40px;
  }
}

.tab-box {
  width: 450px;
  margin: auto;
  font-size: 11px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .tab-box {
    width: 0;
    display: table;
    margin: 0 auto;
  }
}

.error-box {
  width: 450px;
  margin: auto;
  opacity: 0.9;
  font-size: 11px;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .error-box {
    width: 100%;
  }
}

.icon-box {
  padding-bottom: 40px;
  display: flex;
  align-items: end;
}

.icon-box p {
  padding-left: 10px;
  color: #4A403F;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 24px;
}

@media screen and (max-width: 768px) {
  .icon-box p {
    width: 100%;
  }
}

div.tab {
  opacity: 0.8;
  overflow: hidden;
  width: auto;
  margin-right: 2px;
  float: left;
  border-radius: 4px 4px 0 0;
  background-color: #F1F1F2;
}

div.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 5px 10px;
  color: #999999;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 16px;
}

div.tab button.active {
  color: #3f86b3;
  background-color: #FFFFFF;
}

.auth-box ::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: uppercase;
}

.auth-box :-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: uppercase;
}

.auth-box ::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: uppercase;
}

.auth-box :-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: uppercase;
}

.auth-header {
  padding: 10px 0;
}

.auth-header span {
  padding-right: 10px;
}

.auth-footer {
  display: block;
  bottom: 0;
  padding: 50px 0;
}

@media screen and (max-width: 768px) {
  .auth-footer {
    padding: 50px 0 0;
  }
}

#register-form li {
  padding: 10px 0;
}

#register-form .btn-social {
  padding-left: 40px;
}

#register-form .btn-social:hover, #register-form .btn-social:focus {
  color: #fff;
}

#recover-block {
  border: none;
  border-top-left-radius: 5px;
  margin-bottom: 40px;
}

#recover-form {
  padding: 0 15px;
}

.alert i {
  padding-right: 5px;
}

#login-form .btn-social,
#register-form .btn-social,
#my-data-form .btn-social,
#payment-form .btn-social,
#recover-form .btn-social {
  padding-left: 40px;
  padding-top: 10px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #FFFFFF;
  text-transform: uppercase;
  text-align: center;
}

#login-form .btn-social:hover, #login-form .btn-social:focus,
#register-form .btn-social:hover,
#register-form .btn-social:focus,
#my-data-form .btn-social:hover,
#my-data-form .btn-social:focus,
#payment-form .btn-social:hover,
#payment-form .btn-social:focus,
#recover-form .btn-social:hover,
#recover-form .btn-social:focus {
  color: #fff;
}

#login-form input[type=button], #login-form input[type=submit],
#register-form input[type=button],
#register-form input[type=submit],
#my-data-form input[type=button],
#my-data-form input[type=submit],
#payment-form input[type=button],
#payment-form input[type=submit],
#recover-form input[type=button],
#recover-form input[type=submit] {
  color: #FFFFFF;
  box-shadow: none;
  border: none;
}

#login-form input[type=checkbox],
#register-form input[type=checkbox],
#my-data-form input[type=checkbox],
#payment-form input[type=checkbox],
#recover-form input[type=checkbox] {
  height: 13px;
}

#login-form .btn-facebook,
#register-form .btn-facebook,
#my-data-form .btn-facebook,
#payment-form .btn-facebook,
#recover-form .btn-facebook {
  height: 40px;
  width: 100%;
}

#login-form .btn-facebook:hover, #login-form .btn-facebookfocus,
#register-form .btn-facebook:hover,
#register-form .btn-facebookfocus,
#my-data-form .btn-facebook:hover,
#my-data-form .btn-facebookfocus,
#payment-form .btn-facebook:hover,
#payment-form .btn-facebookfocus,
#recover-form .btn-facebook:hover,
#recover-form .btn-facebookfocus {
  background-color: #5D7BC2;
}

.intro {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  font-size: 1.2em;
}

.oder {
  color: #999999;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.disabled-box-min-height {
  min-height: 720px;
}

@media screen and (min-width: 1800px) {
  .auth-footer {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }
}

@media screen and (max-width: 1050px) {
  .not-registered .text {
    margin-right: 0;
    font-size: 11px;
  }
}

@media screen and (max-width: 820px) {
  .auth-box .bottom-text {
    bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .auth-box.auth-box-new #login-form-div {
    padding: 20px;
  }
  .auth-modal-container .logo-motto {
    margin: 20px auto auto;
  }
  .auth-modal-container .welcome-paragraph {
    font-size: 28px;
  }
  .not-registered {
    padding-top: 10px;
  }
  .not-registered button {
    min-width: 80px;
  }
  .full-content-height {
    background: url("/themes/photo-club/images//auth/new_auth_background_mobile.jpg");
    background-repeat: no-repeat;
    background-position: right;
    -webkit-background-size: cover;
    background-size: cover;
  }
  .icon-box {
    padding-bottom: 12px;
    display: block;
    position: relative;
  }
  .icon-box img {
    position: absolute;
    height: 32px;
  }
  .icon-box p {
    padding-left: 62px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    min-height: 27px;
  }
  .icon-box a {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    color: #3C83B0;
  }
  .pc-logo {
    margin: 25px 0 40px;
  }
  .page-block .information {
    margin-left: 0;
    padding-bottom: 20px;
  }
  .page-block .information .img-responsive {
    margin: 0 auto;
  }
  .page-block .information .product img {
    height: 41px;
    width: 41px;
  }
  .free-registration-title {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    padding-bottom: 45px;
  }
  .auth-box {
    margin-bottom: 0;
    padding: 0;
  }
}

.membership-page.container-fluid {
  padding: 0 60px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  max-width: 1200px;
}

@media screen and (min-width: 768px) {
  .membership-page.container-fluid > .row {
    margin-left: auto;
    margin-right: 0;
    width: 100%;
    max-width: 580px;
  }
  .membership-page.container-fluid .auth-box {
    margin-left: auto;
    margin-right: 0;
    width: 100%;
    max-width: 580px;
  }
}

@media screen and (max-width: 991px) {
  .membership-page.container-fluid .customask_circle:not(.mobile) {
    transform: translate(-248px, -90px) scale(0.5) !important;
  }
}

.membership-page.container-fluid img.pc-logo {
  margin: 50px auto 25px;
}

.membership-page.container-fluid .check-ul li {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500 !important;
  padding-right: 55px;
}

.membership-page.container-fluid .check-ul li:last-child:before {
  font-family: 'FontAwesome';
  content: '\f058';
  font-size: 16px;
  color: #FA6362;
  position: absolute;
  margin-left: -24px;
}

.membership-page.container-fluid .check-ul li.delivery-notice {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  margin-left: -30px;
  padding: 0 30px;
  margin-top: 15px;
}

.membership-page.container-fluid .check-ul li.delivery-notice:before {
  display: none !important;
}

.membership-page.container-fluid .auth-box {
  max-width: 580px;
  opacity: 1 !important;
  padding: 0 0 20px 0;
}

.membership-page.container-fluid .auth-box > .row {
  padding: 0;
}

.membership-page.container-fluid .auth-box > .row > .col-md-12:first-child {
  padding: 0;
}

.membership-page.container-fluid .auth-box > .row > .col-md-12:first-child h3 {
  background: #3e85b3;
  color: #fff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  width: calc(100% + 2px);
  padding: 6px 60px !important;
  text-transform: uppercase;
  margin-top: -1px !important;
  margin-left: -1px !important;
}

.membership-page.container-fluid.mein-extend-page .auth-box > .row > .col-md-12:first-child h3 {
  background: #37c391;
}

.membership-page.container-fluid.mein-extend-page .auth-box .for-free-wrapper {
  transform: rotate(-16deg);
}

.membership-page.container-fluid.mein-extend-page .auth-box .for-free-label {
  background-color: #37c391;
}

.membership-page.container-fluid.mein-extend-page .auth-register a {
  background-color: #37c391;
  border-color: #37c391;
}

.membership-page.container-fluid.mein-extend-page .check-ul li:before {
  color: #37c391;
}

.membership-page.container-fluid .auth-register {
  display: flex;
  justify-content: center;
  margin: 20px 0 0 0;
}

.membership-page.container-fluid .auth-register a {
  max-width: 100%;
  width: fit-content;
  width: -moz-fit-content;
  font-size: 16px;
}

.membership-page.container-fluid .free-registration {
  margin-bottom: 40px;
  border-radius: 25px;
}

.membership-page.container-fluid .free-registration .submit-group {
  margin-bottom: 0 !important;
}

.membership-page.container-fluid .advantages-title {
  display: none;
  text-transform: uppercase;
  font-size: 24px !important;
}

.membership-page.container-fluid .for-free-label {
  width: 120px;
  height: 120px;
  top: 220px;
}

.membership-page.container-fluid .for-free-label .for-free-wrapper {
  height: 108px;
  width: 108px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.membership-page.container-fluid .for-free-label .for-free-wrapper p.coop {
  font-size: 13px;
}

.membership-page.container-fluid .for-free-label div {
  font-size: 21px;
}

.membership-page.container-fluid .free-registration-title {
  letter-spacing: 1px;
}

.membership-page.container-fluid .customask_circle:not(.mobile) {
  position: absolute;
  top: 10px;
  left: 0;
  transform: translate(-318px, -90px) scale(0.8);
}

.membership-page.container-fluid .customask_circle p {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  transform: translate(70px, 70px) rotate(-3deg);
}

.membership-page.container-fluid .customask_circle .customask_image_offer {
  position: absolute;
  left: 0;
  width: 120px;
  margin-top: 65px;
  margin-left: 200px;
  transform: rotate(-6deg);
}

@media screen and (max-width: 850px) {
  .membership-page.container-fluid .check-ul li {
    font-size: 12px;
    line-height: 14px;
  }
  .membership-page.container-fluid .check-ul li li.delivery-notice {
    font-size: 10px;
    margin-bottom: 10px;
  }
  .membership-page.container-fluid .advantages-title {
    font-size: 18px !important;
  }
  .membership-page.container-fluid .free-registration h3 {
    font-size: 13px;
  }
  .membership-page.container-fluid .auth-register a {
    font-size: 13px;
  }
}

@media screen and (max-width: 768px) {
  .membership-page.container-fluid {
    width: 80%;
    margin: auto;
  }
  .membership-page.container-fluid .for-free-label {
    top: 120px;
    width: 96px;
    height: 96px;
  }
  .membership-page.container-fluid .for-free-label .for-free-wrapper {
    height: 84.2px;
    width: 84.2px;
  }
  .membership-page.container-fluid .for-free-label .for-free-wrapper p.coop {
    font-size: 11px;
  }
  .membership-page.container-fluid .for-free-label .for-free-wrapper div {
    font-size: 18px;
  }
  .membership-page.container-fluid .customask_circle:not(.mobile) {
    display: none;
  }
  .membership-page.container-fluid .customask_circle.mobile {
    margin: auto;
  }
  .membership-page.container-fluid .customask_circle.mobile .customask_image_offer {
    left: calc(100vw / 2);
    margin-left: 0;
  }
}

@media screen and (max-width: 1200px) {
  .membership-page.container-fluid > .row:first-child > div:first-child {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 40px auto 20px;
  }
  .membership-page.container-fluid > .row:first-child > div:first-child img {
    margin: 0;
  }
  .membership-page.container-fluid > .row:first-child > div:first-child img:last-child {
    width: 40%;
  }
  .membership-page.container-fluid > .row:first-child > div:first-child img:first-child {
    width: 50%;
  }
}

@media screen and (max-width: 540px) {
  .membership-page.container-fluid {
    padding: 0 20px;
    width: 100%;
  }
  .membership-page.container-fluid .for-free-label {
    top: 230px;
    right: -28px;
  }
  .membership-page.container-fluid .customask_circle .customask_image {
    width: 100%;
  }
  .membership-page.container-fluid .customask_circle .customask_image_offer {
    max-width: 80px;
  }
  .membership-page.container-fluid .customask_circle p {
    font-size: 12px;
  }
}

.membership-page.container-fluid p.delivery-notice {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
  padding: 20px 30px 0;
  display: flex;
  box-sizing: border-box;
}

@media screen and (min-width: 769px) {
  .membership-page.container-fluid .customask_circle.mobile {
    display: none;
  }
}

@media screen and (min-width: 768px) and (max-width: 769px) {
  .membership-page.container-fluid .customask_circle.mobile {
    margin-bottom: 20px;
  }
}

.wuestenrot-page .customask_circle {
  display: none !important;
}

@media screen and (max-width: 767px) {
  .not-registered {
    padding-top: 5px;
  }
}

.new-auth-lp .home-background-image .logo-block {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}

.new-auth-lp .home-background-image .logo-block {
  margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
  .new-auth-lp .home-background-image .logo-block img {
    margin-top: 80px !important;
  }
}

@media screen and (max-width: 768px) {
  .new-auth-lp .home-background-image .logo-block img {
    margin-top: 50px !important;
  }
}

.new-auth-lp .home-background-image .logo-block img {
  object-fit: contain;
  max-height: 70px;
}

.new-auth-lp .home-background-image .logo-block .home-logo {
  max-width: 265px;
  margin: 0 10px 0 0;
  padding-top: 0 !important;
}

.new-auth-lp .home-background-image .logo-block .extend-logo {
  max-width: 240px;
  margin: 0 0 0 10px;
}

.new-auth-lp .home-background-image .logo-block .sparkasse-logo {
  max-width: 240px;
  margin: 0 0 0 10px;
  max-height: 90px !important;
}

@media screen and (max-width: 540px) {
  .new-auth-lp .home-background-image .logo-block {
    flex-wrap: wrap;
    margin-bottom: 0px;
  }
  .new-auth-lp .home-background-image .logo-block img {
    max-width: 280px !important;
    margin: 10px 0 !important;
  }
}

.new-auth-lp .home-background-image .home-subtitle {
  color: #4A403F;
  max-width: 400px;
  margin: 0 auto 40px;
  font-size: 28px;
}

.new-auth-lp .home-background-image .btn {
  background-color: #3E85B3 !important;
  border-color: #3E85B3 !important;
}

@media screen and (min-width: 541px) {
  .new-auth-lp .home-background-image {
    background-image: url("/themes/photo-club/images/auth/new/extend/top_desktop.jpg");
  }
}

@media screen and (max-width: 540px) {
  .new-auth-lp .home-background-image {
    background-image: url("/themes/photo-club/images/auth/new/extend/top_mobile.jpg");
    padding-top: 190px;
  }
  .new-auth-lp .home-background-image .home-subtitle {
    font-size: 19px;
    margin: 0 auto 20px;
  }
}

.new-auth-lp .registration-header {
  text-align: center;
  color: #000;
  font-size: 20px;
}

.new-auth-lp .membership-plan {
  max-width: 536px;
  width: 100% !important;
}

@media screen and (min-width: 540px) {
  .new-auth-lp .membership-plans .content .bottom {
    width: 88% !important;
  }
}

@media screen and (max-width: 540px) {
  .new-auth-lp .membership-plans {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
}
