.modal-header{
  display: none;
}

.payment-page {
  max-width: 435px;
  margin: auto;

  .white-block {
    margin: 0 0 16px;
    border: 1px solid transparent;

    .indicator {
      display: none;
    }

    .loading {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        background-color: rgba(255,255,255,0.5);
        width:100%;
        height:100%;
        top:0;
      }

      .indicator {
        display: block;
        position: absolute;
        top:0;
        bottom:0;
        margin:auto;
        z-index:10;
        left:0;
        right:0;
        width:45px;
        height:45px;
        text-align: center;
      }

    }

    .form-content {
      display: none;
    }

    .custom-radio {
      label {
        cursor: pointer;
        width: 100%;
        position: relative;
        padding: 28px;

        img {
          position: absolute;
          right: 28px;
        }
      }
    }

    &.active {
      border: 1px solid $activeColor;
      padding: 28px;

      .form-content {
        display: block;
      }


      .custom-radio {
        label {
          padding: 0;
        }
        img {
          right: 0;
        }
      }

    }

    p {
      padding-left: 35px;
      margin: 16px 0 16px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 16px;
    }

    form {
      padding-left: 35px;
      margin-bottom:20px;

      &#adyenForm {
        margin-bottom: 0;
      }

      .cols-inline {
        .row {
          padding:0 15px;
          .col-xs-6 {
            padding: 0;
            &:first-child {
              padding-right:8px;
            }
          }
        }
      }

      a {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 45px;
        color: $activeColor;
      }

      .payment-btn {
        padding-left: 0;
      }

    }

    .payment-btn {
      padding-left:35px;

      .btn {
        width:100%;
        display: block;
      }
    }

  }

  .cvc-modal {

    .modal-container {
      p {
        padding-left: 0;
      }

      .card {
        text-align: center;
        border:1px solid #ccc;
        padding:20px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        margin-bottom:20px;

        p {
          padding: 20px 0 0;
          margin: 0;
        }
      }
    }

  }

}

@media screen and (max-width: 768px) {

  .payment-page {

    .white-block {
      margin-bottom:24px;

      #payment-form {
        &.credit-card-form {
          margin-top:-10px;
        }
        .form-control {
          margin: 0;
        }
      }

      label {
        img {
          height:16px;
        }
      }

      p {
        padding-left: 0;
      }

      form {
        padding-left: 0;

        a {
          line-height:32px;
        }
      }

      .payment-btn {
        padding-left:0;

        .btn {
          white-space: normal;
        }
      }

    }

  }

}