@import "../variables";

.password-recovery {
  h3 {
    color: $mainColor;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  h4 {
    padding-top: 8px;
    color: $mainColor;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    span {
      font-weight: 800;
    }
  }
  .password-reset-btn {
    margin-top: 8px;
    width: 100%;
    padding: 11px 0;
  }
}