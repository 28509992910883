.photo {
  h3 {
    font-size: 16px;
  }
  .container {
    padding-bottom: 25px;
  }
  .tips {
    overflow: hidden;
    height: 500px;
    background-color: #F9F9F9;
    border: solid 1px #E1E1E1;
    .text {
      padding: 0 10px;
      h3 {
        font-size:16px;
        font-weight:600;
      }
      a {
        color: $primaryColor;
      }
    }
    .href {
      position: absolute;
      bottom: 40px;
      right: 30px;
    }
    a {
      color: $primaryColor;
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 264px;
    }
  }
  .page {
    color: #FA6362;
    font-size: 18px;
    letter-spacing: 2px;
    .pagination > .active > span {
      background-color: transparent;
      color: #FA6362;
      font-weight: 800;
    }
    .pagination > li > a, .pagination > li > span {
      background-color: transparent;
      color: #FA6362;
      border: none;
      padding: 0;
    }
  }
}