.set-price {
  color: $mainColor;

  h4 {
    span {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
    }
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    margin: 0;
    color: #4A403F;
    position: relative;
    display: inline-block;

    span {
      cursor: pointer;
      position: relative;
      &:hover {
        .custom-popup {
          display: block;
          width: 280px;
          left: -135px;
          position: absolute;
          z-index: 20;
        }
      }
    }
  }

  .white-block {
    margin-bottom: 24px;
  }

  .price-slider {
    padding: 16px 16px 0;

    .range-wrap {
      position: relative;
      top: 30px;
      left: 16px;

      .range {

        .range-wrapper {
          height: 33px;
          width: 0;
          overflow: hidden;
          position: relative;
          z-index: 20;

          .range-filled {
            height: 33px;
            width: 283px;
            top: 0;
            content: '';
            border-style: solid;
            border-width: 0 0 33px 283px;
            border-color: transparent transparent #FA6362 transparent;
            z-index: 10;
            position: absolute;
          }

        }

        .range-background {
          height: 33px;
          width: 283px;
          top: 0;
          content: '';
          border-style: solid;
          border-width: 0 0 33px 283px;
          border-color: transparent transparent #f1f1f2 transparent;
          z-index: 10;
          position: absolute;
        }

        input[type=range]::-moz-range-progress,
        input[type=range]::-moz-range-track {
          background: transparent;
          border-color: transparent;
        }

        input[type=range]::-moz-range-thumb {
          background: $inputBackgroundColor;
          color: $inputBackgroundColor;
        }

        [type=range] {
          position: absolute;
          left: 0;
          top: 0;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 283px;
          height: 33px;
          outline: none;
          z-index: 30;
          background: transparent;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 16px;
            height: 36px;
            background: url($imageFolder + 'make_money/handle.svg');
            background-size: 16px 36px;
            cursor: pointer;
            z-index: 30;
            position: relative;
            border: none;
          }

          &::-moz-range-thumb {
            width: 16px;
            height: 36px;
            background: url($imageFolder + 'make_money/handle.svg');
            background-size: 16px 36px;
            cursor: pointer;
            z-index: 30;
            position: relative;
          }
          &::-moz-range-progress {
            background: red;
          }
        }

      }

      .range-ticks {
        margin-left: 7px;
        width: 335px;
        .tick {
          border: 1px solid #999999;
          height: 8px;
          width: 2px;
          float: left;
          margin-right: 65px;
          margin-top: 7px;
        }
      }

      .range-text {
        color: #999999;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 16px;
        width: 283px;
        padding-top: 20px;
        position: relative;

        .right-text,
        .left-text {
          position: absolute;
        }

        .right-text {
          text-align: right;
          right: 0;
        }

        span {
          display: block;
        }
      }
    }

    p {
      color: #4A403F;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 16px;
      margin: 65px 0 43px;
      padding-right: 16px;
    }

  }

  .product-info {
    padding: 17px 24px 17px;
    margin-top: 65px;

    .price-colored {
      color: $secondaryColor;
    }

    h4 {
      color: $mainColor;
      font-size: 18px;
      font-weight: 600;
      line-height: 16px;
      margin-top: 0;
    }
    p {
      color: #999999;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 16px;
    }
    table {
      margin-bottom: 0;
      thead {
        color: #999999;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 16px;
        text-align: right;
        border-top: 2px solid #999999;
        th {
          border-bottom: 2px solid #999999;
          padding-left: 0;
          &:last-child {
            text-align: right;
          }
          &:first-child {
            width: 50%;
          }
        }
      }
      tbody {
        color: #4A403F;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 16px;
        border-bottom: 2px solid #999999;

        td {
          border-top: 0;
          padding-left: 0;
          &:last-child {
            text-align: right;
          }
        }

      }
    }
    a {
      color: $activeColor;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 16px;
      display: block;
      width: 100%;
      text-align: center;
      border: none;
      padding: 13px 0;
    }
    .generated-product {
      text-align: center;
      img {
        width: 250px;
        margin-top: -50px;
      }
    }
  }

}

@media screen and (max-width: 767px) {
  .set-price {

    h3 {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      margin-bottom: 20px;

      span {
        position: initial;
        &:hover {
          .custom-popup {
            left: 0;
            right: 0;
            margin: 10px auto;
            width: 100%;
            z-index: 50;
          }
        }
      }
    }

    h4 {
      span {
        padding: 5px 0;
      }
    }

    .price-slider {
      .range-wrap {
        top: 5px;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        max-width: 245px;

        .range-ticks {
          .tick {
            margin-right: 55px;
          }
        }

        .range-text {
          padding-top: 15px;
          max-width: 100%;
        }
        .range {
          .range-wrapper {
            .range-filled {
              border-width: 0 0 33px 245px;
              width: 245px;
              max-width: 100%;
            }
          }
          .range-background {
            width: 245px;
            max-width: 100%;
            border-width: 0 0 33px 245px;
          }
          [type=range] {
            width: 245px;
          }
        }
      }
      p {
        display: none;
      }
    }

    .product-info {
      padding: 0 16px;

      table {
        margin-bottom: 16px;
      }

      .generated-product {
        p {
          margin: 20px 0;
        }

        i {
          margin-bottom: 20px;
        }

        img {
          margin-top: 0;
        }
      }

      a {
        padding: 0 0 13px;
        border-bottom: 2px solid #999999;
      }

    }
  }
}