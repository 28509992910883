@import "../variables";
@import "auth/register";
@import "auth/payment";
@import "auth/confirm";
@import "auth/friend";
@import "auth/award2018";
@import "input/input";

.full-content-height {
  background: url($imageFolder + '/auth/new_auth_background_10_06.jpg') no-repeat center center;
  background-size: cover;
  //padding-bottom: 80px;
  .auth-content {
    padding: 80px 0 150px;
    background-position: bottom;
    min-height: inherit;
    &.container-fluid {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
.rewardo-bg {
  background: url($imageFolder + 'rewardo/bg.png') no-repeat center center;
  background-size: cover;
}

.radio-auth-modal {
  .custom-radio-button {
    border: 2px solid $authRadioButtonColor;
    color: $authRadioButtonColor;
    padding: 4px 18px;
    font-size: 14px;
    opacity: 0.5;
    margin-right: 17px;
  }
}

.radio-auth-modal input:checked+.custom-radio-button {
  border-color: $activeColor;
  color: $activeColor;
}

.meinfoto-content-image {
  background: url($imageFolder + '/auth/new/mf-background.jpg') no-repeat center center;
  background-size: cover;
}

.auth-modal-container {

  /* General Modal */
  .modal-wrapper{
    padding-left: 10px;
    padding-right: 10px;
  }
  .modal-container, .modal-wrapper .close-modal {
    max-width: 905px !important;
    width: 100%;
  }
  .modal-container{
    padding: 0 !important;
    border-radius: 6px;
  }
  .modal-header {
    display: none;
  }
  .modal-body {
    margin: 0;
    padding: 0;
  }
  .lato-h1{
    font-size: 28px;
    font-weight: bold;
  }
  .lato-h2{
    font-size: 22px;
  }
  .lato-h3{
    font-size: 16px;
    font-weight: bold;
  }
  .col-md-7{
    @media screen and (min-width: 769px) {
      width: 54%;
    }
  }
  .col-md-5{
    @media screen and (min-width: 769px) {
      width: 46%;
    }
  }

  /* Login Side*/
  .modal-login-header{
    margin-top: 20px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: #26252D;
    line-height: 36px;
    margin-bottom: 30px;
    @media screen and (max-width: 769px){
      margin: auto;
      margin-top: 10px;
      margin-bottom: 20px;
    }

    &.right-side-head {
      margin-bottom: 80px;
    }
  }
  .modal-login-header span{
    color: #b8b9bd !important;
    font-weight: normal !important;
  }
  .login-split div{
    display: inline-block;
  }
  .login-form-div{
    padding: 20px;
  }
  .login-input-style{
    width: 395px;
    padding: 25px !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0 !important;
    box-shadow: none !important;
    border: none;
    border-bottom: solid 1px #D5D5DB !important;
    font-weight: 400;
    @media screen and (max-width: 825px){
      width: 325px;
    }
    @media screen and (max-width: 769px){
      width: 100%;
    }
  }
  .login-custom-label{
    font-size: 12px;
    color: $inputNameColor;
    padding-left: 25px;
    margin-bottom: -10px;
    font-weight: 400;
    transition: transform 0.3s;
  }
  .eye-icon{
    color:#26252D;
    margin-top: 34px;
    background-image: url($imageFolder + 'auth/eye-icon.svg');
    background-repeat: no-repeat;
    width: 32px;
    height: 16px;
    transform: scale(0.6);
    transition: opacity .3s;
    opacity: 1;
    &.active{
      opacity: 0.7;
    }
  }
  .form-control{
    color: #26252D !important;
    font-size: 16px !important;
    font-weight: 400;
  }
  .login-button{
    display: inline-block;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    width: 100%;
  }
  .facebook-button{
    border: 3px solid #9aa9c9;
    border-radius: 40px;
    color: #3B5998;
    padding: 9px;
    transition: color .3s, border-color .3s;
    background-color: transparent;
    &:hover{
      border-color: #3B5998;
      color: #263961 !important;
    }
  }
  .submit-group{
    margin-bottom: 20px;
    width: 100%;
    & .text-center{
      margin: auto;
      margin-top: 5px;
      margin-bottom: 20px;
      width: 100%;
    }
    & p{
      margin: auto;
    }
  }
  .fa-facebook{
    padding-right: 5px;
  }
  .already-member{
    font-weight: 400;
    margin: auto auto 17px;
    color: $authTextColor;
    a {
      font-weight: 700;
    }
  }
  .password-form-group{
    margin-bottom: 45px;
  }
  .container-content{
    margin:auto;
  }
  .custom-label-translate{
    transform: scale(1.2) translateY(25px);
  }
  .login-half-size{
    width: 185px;
    @media screen and (max-width: 825px){
      width: 145px;
    }
    @media screen and (max-width: 769px){
      width: 100%;
    }
  }
  .name-input{
    padding-left: 0;
    @media screen and (max-width: 769px){
      padding-right: 0;
    }
  }
  .lastname-input{
    padding-right: 0;
    @media screen and (max-width: 769px){
      padding-left: 0;
    }
  }
  .custom-checkbox-wrapper{
    margin: auto auto 20px;
    & label:before{
      border-width: 2px;
      border-radius: 3px;
      background-color: #fff;
    }
    & label{
      @media screen and (max-width: 400px){
        max-width: 250px;
      }
    }
  }
  .salutation-select{
    text-indent: 25px;
    height: 57px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 !important;
  }

  /* Info Side */
  .photo-club-background{
    background-image: url($imageFolder + "auth/new/login-register-background.jpg");
    height: 672px;
    margin-top: -2px;
    margin-bottom: -1px;
    margin-left: -2px;
    -webkit-background-size: cover;
    background-size: cover;
    &.registration_free{
      height: 743px;
    }
    @media screen and (max-width: 769px){
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 450px !important;
      margin: -3px 0 0 0;
    }
    @media screen and (max-width: 420px){
      height: 440px !important;
    }
    @media screen and (max-width: 370px){
      height: 490px !important;
    }
  }
  .logo-motto{
    background-image: url($imageFolder + 'auth/new/login-logo.svg');
    background-repeat: no-repeat;
    height: 67px;
    width: 236px;
    margin:50px auto auto;
    background-size: contain;

    &.en {
      background-image: url($imageFolder + 'auth/new/login-logo-en.svg') !important;
    }
  }
  .welcome-paragraph{
    font-size: 36px;
    font-weight: 300;
    color: #211B1B;
    padding-left: 25px;
    margin-top: 50px;
  }
}

.ul-features {
  li {
    list-style-image: url($imageFolder + "auth/new/checkmark-circle.svg");
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding-left: 10px;
    margin-top: 10px;
    a {
      color: #fff;
    }
    &.li-blue {
      list-style-image: url($imageFolder + "auth/new/checkmark-circle-blue.svg");
    }
  }
}

.logout {
  border: 1px solid #4A403F;
  border-radius: 2px;
  padding: 8px 15px;
  word-spacing: 2px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.not-registered, .count-down-timer {
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  a {
    color: $activeColor;
  }
  .text {
    margin-right: 15px;
  }
}

.nopadding {
  padding: 0;
}

.pc-logo {
  max-width: 265px;
  margin: 50px 0 25px;
}

.telekom-logo {
  max-width: 152px;
  margin: 0 20px;
}

.rewardo-logo {
  max-width: 200px;
  margin: 0 20px;
}

.kik-logo {
  max-width: 150px;
  margin: 0 20px;
}

.sparkasse-logo {
  max-width: 100px;
  margin: 0 30px;

  @media screen and (max-width: 1200px) {
    margin-top: -30px !important;
    padding: 10px;
  }
}

.haspa-logo {
  max-width: 230px;
  margin: 0 30px;

  @media screen and (max-width: 1200px) {
    margin-top: -30px !important;
    padding: 10px;
  }
}


.douglas-logo {
  max-width: 230px;
  margin: 0 20px;
}

.fti_touristik-logo {
  max-width: 120px;

  @media screen and (min-width: 1200px) {
    margin-bottom: -20px;
    margin-left: 30px;
  }
}

.mobilcomdebitel-logo {
  max-width: 220px;
  margin: 0 20px;
}

.tui_cruises-logo {
  max-width: 220px;
  margin: 0 20px;
}

.weltbild-logo {
  max-width: 220px;
  margin: 0 20px;
}

.xing-logo {
  max-width: 220px;
  margin: 0 20px;
}

.wuestenrot-logo {
  max-width: 220px;
  margin: 0 20px;
}

.picturepeople-logo {
  max-width: 220px;
  margin: 20px 20px 0;
}

.photokina-logo {
  max-width: 240px;
  margin: 20px 10px 0;
}

.volksbank-logo {
  max-width: 240px;
  margin: 20px 10px 0;
}

.messefrankfurt-logo {
  max-width: 240px;
  margin: 20px 10px 0;
}

.glossybox-logo {
  max-width: 240px;
  margin: 20px 10px 0;
}

.extend-logo {
  max-width: 240px;
  margin: 20px 10px 0;
}

.page-block {
  background-color: #F7F7F7;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  color: #4A403F;
  .information {
    margin-left: 40px;
    padding-bottom: 40px;
  }
  .description {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .img-articles {
    height: 270px;
  }
  .product {
    padding-top: 15px;
    font-size: 14px;
    .fa-check-circle {
      font-size: 24px;
      color: #FA6362;
      float: left;
      padding-right: 20px;
    }
    span {
      font-weight: 800;
    }
    img {
      float: left;
      height: 35px;
      width: 35px;
      margin-right: 15px;
    }
  }

}

.free-registration-title {
  font-size: 36px;
  font-weight: 800;
  letter-spacing: 2px;
  line-height: 40px;
  margin: 0;
  text-align: center;
  padding: 20px 0 45px;

  max-width: 580px;
  margin: auto;
}

.mein-extend-page .free-registration-title {
  font-size: 28px;
}

.auth-box {
  width: 100%;
  max-width: 450px;
  opacity: 0.9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-top-left-radius: 0;
  padding: 20px;
  border: 1px solid white;
  background-color: white;
  margin: auto;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  &.auth-box-new {
    @extend .auto-fill-transparent-input;
    max-width: 905px;
    opacity: 1;
    border-radius: 8px;
    padding: 0;
    background-color: $backgroundColor;
    border-color: $backgroundColor;
    #login-form-div {
      padding: 20px 40px 0 47px;
    }
    .eye-icon {
      position: absolute;
      right: 0;
    }
    .login-or-facebook {
      font-size: 12px;
      color: $authTextColor;
    }
    .conditions {
      label {
        color: $authTextColor;
      }
    }
    .form-group {
      .login-input-style {
        background-color: transparent;
      }
    }
  }

  .description-second {
    letter-spacing: 1px !important;
    font-size: 12px !important;
    line-height: 20px !important;
    font-weight: bold !important;
  }

  @media screen and (max-width: 768px) {
    border: none;
  }

  .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .text {
    color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    margin: 20px;
  }

  .bottom-text {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
  }

  .telekom-link {
    color: white;
    font-weight: 900;
    text-decoration: underline ;
  }

  .telekom-register {
    margin: 20px 0 0 0;
  }

  .auth-register {
    margin: 20px 0 0 0;
  }

  &.free-registration {
    margin-bottom: 40px;
  }

  .already-member {
    padding-top: 20px;
  }

  .for-free-label {
    position: absolute;
    height: 96px;
    width: 96px;
    background-color: #FA6362;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    color: #fff;
    right: -48px;
    top: -48px;
    text-align: center;
    -webkit-transform: rotate(10deg);
    -moz-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    -o-transform: rotate(10deg);
    transform: rotate(10deg);

    .for-free-wrapper {
      display: table;
      box-sizing: border-box;
      height: 84.2px;
      width: 84.2px;
      border: 0.79px solid #FFFFFF;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      position: absolute;
      top: 6px;
      bottom: 6px;
      left: 6px;
      right: 6px;
      margin: inherit;

      span {
        font-size: 14.16px;
        font-weight: 800;
        line-height: 18px;
        text-align: center;
        display: block;
        border-bottom: 1px solid #fff;
        margin: 10px 10px 5px;
        padding-bottom: 5px;
        text-decoration: line-through;
      }

      p {
        font-size: 9.44px;
        font-weight: 600;
        line-height: 12px;
        margin: 0;
        padding: 0;
        &.coop {
          display: table-cell;
          vertical-align: middle;
          line-height: 17px;
          font-size: 12px;
        }

        strong {
          display: block;
          font-size: 14.16px;
          font-weight: 800;
          line-height: 18px;
          text-align: center;
        }
      }
    }
  }

  &.free-registration {
    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      margin: 0 0 20px;
      text-align: center;
      padding: 0 60px;
      &.advantages-title {
        padding:0;
      }
      &.membership-later-title {
        margin: 20px 0 10px;
      }
    }
    .email-info {
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 16px;
      margin-bottom: 20px;
    }
    .conditions {
      margin-bottom: 15px;
      white-space: normal;
    }

    .submit-group {
      margin-bottom: 20px;
    }

    ul {
      margin-bottom: 0;
    }

  }
  .remember-me {
    margin: 0;
    padding-left: 0;
  }
  a.password {
    color: #999999;
    font-size: 12px;
    font-weight: 600;
  }
  a:hover {
    color: $primaryColor;
  }
  .checkbox {
    margin-top: 0;
    white-space:nowrap;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 10px;
    margin-bottom: 40px;
  }
}

.tab-box {
  width: 450px;
  margin: auto;
  font-size: 11px;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    width: 0;
    display: table;
    margin: 0 auto;
  }
}

.error-box {
  width: 450px;
  margin: auto;
  opacity: 0.9;
  font-size: 11px;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.icon-box {
  padding-bottom: 40px;
  display: flex;
  align-items: end;

  p {
    padding-left: 10px;
    color: #4A403F;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px;
  }
  @media screen and (max-width: 768px) {
    p {
      width: 100%;
    }
  }
}

div.tab {
  opacity: 0.8;
  overflow: hidden;
  width: auto;
  margin-right: 2px;
  float: left;
  border-radius: 4px 4px 0 0;
  background-color: #F1F1F2;

  button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 5px 10px;
    color: #999999;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
  }

  button.active {
    color: $primaryColor;
    background-color: #FFFFFF;
  }
}

.auth-box ::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: uppercase;
}

.auth-box :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: uppercase;
}

.auth-box ::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: uppercase;
}

.auth-box :-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: uppercase;
}

.auth-header {
  padding: 10px 0;

  span {
    padding-right: 10px;
  }
}

.auth-footer {
  display: block;
  bottom: 0;
  padding: 50px 0;
  @media screen and (max-width: 768px) {
    padding: 50px 0 0;
  }
}

#register-form {
  li {
    padding: 10px 0;
  }

  .btn-social {
    padding-left: 40px;
    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

#recover-block {
  border: none;
  border-top-left-radius: 5px;
  margin-bottom: 40px;
}

#recover-form {
  padding: 0 15px;
}

.alert {
  i {
    padding-right: 5px;
  }
}

#login-form,
#register-form,
#my-data-form,
#payment-form,
#recover-form {

  .btn-social {
    padding-left: 40px;
    padding-top: 10px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #FFFFFF;
    text-transform: uppercase;
    text-align: center;
    &:hover,
    &:focus {
      color: #fff;
    }
  }

  input[type=button], input[type=submit] {
    color: #FFFFFF;
    box-shadow: none;
    border: none;
  }

  input[type=checkbox] {
    height: 13px;
  }

  // input:not([type=checkbox])


  .btn-facebook {
    height: 40px;
    width: 100%;
    &:hover,
    &focus {
      background-color: #5D7BC2;
    }
  }
}

.intro {
  position: absolute;
  top: 35%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  font-size: 1.2em;
}

.oder {
  color: #999999;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.disabled-box-min-height {
  min-height: 720px;
}

//.btn-social > :first-child {
//  line-height: 40px;
//}

@media screen and (min-width: 1800px) {
  .auth-footer {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
  }
}

@media screen and (max-width: 1050px) {
  .not-registered {
    .text {
      margin-right: 0;
      font-size: 11px;
    }
  }
}

@media screen and (max-width: 820px) {
  .auth-box {
    .bottom-text {
      bottom: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .auth-box {
    &.auth-box-new {
      #login-form-div {
        padding: 20px;
      }
    }
  }
  .auth-modal-container {
    .logo-motto {
      margin: 20px auto auto;
    }
    .welcome-paragraph{
      font-size: 28px;
    }
  }
  .not-registered {
    padding-top:10px;
    button {
      min-width: 80px;
    }
  }

  .full-content-height {
    background: url($imageFolder + '/auth/new_auth_background_mobile.jpg');
    background-repeat: no-repeat;
    background-position: right;
    -webkit-background-size: cover;
    background-size: cover;
  }

  .icon-box {
    padding-bottom:12px;
    display: block;
    position: relative;

    img {
      position: absolute;
      height:32px;
    }

    p {
      padding-left:62px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 16px;
      min-height: 27px;
    }
    a {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 16px;
      color: $activeColor;
    }
  }

  .pc-logo {
    margin: 25px 0 40px;
  }

  .page-block {
    .information {
      margin-left: 0;
      padding-bottom: 20px;
      .img-responsive {
        margin: 0 auto;
      }
      .product {
        img {
          height: 41px;
          width: 41px;
        }
      }
    }
  }

  .free-registration-title {
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 24px;
    text-align: center;
    padding-bottom: 45px;
  }

  .auth-box {
    margin-bottom: 0;
    padding: 0;
  }
}

.membership-page.container-fluid {
  padding: 0 60px;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

  // Spacing and sizing
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  max-width: 1200px;

  @media screen and (min-width: 768px) {
    >.row {
      margin-left: auto;
      margin-right: 0;
      width: 100%;
      max-width: 580px;
    }

    .auth-box {
      margin-left: auto;
      margin-right: 0;
      width: 100%;
      max-width: 580px;
    }


  }

  @media screen and (max-width: 991px) {
    .customask_circle:not(.mobile){
      transform: translate(-248px, -90px) scale(0.5) !important;
    }
  }


  img.pc-logo {
    margin: 50px auto 25px;
  }

  .check-ul {
    li {
      font-size: 18px;
      line-height: 22px;
      font-weight: 500 !important;
      padding-right: 55px;

      &:last-child {
        &:before {
          font-family: 'FontAwesome';
          content: '\f058';
          font-size: 16px;
          color: #FA6362;
          position: absolute;
          margin-left: -24px;
        }
      }

      &.delivery-notice {
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        margin-left: -30px;
        padding: 0 30px;
        margin-top: 15px;

        &:before {
          display: none !important;
        }
      }
    }
  }

  .auth-box {
    max-width: 580px;
    opacity: 1 !important;
    padding: 0 0 20px 0;

    >.row {
      padding: 0;

      >.col-md-12:first-child {
        padding: 0;

        h3 {
          background: #3e85b3;
          color: #fff;
          border-top-left-radius: 25px;
          border-top-right-radius: 25px;
          width: calc(100% + 2px);
          padding: 6px 60px !important;
          text-transform: uppercase;

          // Hacky but needed. Sorry boys :^(
          margin-top: -1px !important;
          margin-left: -1px !important;
        }
      }
    }
  }

  &.mein-extend-page {
    .auth-box {
      >.row {
        >.col-md-12:first-child {
          h3 {
            background: #37c391;
          }
        }
      }

      .for-free-wrapper {
        transform: rotate(-16deg);
      }

      .for-free-label {
        background-color: #37c391;
      }
    }

    .auth-register a {
      background-color: #37c391;
      border-color: #37c391;
    }

    .check-ul li {
      &:before {
        color: #37c391;
      }
    }
  }

  .auth-register {
    display: flex;
    justify-content: center;
    margin: 20px 0 0 0;

    a {
      max-width: 100%;
      width: fit-content;
      width: -moz-fit-content;

      font-size: 16px;
    }
  }

  .free-registration {
    margin-bottom: 40px;
    border-radius: 25px;

    .submit-group {
      margin-bottom: 0 !important;
    }
  }

  .advantages-title {
    display: none;
    text-transform: uppercase;
    font-size: 24px !important;
  }

  .for-free-label {
    width: 120px;
    height: 120px;
    top: 220px;

    .for-free-wrapper {
      height: 108px;
      width: 108px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      p {
        &.coop {
          font-size: 13px;
        }
      }
    }

    div {
      font-size: 21px;
    }
  }

  .free-registration-title {
    letter-spacing: 1px;
  }

  .customask_circle {
    &:not(.mobile) {
      position: absolute;
      top: 10px;
      left: 0;
      transform: translate(-318px, -90px) scale(0.8);
    }

    p {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      transform: translate(70px, 70px) rotate(-3deg);
    }

    .customask_image_offer {
      position: absolute;
      left: 0;
      width: 120px;
      margin-top: 65px;
      margin-left: 200px;
      transform: rotate(-6deg);
    }
  }

  @media screen and (max-width: 850px) {
    .check-ul {
      li {
        font-size: 12px;
        line-height: 14px;

        li.delivery-notice {
          font-size: 10px;
          margin-bottom: 10px;
        }
      }
    }

    .advantages-title {
      font-size: 18px !important;
    }

    .free-registration h3 {
      font-size: 13px;
    }

    .auth-register a {
      font-size: 13px;
    }
  }

  @media screen and (max-width: 768px) {
    width: 80%;
    margin: auto;

    .for-free-label {
      top: 120px;

      width: 96px;
      height: 96px;

      .for-free-wrapper {
        height: 84.2px;
        width: 84.2px;

        p {
          &.coop {
            font-size: 11px;
          }
        }

        div {
          font-size: 18px;
        }
      }
    }

    .customask_circle {

      &:not(.mobile) {
        display: none;
      }

      &.mobile {
        margin: auto;

        .customask_image_offer {
          left: calc(100vw / 2 );
          margin-left: 0;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    >.row:first-child {
      >div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin: 40px auto 20px;

        img {
          margin: 0;

          &:last-child {
            width: 40%;
          }

          &:first-child {
            width: 50%;
          }
        }
      }
    }
  }

  @media screen and (max-width: 540px) {
    padding: 0 20px;
    width: 100%;

    .for-free-label {
      top: 230px;
      right: -28px;
    }

    .customask_circle {
      .customask_image {
        width: 100%;
      }

      .customask_image_offer {
        max-width: 80px;
      }

      p {
        font-size: 12px;
      }
    }
  }

  p.delivery-notice {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    padding: 20px 30px 0;
    display: flex;
    box-sizing: border-box;
  }


  @media screen and (min-width: 769px) {
    .customask_circle.mobile {
      display: none;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 769px) {
    .customask_circle.mobile {
      margin-bottom: 20px;
    }
  }
}

.wuestenrot-page .customask_circle {
  display: none !important;
}

// hack because we didn't use mobile-first approach (min-width: 768)
@media screen and (max-width: 767px) {
  .not-registered {
    padding-top: 5px;
  }
}

.new-auth-lp .home-background-image {
  .logo-block {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
  }

  .logo-block {

    margin-bottom: 30px;

    @media screen and (min-width: 768px) {
      img {
        margin-top: 80px !important;
      }
    }

    @media screen and (max-width: 768px) {
      img {
        margin-top: 50px !important;
      }
    }

    img {
      object-fit: contain;
      max-height: 70px;
    }

    .home-logo {
      max-width: 265px;
      margin: 0 10px 0 0;
      padding-top: 0 !important;
    }

    .extend-logo {
      max-width: 240px;
      margin: 0 0 0 10px;
    }

    .sparkasse-logo {
      max-width: 240px;
      margin: 0 0 0 10px;
      max-height: 90px !important;
    }

    @media screen and (max-width: 540px) {
      flex-wrap: wrap;
      margin-bottom: 0px;

      img {
        max-width: 280px !important;
        margin: 10px 0 !important;
      }
    }
  }

  .home-subtitle {
    color: #4A403F;
    max-width: 400px;
    margin: 0 auto 40px;
    font-size: 28px;
  }

  .btn {
    background-color: #3E85B3 !important;
    border-color: #3E85B3 !important;
  }

  @media screen and (min-width: 541px) {
    background-image: url($imageFolder + 'auth/new/extend/top_desktop.jpg');
  }

  @media screen and (max-width: 540px) {
    background-image: url($imageFolder + 'auth/new/extend/top_mobile.jpg');

    padding-top: 190px;

    .home-subtitle {
      font-size: 19px;
      margin: 0 auto 20px;
    }
  }
}

.new-auth-lp .registration-header {
  text-align: center;
  color: #000;
  font-size: 20px;
}

.new-auth-lp {
  .membership-plan {
    max-width: 536px;
    width: 100% !important;
  }

  @media screen and (min-width: 540px) {
    .membership-plans .content .bottom {
      width: 88% !important;
    }
  }

  @media screen and (max-width: 540px) {
    .membership-plans {
      margin-top: 30px !important;
      margin-bottom: 30px !important;
    }
  }

}