.award2018-content-image {
  background: url($imageFolder + '/award2018/auth-background.jpg') no-repeat center center;
  background-size: cover;
  background-position: 50% 20%;
}

.picanova-award-bg-image {
  background: url($imageFolder + '/award2018/picanova_award_bg.jpg') no-repeat center center;
  background-size: cover;
}

.mountain-background {
  background: url($imageFolder + 'award2018/picanova_award_bg.jpg') no-repeat center center;
  background-size: cover;
  width: 100%;
}

.top-100-background {
  background: url($imageFolder + 'award2018/photokina_blue.jpg') no-repeat center center;
  background-size: cover;
  width: 100%;
}

.award2018-default-container {
  background: #F7F7F9;
  width: 100%;
}

.award2018-modal-title {
  color: #FFFFFF;
  font-size: 28px;
  font-weight: bold;
}

.award2018-modal-lists {
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: 120px;
  color: #ffffff;
  li {
    margin-bottom: 10px;
    padding-left: 5px;
  }
}

.award2018-modal-logo {
  width: 250px;
}

.modal-description {
  line-height: 60px;
  font-size: 28px;
  font-weight: 300;
  .bold {
    font-weight: 700;
    display: block;
  }
}

.modal-logo {
  width: 250px;
}

.award2018-index {
  .title-line {
    font-size: 28px;
    font-weight: bold;
    margin-top: 30px;
  }

  .subtitle {
    font-size: 22px;
  }

  label.btn {
    padding: 15px 75px;
  }

  ul.bullets {
    font-size: 16px;
    list-style: none;
    padding: 40px 0 0 15px;
    li {
      margin-bottom: 40px;
      &:before {
        color: #FA6362;
        content: "\f00c";
        font-family: FontAwesome;
        display: inline-block;
        margin-left: -19px;
        margin-right: 10px;
        float: left;
        height: 23px;
      }
    }
  }

  .note {
    font-size: 12px;
  }

  &.award2018-info {
    min-height: 1000px;
  }
}

.photokina-terms {
  @media (max-width: 767px) {
    h2 {
      font-size: 18px;
    }
  }
}

.award2018 {
  position: relative;
  max-width: 620px;
  margin: 100px auto 100px;
  border-radius: 4px;
  background: url($imageFolder + '/auth/new/login-register-background.jpg') no-repeat center center;
  background-size: cover;
  padding: 10px 0 15px;
  color: $mainColor;

  .small-header-image {
    width: 100%;
    margin: 10px 0;
  }

  &.prizes-page {
    a {
      color: $primaryColor;
    }
    background: transparent;
    max-width: 700px;
    margin: 10px auto 0;
    .prizes-title {
      font-size: 28px;
      margin-bottom: 25px;
    }
    .prizes-description {
      font-size: 16px;
      margin-bottom: 25px;
    }
    ol {
      font-size: 16px;
      padding-left: 0;
      margin-bottom: 80px;
      li {
        margin-top: 10px;
        padding-left: 5px;
      }
    }
  }

  &.top-100 {
    background: transparent;
    max-width: 1000px;
    margin: 50px auto 50px;
    .top-100-title {
      font-size: 28px;
      margin-bottom: 35px;
    }
    ul {
      li {
        max-width: 520px;
        color: #FFFFFF;
      }
    }
    .content-color {
      color: #454553;
    }
  }

  &.worth-page {
    background: transparent;
    max-width: 1150px;
    margin-top: 70px;
    .worth-title {
      font-size: 28px;
      margin-bottom: 35px;
    }
    .worth-description {
      font-size: 16px;
      margin-bottom: 35px;
    }
    ul {
      li {
        color: #49A0D5;
      }
    }
    .content-color {
      color: #454553;
    }
    .worth-register {
      margin-top: 40px;
      max-width: 240px;
    }
    .deadline {
      margin-bottom: 70px;
    }
  }

  &.info-page {
    background: transparent;
    max-width: 1150px;
    margin-top: 40px;
    .info-title {
      font-weight: bold;
      font-size: 28px;
    }
    .info-logo {
      margin-top: 50px;
      margin-bottom: 35px;
      &.picanova {
        width: 150px;
      }
      &.photo-club {
        width: 120px;
      }
    }
    .info-description {
      font-size: 16px;
      text-align: center;
      margin-bottom: 70px;
    }
  }

  &.landing-page {
    background: transparent;
    max-width: none;
    margin: 30px auto 0;
    .logos {
      img {
        margin: 10px 30px 0;
      }
    }
    .photokina-logo {
      width: 200px;
    }
    .landing-title {
      font-weight: 900;
      margin-top: 100px;
      font-size: 72px;
    }
    .landing-description {
      font-size: 18px;
      font-weight: bold;
    }
    .landing-register {
      margin-top: 120px;
      max-width: 400px;
    }
    .btn {
      font-size: 14px;
    }
  }

  .deadline {
    margin-top: 15px;
    font-size: 16px;
    margin-bottom: 20px;

    a {
      text-decoration: underline;
      color: $mainColor;
    }
  }

  .logos {
    text-align: center;
    img {
      display: inline-block;
      margin: 10px 0 0;
    }
  }

  .pc-logo {
    float: right;
    margin: 0 20px 0;
  }

  .award-content {
    padding: 0 20px;
  }

  &.picanova-award-bg {
    background: rgba(247, 247, 247, 0.6);
    max-width: 620px;
    margin: 30px auto;

    .award-pc-logo {
      display: block;
      margin: 20px auto 30px;
      width: 100%;
      max-width: 337px;
      padding: 0 10px;
    }
  }

  .inside-circle {
    border-radius: 50%;
    background: white;
    width: 105px;
    height: 105px;
    position: absolute;
    left: 20px;
    top: 20px;
    display: table;
    p {
      transform: rotate(-20deg);
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      width: 107px;
      display: table-cell;
      vertical-align: middle;
      span {
        font-size: 16px;
        font-weight: 900;
        &.euro {
          font-size: 22px;
          line-height: 0;
        }
      }
    }
  }

  .outside-circle {
    border-radius: 50%;
    background: transparent;
    width: 145px;
    height: 145px;
    border: 1px solid white;
    position: absolute;
    top: -40px;
    left: -60px;
    z-index: 100;
  }

  ul {
    list-style-type: circle;
    padding-left: 20px;
    margin-top: 10px;
    li {
      color: #000;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 9px;
      &.picanova-award {
        color: #26252D;
      }
    }
  }

  .btn-primary {
    padding: 14px 12px;
    width: 100%;

    &.href-label {
      width: auto;
      padding: 10px 30px;
    }
  }

  .logo {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 220px;
  }

  h1 {
    color: #211B1B;
    font-size: 46px;
    font-weight: 300;
    span {
      display: block;
      font-weight: 900;
      font-size: 76px;
    }
  }

  h3 {
    font-size: 28px;
    color: #454553;
    font-weight: bold;
  }

  .text {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    &.picanova-award {
      color: #26252D;
    }
    &.small {
      font-size: 12px;
    }
  }

  .title {
    color: #26252D;
    font-size: 22px;
    font-weight: 600;
  }

  .info {
    padding: 0 20px;
  }

  .href {
    color: #49A0D5;
    font-weight: 600;
  }
}

@media (max-width: 767px) {
  .top-100-background {
    background: url($imageFolder + 'award2018/photokina_blue.jpg') no-repeat;
    background-size: cover;
  }
  .award2018.landing-page {
    .logos {
      display: flex;
      justify-content: space-between;
      height: 40px;
      img {
        margin: 10px 7px 0;
      }
      .photokina-logo {
        width: 120px;
      }
    }
    .landing-title {
      font-size: 40px;
      text-align: left;
      margin-top: 40px;
    }
    .landing-description {
      font-size: 14px;
    }
    .award-content {
      text-align: left;
    }
    .landing-register {
      margin-top: 50px;
    }
  }
  &.top-100-page {
    .top-100-title {
      text-align: center;
      font-size: 22px;
    }
    ul {
      padding-left: 20px;
      margin-bottom: 40px;
      li {
        font-size: 14px !important;
      }
    }
    img {
      width: 500px;
    }
  }
  &.worth-page {
    margin-top: 10px;
    margin-bottom: 0;
    .worth-title {
      font-size: 24px;
      text-align: center;
    }
    .worth-description {
      font-size: 14px;
      text-align: center;
    }
    ul {
      font-size: 14px;
    }
    .worth-register {
      margin-left: 40px;
    }
    .btn {
      font-size: 14px;
    }
  }
  &.info-page {
    margin-bottom: 0;
    .info-description {
      margin-bottom: 0;
    }
  }
  .modal-description {
    line-height: 40px;
  }

  .award2018 {
    ul {
      li {
        font-size: 14px;
      }
    }
    &.prizes-page {
      ol {
        font-size: 14px;
        padding-left: 15px;
      }
      .prizes-title {
        font-size: 24px;
      }
      .prizes-description {
        font-size: 14px;
      }
    }
    .deadline {
      font-size: 14px;
      margin-bottom: 20px;
      text-align: center;
    }
    .outside-circle {
      width: 100px;
      height: 100px;
      left: 0;
      top: -60px;
      .inside-circle {
        width: 85px;
        height: 85px;
        left: 6px;
        top: 7px;
        p {
          width: 90px;
          font-size: 10px;
          span {
            font-size: 13px;
          }
          span.euro {
            font-size: 15px;
          }
        }
      }
    }
    h1 {
      font-size: 26px;
      span {
        display: block;
        font-weight: 900;
        font-size: 46px;
      }
    }
  }

  .picanova-award-bg-image {
    background: url($imageFolder + '/award2018/picanova_award_bg_mob.jpg') no-repeat center center;
    background-size: cover;
  }
}

.award2018-top100-container {
  padding: 0;
  .top100-button {
    margin-bottom: 250px;
  }
  .img-responsive {
    transition: all .4s ease-in-out;
    &:hover {
      transform: scale(1.5);
      z-index: 9999;
      position: relative;
    }
  }
}

@media (max-width: 375px) {
  .award2018.landing-page {
    .logos {
      img {
        margin: 10px 3px 0;
        width: 80px;
      }
      .photokina-logo {
        width: 80px;
      }
    }
  }
}