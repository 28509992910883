.referral-banner {
  background-color: #FA6362;
  color: #fff;
  padding: 5px 15px;
  margin-bottom: 20px;

  .referral-banner__timer {
    font-size: 30px;
    font-weight: bold;
    margin: 10px 0;
  }

  p {
    font-size: 20px;
    margin-bottom: 0;
  }

  .referral-banner__button {
    text-align: right;
    margin: 15px 0;

    .btn {
      background-color: #fff;
      border-radius: 40px;
      padding: 5px 15px;
      color: #FA6362;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
  }
}


@media (max-width: 768px) {
  .referral-banner {
    padding: 10px 15px;

    .referral-banner__timer {
      font-size: 18px;
      margin: 15px 0 0;
    }

    p {
      font-size: 16px;
    }

    .referral-banner__button {
      margin-bottom: 0;

      .btn {
        font-size: 14px;
        padding: 3px 10px;
      }
    }
  }
}