@media (min-width: 992px) {
  .shop-landing {
    display: block;
    height: 500px;

    &.blanket {
      background-image: url($imageFolder + 'de/shop/produkte/decke/background.jpg');
    }
    &.tote-bag {
      background-image: url($imageFolder + 'de/shop/produkte/tote-bag/background.jpg');
    }
    &.canvas {
      background-image: url($imageFolder + 'de/shop/wall-with-shelf.jpg');
      background-position: center bottom;
    }
    &.wall-cloth {
      background-image: url($imageFolder + 'de/shop/produkte/wandtuch/background.jpg');
    }
    &.forex {
      background-image: url($imageFolder + 'de/shop/produkte/forex/background.jpg');
    }
    &.acrylic-glass {
      background-image: url($imageFolder + 'de/shop/produkte/acryl/background.jpg');
    }
    &.acrylic-block {
      background-image: url($imageFolder + 'de/shop/produkte/acrylblock/background.jpg');
    }
    &.cushion {
      background-image: url($imageFolder + 'de/shop/produkte/kissen/teaser-desktop.jpg');
    }
    &.photo-calendar {
      background-image: url($imageFolder + 'de/shop/produkte/fotokalender/background.jpg');
    }
    &.photo-book {
      background-image: url($imageFolder + 'de/shop/produkte/fotobuch/background.jpg');
      background-size: cover;
    }
    &.bilderbox {
      background-image: url($imageFolder + 'de/shop/produkte/bilderbox/background.jpg');
    }
    &.phone-case {
      background-image: url($imageFolder + 'de/shop/produkte/handyhulle/background.jpg');
    }
    &.mug {
      background-image: url($imageFolder + 'de/shop/produkte/tasse/background.jpg');
    }
    &.alu-dibond {
      background-image: url($imageFolder + 'de/shop/produkte/alu-dibond/background.jpg');
    }
    &.acrylic-dibond {
      background-image: url($imageFolder + 'de/shop/produkte/acryl-alu/background.jpg');
    }
    &.canvas-plus {
      background-image: url($imageFolder + 'de/shop/produkte/leinwand-plus/background.jpg');
    }
    &.canvas-big {
      background-image: url($imageFolder + 'de/shop/produkte/riesenleinwand/background.jpg');
    }
    &.holz {
      background-image: url($imageFolder + 'de/shop/produkte/holz/background.jpg');
    }
    &.towel {
      background-image: url($imageFolder + 'de/shop/produkte/handtuch/background.jpg')
    }
    .content {
      padding-top: 70px;
      padding-bottom: 120px;
    }
    .right-side {
      background-color: #ffffff;
    }
    .cushion-button {
      padding-top: 270px;
    }
  }

  .navbar-second {
    height: auto;

    &.navbar-default .navbar-nav > .open > a {
      background-color: transparent;
      color: $primaryColor;
    }
  }

  .shop-dropdown-items {

    .container {
      /* two times the padding for bootstrap containers */
      padding-left: 15px + 15px;
    }
    p {
      font-size: 11px;
      font-weight: 400;
      color: black;
    }
  }
}

@media (max-width: 992px) {
  .dashboard-title {
    margin-top: 15px;
  }
  .navbar .navbar-header {
    margin-left: 15px;
  }
  .shop-landing .mug-button .badges {
    top: 120px;
  }
  .shop-landing .mug-button label {
    font-size: 11px;
  }
  .mobile-menu-icon {
    display: inline-block;
    vertical-align: middle;

  }
  .navbar-second .navbar-header {
    margin-top: 13px;
    .navbar-toggle {
      margin-top: 0;
    }
  }
}

@media (max-width: 767px) {
  iframe {
    width: 100%;
  }

  .shop-landing {
    height: auto;
    .right-side {
      label[for=custom-image-classic] {
        margin-bottom: 10px;
      }
    }
    &.home-lifestyle, &.photo-gifts, &.with-text, &.wall-deco {
      height: 200px;
    }
  }

  .p-top-200-mobile {
    padding-top: 200px;
  }

  .shop-products h1 {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar {
    margin-bottom: 0;
  }

  .navbar-collapse {
    padding-top: 0;

    &.collapse {
      padding-top: 0;
    }
  }

  .collapsing {
    height: 0 !important;
  }

  .navbar-toggle {
    float: left;
    margin-top: 0;
    margin-bottom: 0;
  }

  .icon-container {
    width: 30px;
    height: auto;
  }

  .mobile-menu-close-icon {
    display: none;
  }

  .icon-bar {
    display: none !important;
  }

  .collapsed .icon-bar {
    display: block !important;
  }

  .collapsed .close {
    display: none;
  }

  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background-color: white;
  }

  .navbar-nav .open .dropdown-menu {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
    left: 0;
    margin-top: 14px;
    min-width: auto;
    border-radius: unset;
  }

  .trusted-shop {
    .content {
      background-color: #ffffff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
    }
  }

  .shop-dropdown-items {
    padding: 0 !important;

    .content {
      display: block;
      padding: 0;

      img {
        display: none;
      }
    }
  }

  .shop-landing .mug-button label {
    font-size: 15px;
  }

  .badges {
    right: 0;
    top: -130px;
    bottom: auto;
    white-space: nowrap;

    &.badges--longer {
      .subtext {
        font-size: 12px;
      }
    }

    &:before {
      border-top: 39px solid transparent;
      border-bottom: 39px solid transparent;
    }
    .size {
      font-size: 12px;
    }
    .price {
      font-size: 20px;
    }
    .sub {
      font-size: 12px;
    }
  }

  .blanket {
    .badges {
      top: 70px;
    }
  }

  .shop-navigation {
    width: 100%;
    background-color: white;

    * {
      color: $darkNavTextColor;
    }
    .dropdown > a {
      pointer-events: none;
      text-align: left;
      padding: $mobileMenuLeftPadding !important;

      span {
        display: block;
        position: relative;
        font-size: 12px;
        text-transform: capitalize;

        i {
          color: $darkNavTextColor !important;
          position: absolute;
          display: table;
          margin: auto;
          top: 0;
          bottom: 0;
          right: 5px;
        }
      }
    }
  }

  .shop-navigation li {
    display: block;
  }

  .navbar-second {
    z-index: 1000;
    position: relative;
    box-shadow: none;
    border-top: 1px solid #ccc;

    .navigation {
      margin-top: 0;
      max-width: 65%;
      display: inline-block;
    }

    > .container > .row {
      position: static;
    }

    .navbar-header {
      margin-top: 13px;
      min-height: 41px;
      float: none;
      button {
        border: 1px solid $mobileMenuBorderColor;
        border-bottom: 0;
        border-radius: 4px 4px 0 0;
        border-collapse: collapse;
        transition: border-bottom 1s ease-out;
      }
      &.children-collapsed {
        button {
          transition: border-bottom 1s ease-out;
          border-bottom: 1px solid $mobileMenuBorderColor;
          margin-bottom: 1px;
          border-radius: 4px;
        }
      }
    }

    .navbar-header,
    #products-navbar {
      margin-left: 10px;
      //the following paddings must be equal to col-xs-12 padding
      //padding-left: 15px;
      padding-right: 15px;
    }

    .navbar-header {
      margin-left: 15px;
    }

    .shop-navigation {
      border: 1px solid $mobileMenuBorderColor;
    }

    .navbar-toggle {
      border: none;
      //border-bottom: none;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      margin-right: 0;
    }

    .navbar-toggle.collapsed {
      border-radius: 4px;
    }

    li.dropdown {
      padding-right: 0 !important;
      position: relative;
      text-align: center;
      border-bottom: 1px solid $mobileMenuBorderColor;
      display: block;

      &:last-child {
        border-bottom: none;
      }

      .dropdown-menu {
        background-color: $mobileMenuBackgroundColor;
        min-width: auto;
        position: relative;
        box-shadow: none;
        float: none;
        display: block !important;
        left: 0;
        max-height: 0;
        //this control the closing
        transition: max-height 0.2s ease-out;
        border: none;
        overflow: hidden;

        &.shop-dropdown-items {
          a {
            display: block;
            padding: 13px $mobileMenuLeftPadding;
          }

          .content {
            border-bottom: 1px solid $mobileMenuBorderColor;
          }

          p {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 1px;
            text-transform: uppercase;
            width: auto;
            text-align: left;
            font-weight: 400;
          }

          .row > div:first-child > .content:first-child {
            border-top: 1px solid $mobileMenuBorderColor;
          }

          //remove the border of the last children of the last div
          .row > div:last-child > .content:last-child {
            border-bottom: 0;
          }
        }
      }

      .dropdown-toggle {
        background-color: $darkerNavBackground;
      }

      &.open {
        .dropdown-menu {
          max-height: 500px;
          //this control the opening
          transition: max-height 0.2s ease-in;
          display: inline-block !important;
          margin-bottom: -3px;
        }

        i:before {
          content: "\f106";
        }
      }
    }
  }

  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border: none;
  }

  #top-banner {
    .text-right, .text-left {
      text-align: center;
    }
  }
}

@media(max-width: 400px) {
  .badges, .newsletter .badges {
    width: 80px;
    &.no-discount {
      width: auto;
    }
  }
}

@media (max-width: 320px) {
  .navbar-nav .open .dropdown-menu {
    position: absolute;
  }
}

@media (max-width: 992px) {
  .bilderbox {
    display: table;
  }
  .trusted-shop {
    display: inline-block;
    padding-bottom: 0;
    .content {
      margin-bottom: 20px;
      table td {
        font-size: 11px;
      }
    }
    .table > thead > tr > th {
      padding: 2px;
    }
  }

  .shop-landing {
    .right-side {
      padding: 13px;
      label[for=custom-image-classic] {
        margin-bottom: 10px;
      }
    }
  }

  /** Navigation for shop tablet **/
  .navbar-second {
    z-index: 1000;
    li.dropdown {
      padding-right: 0 !important;
      position: relative;
      text-align: center;
      border-bottom: 1px solid #B7B7B7;
      display: block;
      .dropdown-menu {
        background-color: #F1F1F2;
        min-width: auto;
        position: relative;
        box-shadow: none;
        float: none;
        display: none !important;
        left: 0;
        max-height: 0;
        transition: max-height 0.2s ease-out;
        border: none;
        overflow: hidden;
      }
    }
    li.dropdown.open .dropdown-menu {
      max-height: 500px;
      transition: max-height 0.2s ease-in;
      display: inline-block !important;
      background-color: $darkNavBackground;
      margin-top: 0;

      .container {
        padding-left: 0;
        padding-right: 0;
      }
    }
    .navbar-nav .open .dropdown-menu {
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
      width: 30%;
      left: 0;
    }
    .shop-navigation, .shop-dropdown-items {
      border: 1px solid #B7B7B7;
    }
    .shop-navigation {
      border-bottom: 0;
    }
    .navigation {
      margin-top: 0;
      display: inline-block;
    }
    .shop-navigation, .shop-dropdown-items {
      width: 100%;
      background-color: $darkNavBackground;
    }
    .shop-navigation .dropdown > a, .shop-dropdown-items .dropdown > a {
      pointer-events: none;
      text-align: left;
      padding: 10px !important;
    }
    #cart-icon {
      float: right;
      margin: 15px auto auto;
      bottom: 0;
      background-color: white;
      padding: 5px;
      width: auto;
    }
  }
  .navbar-toggle {
    margin-bottom: 3px;
    display: block;
  }
  .navbar-header {
    float: none;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-nav {
    float: none !important;
    > li {
      float: none;
      > a {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .collapse.in {
    display: block !important;
  }
}

@media (min-width: 767px) {
  .hidden-xs .navbar-collapse {
    float: left;
  }
}