.bonus-block {
  padding: 10px;
  margin-bottom: 20px;
  letter-spacing: 1px;

  .info-text {
    color: #999999;
    line-height: normal;
    font-size:12px;
    font-weight:600;
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    margin:0 0 18px;

    small {
      font-size: 18px;
      font-weight: 800;
      color: $mainColor;
      line-height: 16px;
    }
  }

  .form-row {
    line-height:25px;
  }

  label:not(.radio-label) {
    color: #999;
    font-size:12px;
    font-weight: 600;
  }

  .btn-payout {
    margin-top:10px;
  }

  .wallet-balance {
    font-size: 18px;
    font-weight: 600;
    white-space:nowrap;
  }

  hr {
    height: 2px;
    color: #999999;
    background-color: #999999;
    border: 0 none;
    margin-top: 5px;
    margin-bottom: 12px;
  }

  p {
    color: $descriptionColor;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 3px;

    &.border-top {
      border-top: 2px solid #999;
      display: inline-block;
      padding-top: 18px;
      width: 100%;
    }

    span {
      color: $mainColor;
      font-size: 18px;
      font-weight: 800;
      padding-left: 50px;
    }

  }
  button {
    margin-top: 21px;
    display: block;
  }
}