@import "../../../../../sass/partials/modal";

.payout {
  .custom-radio input[type="radio"] {
    margin-left: 3px;
    margin-right: 3px;
    + label {
      font-size: 12px;
      line-height: 33px;
      margin-right: 20px;
      &:before {
        margin-bottom: 8px;
        margin-right: 5px;
      }
    }
    &:checked {
      + label {
        font-weight: 600;
      }
    }
  }
}

.user-profile {
  label {
    margin-bottom: 0;
  }
  letter-spacing: 1px;
  .col-left {
    padding-right: 10px;
  }
  .col-right {
    padding-left: 10px;
  }
  .info-modal .modal-wrapper .close-modal {
    width: 656px;
  }
  .info-modal .modal-container {
    width: 656px;
    .modal-body {
      h1 {
        margin-top: 0px;
        padding-top: 0px;
      }
      p {
        margin-bottom: 0px;
      }
      margin: 0px;
      padding: 30px;
    }
  }
  .white-block {
    @include font;
    margin-bottom: 20px;
    padding: 10px;
    input, select, label {
      @include font;
    }
    h2 {
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 1px;
      color: $headlineColor;
      margin-top: 2px;
    }
    .form-row {
      line-height: 25px;
    }
    .form-row-editable {
      line-height: 35px;
    }
    .title-link {
      color: $primaryColor;
      padding-top: 3px;
    }
    label:not(.radio-label) {
      color: $defaultGrey;
    }
    .info-text {
      color: $defaultGrey;
      line-height: normal;
    }
    hr {
      height: 2px;
      color: $defaultGrey;
      background-color: $defaultGrey;
      border: 0 none;
      margin-top: 5px;
      margin-bottom: 12px;
    }
    .custom-radio-mister {
      padding-right: 10px;
    }
  }
}
