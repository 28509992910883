$imageFolder:'/themes/photo-club/images/';
$screen-lg-min: 1200px;
$screen-md-min: 992px;
$screen-sm-min: 767px;
$screen-xs-min: 480px;
$activeColor: #3C83B0;
$primaryColor: #3f86b3;
$secondaryColor: #FA6362;
$mainColor: #4A403F;
$descriptionColor: #999999;
$inputBackgroundColor: #F1F1F2;
$topBarBackground: #201B1B;
$darkerNavBackground: #3F3F3F;
$darkNavBackground: #575757;
$darkNavTextColor: #B2B2B2;
$navbarHeight: 36px;
$logoWidth: 96px;
$cartBackgroundColor: #E9E9E9;
$white: #fff;

$mobileNavbarHeight: 45px;
$mobileLogoWidth: 54px;
$shopNavigationTextColor: #454553;

$authRadioButtonColor: #454553;

$newsletterTopBar: #cc1717;

// New design variables, replace later with old ones
$backgroundColor: #F7F7F9;
$inputNameColor: rgba(69,69,83,0.5);
$authTextColor: #B7B7BC;

// Active and Hover states
$secondaryColorActive: rgba(250, 99, 98, 0.8) !important;