.btn.btn-pc-primary {
  border-radius: 4px;
  font-weight: 600;
  background-color: #49A0D5;
  border-color: #49A0D5;
}

.btn.btn-pc-primary:hover, .btn.btn-pc-primary:active, .btn.btn-pc-primary:active:focus, .btn.btn-pc-primary.active, .btn.btn-pc-primary:focus {
  background-color: #3C83B0;
  border-color: #49A0D5;
}

.loading-overlay .table .table-cell p {
  margin-top: 30px;
  font-size: 16px;
}

#shop-iframe {
  border: none;
  width: 100%;
}

#shop-app .fine-print {
  margin-top: 25px;
}

.bigger-line-height {
  line-height: 2em;
}

.sidelines {
  position: relative;
  text-align: center;
  margin: 50px 0;
  font-size: 18px;
  font-weight: 600;
}

.sidelines span {
  padding: 0 20px;
  min-width: 25%;
  display: inline-block;
  position: relative;
  background-color: #fff;
}

.sidelines:before {
  content: '';
  display: block;
  position: absolute;
  height: 1px;
  width: 83.3333333%;
  top: 50%;
  margin-left: 8.333333%;
  background-color: #aaa;
  left: 0;
}

#checkout-cart-icon {
  position: absolute;
  top: 0;
  right: 5%;
  height: 100%;
}

#checkout-cart-icon > div {
  display: table;
  height: 100%;
}

#checkout-cart-icon a {
  color: #B2B2B2;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  display: table-cell;
  vertical-align: middle;
}

#checkout-cart-icon a .fa-shopping-cart {
  position: absolute;
  right: 0;
  top: 49%;
}

#checkout-cart-icon a:hover {
  color: #3f86b3;
}

#checkout-cart-icon i {
  font-size: 20px;
}

#cart-icon {
  float: right;
  padding-top: 10px;
  padding-bottom: 12px;
  text-align: center;
}

#cart-icon, #checkout-cart-icon {
  font-size: 20px;
}

#cart-icon .la-shopping-cart:after, #checkout-cart-icon .la-shopping-cart:after {
  content: attr(data-count);
  display: inline-block;
  position: relative;
  background: #3C83B0;
  height: 1.5rem;
  width: 1.5rem;
  left: -0.8rem;
  bottom: 1.1rem;
  text-align: center;
  font-size: 1.2rem;
  border-radius: 100%;
  color: white;
  padding: 2px;
  font-family: Lato, sans-serif;
  text-align: center;
}

.checkout-height {
  height: 100% !important;
  padding-bottom: 0 !important;
}

.master-body {
  padding-top: 0;
}

.navbar-fixed-top {
  margin-bottom: 0;
}

.navbar {
  border: none;
}

.navbar-second {
  border: none;
  font-size: 12px;
  font-weight: 600;
  height: 64px;
  box-shadow: none;
}

.navbar-second .navbar-collapse {
  padding-left: 0;
  padding-top: 0;
}

.navbar-second .navigation {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.navbar-second .navbar-nav > li > a {
  color: #454553;
}

.grey-block, .trusted-shop .content, .shop-products .product, .payments .pay-container {
  background-color: #F9F9F9;
  border: solid 1px #E1E1E1;
}

.img-width {
  width: 100%;
}

.img-center {
  margin: 0 auto;
}

.badges {
  right: 100%;
  bottom: 0;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  padding: 6px 15px 6px 5px;
  border: 2px solid #FA6362;
  border-left-width: 1px;
  background-color: #FA6362;
  font-size: inherit;
  position: absolute;
  border-radius: 0;
  width: 150px;
}

.badges.badges--longer {
  width: 210px;
}

.badges.badges--longer .price .bigger-line-height {
  line-height: 12px;
}

.badges.badges--longer .subtext {
  font-size: 16px;
  white-space: nowrap;
}

.badges:before {
  left: -27px;
  border-top: 52px solid transparent;
  border-bottom: 52px solid transparent;
  border-right: 26px solid #FA6362;
  content: '';
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  top: -2px;
}

.badges .size {
  font-size: 16px;
}

.badges .price {
  color: #fff;
  font-size: 32px;
  font-weight: 600;
}

.badges .sub {
  font-size: 16px;
  text-decoration: line-through;
}

.newsletter .badges.badges--longer {
  width: 170px;
}

.newsletter .shop-landing .right-side h3, .newsletter.shop-landing .right-side h3 {
  font-size: 22px;
}

.newsletter h2 {
  font-size: 45px;
}

.newsletter img.campaignDeals {
  width: 100%;
}

.newsletter .custom-button {
  margin-top: 10px;
  margin-right: 20px;
}

.shop-landing .fine-print {
  margin-bottom: 60px;
}

.shop-landing .mug-text {
  padding-top: 20px;
}

.shop-landing .mug-text span {
  font-size: 33px;
  font-weight: 800;
}

.shop-landing .cushion-text {
  font-size: 16px;
  padding: 25px;
  margin-top: 3px;
  text-align: center;
}

.shop-landing .cushion-text span {
  display: block;
  font-size: 24px;
  font-weight: 800;
}

.shop-landing .mug-button .badges {
  top: 205px;
  right: 10px;
  bottom: auto;
  width: auto;
}

.shop-landing .mug-button .badges .price {
  font-size: 24px;
}

.shop-landing .mug-button .badges .size {
  font-size: 14px;
}

.shop-landing .mug-button .badges .sub {
  font-size: 14px;
}

.shop-landing .mug-button .badges:before {
  border-top: 46px solid transparent;
  border-bottom: 46px solid transparent;
}

.shop-landing .mug-button h2 {
  color: #4a4a4a;
  margin: 20px 40px 20px 0;
  font-weight: bold;
  font-size: 1.5em;
}

.shop-landing .mug-button label {
  width: 80%;
  font-weight: 700;
  font-size: 15px;
  color: #fff;
  letter-spacing: normal;
  padding: 10px 0;
  height: auto;
}

.shop-landing .cushion-button label {
  width: 60%;
}

.shop-landing .red {
  color: #F10006;
  font-weight: 700;
}

.shop-landing .product-circle {
  position: absolute;
  top: -15px;
  left: -15px;
  height: 92px;
  width: 92px;
}

.shop-landing .right-side {
  padding: 25px;
}

.shop-landing .right-side h3 {
  margin-top: 0;
  color: #4A403F;
  font-size: 24px;
  letter-spacing: 0.5px;
}

.shop-landing .right-side h3 span {
  font-weight: 800;
}

.shop-landing .right-side ul {
  list-style: none;
  padding: 0;
  padding-left: 1.3em;
  overflow: hidden;
}

.shop-landing .right-side ul li {
  color: #4A4A4A;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
}

.shop-landing .right-side ul li:before {
  color: #FA6362;
  content: "\f00c";
  /* FontAwesome Unicode */
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -19px;
  margin-right: 10px;
  float: left;
  height: 23px;
}

.shop-landing .right-side .link {
  color: #3f86b3;
  text-decoration: underline;
}

.shop-landing .right-side .bottom {
  padding-top: 50px;
}

.shop-landing .right-side label {
  width: 100%;
  padding: 15px;
  height: 55px;
  font-size: 18px;
  font-weight: 600;
}

.big_upload_button {
  width: 100%;
  padding: 15px;
  height: 55px;
  font-size: 18px;
  font-weight: 600;
}

.trusted-shop {
  margin-top: 55px;
  padding-bottom: 50px;
}

.trusted-shop .content {
  position: relative;
  width: 100%;
  height: 310px;
}

.trusted-shop .content img {
  max-width: 45%;
  padding: 0 0 10px 10px;
}

.trusted-shop .content table {
  margin-bottom: 0;
}

.trusted-shop .content table td {
  color: #4A4A4A;
  font-size: 14px;
  padding: 2px 8px;
  border-top: none;
}

.trusted-shop .content table th {
  color: #848484;
  font-size: 14px;
  font-weight: bold;
  border-bottom: none;
}

.trusted-shop .content table th:nth-child(3) {
  background: #848484;
  color: #fff;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  text-align: center;
}

.trusted-shop .content table thead tr {
  background-color: transparent !important;
}

.trusted-shop .content table tr:nth-child(odd) {
  background-color: #fff;
}

.trusted-shop .content table tr:nth-child(even) {
  background-color: #f3f3f3;
}

.trusted-shop .content table tr:nth-child(odd) .highlight {
  color: #fff;
  background: #FA6362;
  text-align: center;
  font-weight: 700;
}

.trusted-shop .content table tr:nth-child(even) .highlight {
  color: #fff;
  background: #F97F7E;
  text-align: center;
  font-weight: 700;
}

.trusted-shop .content .images {
  padding-bottom: 30px;
}

.trusted-shop .content span {
  color: #4A4A4A;
  font-size: 11px;
  font-weight: bold;
}

.trusted-shop .content .href {
  position: absolute;
  bottom: 10px;
  right: 0;
}

.trusted-shop .content a {
  color: #3f86b3;
}

.trusted-shop .content .photo-quality img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.trusted-shop h5 {
  color: #4A4A4A;
  font-size: 17px;
  font-weight: 600;
  padding: 13px;
  margin-top: 0;
  text-align: center;
}

.trusted-shop p {
  color: #4A4A4A;
  font-size: 14px;
  text-align: center;
  padding: 0 13px 0;
}

.shop-products {
  background-color: #fff;
  padding-bottom: 70px;
  color: #4A4A4A;
  font-size: 16px;
}

.shop-products .badges {
  right: 90px;
}

.shop-products .mug-badges .badges {
  height: 105px;
}

.shop-products .mug-badges .price {
  color: #ffffff;
}

.shop-products .modal-body .images-with-text {
  padding-bottom: 15px;
}

.shop-products .frame-img img {
  height: 45px;
  width: 45px;
  margin: 0 auto;
}

.shop-products .seo-image {
  display: none;
}

.shop-products img {
  width: 100%;
}

.shop-products ul {
  padding-bottom: 10px;
}

.shop-products li {
  padding-bottom: 5px;
}

.shop-products .icon {
  position: relative;
  display: block;
  width: 52px;
  height: 34px;
  border-radius: 8px;
  margin: 25px auto 0;
}

.shop-products .icon:before {
  position: absolute;
  top: 4px;
  left: 4px;
  content: '';
  width: 44px;
  height: 26px;
  background-color: #fff;
  border-radius: 4px;
}

.shop-products .icon-text {
  line-height: 18px;
  min-height: 40px;
  display: block;
  vertical-align: middle;
  margin-bottom: 15px;
}

.shop-products .stands {
  padding-top: 30px;
}

.shop-products .image-tabs {
  padding-top: 15px;
}

.shop-products .colors .box {
  padding-top: 20px;
}

.shop-products .colors .gold {
  background-color: #FFC600;
  background-image: linear-gradient(to top, #FFC600, #FFF370);
}

.shop-products .colors .silver {
  background-color: #BDBDBD;
  background-image: linear-gradient(to top, #BDBDBD, #F4F4F4);
}

.shop-products .colors .shblack {
  background-color: #131313;
  background-image: linear-gradient(to top, #131313, #C9C7C7);
}

.shop-products .colors .black {
  background: #333;
}

.shop-products .colors .white {
  border: 1px solid #333;
}

.shop-products .colors .white:after {
  width: 40px;
  height: 22px;
  border-radius: 4px;
  border: 1px solid #333;
  position: absolute;
  top: 5px;
  left: 5px;
  content: '';
}

.shop-products .colors .wood {
  background: #73472E;
}

.shop-products .colors .wood_lighter {
  background: #B4936A;
}

.shop-products .colors .wood_darker {
  background: #E6A45C;
}

.shop-products .colors .wood_red {
  background: #97451C;
}

.shop-products .product-image-choose {
  border: 1px solid #aaa;
  padding: 10px 0;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 4px;
}

.shop-products .product-image-choose:hover {
  color: #3f86b3;
  border-color: #3f86b3;
}

.shop-products .custom-button {
  padding: 50px 0 10px;
}

.shop-products .custom-button label {
  float: none;
  padding: 10px 20px;
  font-size: 18px;
}

.shop-products .custom-height {
  height: 360px !important;
}

.shop-products .product {
  height: 460px;
  position: relative;
}

.shop-products .product .price {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding-bottom: 0;
}

.shop-products .product img {
  height: 260px;
  object-fit: cover;
}

.shop-products .img {
  width: 100%;
}

.shop-products .active {
  color: #3f86b3;
  border-color: #3f86b3;
}

.shop-products h1 {
  padding-top: 75px;
  padding-bottom: 50px;
  text-align: center;
  font-weight: 400;
}

.shop-products h1 span {
  font-weight: 800;
}

.shop-products h4 {
  color: #4A4A4A;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 10px;
}

.shop-products .text {
  font-size: 14px;
  padding: 20px 11px;
  margin-bottom: 0;
}

.shop-products .product-main-text {
  font-size: 14px;
}

.shop-products .product-text {
  font-size: 14px;
}

.shop-products .product-text span {
  font-size: 16px;
  font-weight: 600;
}

.shop-products .price {
  color: #FA6362;
  font-size: 28px;
  font-weight: 600;
  padding: 0 10px 10px;
}

.shop-products .price span {
  font-size: 22px;
}

.shop-products .bottom {
  padding-bottom: 25px;
}

.shop-products button, .shop-products label {
  float: right;
  padding: 8px 17px;
  color: #FFFFFF;
  font-size: 14px;
}

.shop-products .modal-container,
.shop-products .modal-wrapper .close-modal {
  max-width: 700px;
  width: 90%;
}

.shop-products .modal-container .modal-body,
.shop-products .modal-wrapper .close-modal .modal-body {
  margin: 0;
}

.shop-products .modal-container .modal-button,
.shop-products .modal-wrapper .close-modal .modal-button {
  padding: 15px 0 25px;
}

.shop-products .modal-container button,
.shop-products .modal-wrapper .close-modal button {
  width: 140px;
  float: none;
}

.shop-products .modal-container .btn-primary, .shop-products .modal-container .btn-default,
.shop-products .modal-wrapper .close-modal .btn-primary,
.shop-products .modal-wrapper .close-modal .btn-default {
  text-transform: none;
}

.payments h4 {
  padding-top: 40px;
  padding-bottom: 50px;
  color: #4A403F;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
}

.payments input::placeholder {
  color: #AAAAAA;
  font-size: 14px;
  font-style: italic;
}

.payments .master-card .amazon {
  padding-top: 10px;
}

.payments .master-card img {
  max-height: 110px;
  max-width: 100%;
  padding-bottom: 60px;
  margin: 0 auto;
}

.payments .pay-container {
  height: 200px;
  padding: 10px 20px 20px;
}

.payments .pay-container input[type=email] {
  height: 35px;
  margin-right: 20px;
  border: 1px solid #E1E1E1;
}

.payments .pay-container input[type="checkbox"] {
  margin-left: -20px;
  margin-top: 2px;
}

.payments .pay-container .checkbox {
  margin-top: 0;
}

.payments .pay-container .icons {
  margin-left: 15px;
}

.payments .pay-container button {
  height: 35px;
  font-size: 16px;
  font-weight: normal;
}

.payments .pay-container h4 {
  padding-top: 0;
  padding-bottom: 0;
}

.payments .pay-container .offer {
  color: #4A403F;
  font-size: 18px;
  font-weight: 600;
}

.payments .pay-container label {
  padding-top: 20px;
  color: #4A4A4A;
  font-size: 12px;
}

.payments .pay-container .icon {
  color: #4A4A4A;
  font-size: 18px;
  padding: 10px 30px 20px 0;
}

.payments .pay-container .checkbox a {
  color: #3f86b3;
  font-size: 12px;
  text-decoration: underline;
}

/* CSS used here will be applied after bootstrap.css */
.navbar-second {
  margin-bottom: 0;
  background-color: white !important;
}

.navbar-second li.dropdown.open, .navbar-second li.dropdown:hover {
  position: static;
}

.navbar-second li.dropdown.open .dropdown-menu, .navbar-second li.dropdown:hover .dropdown-menu {
  display: table;
  width: 100%;
  text-align: center;
  left: 0;
  right: 0;
  box-shadow: none;
}

.navbar-second li.dropdown.open .dropdown-menu > li, .navbar-second li.dropdown:hover .dropdown-menu > li {
  display: table-cell;
}

.navbar-second .dropdown-menu {
  top: unset !important;
  width: 100%;
  background-color: #FAFAFA;
  border-radius: 0;
  border-top: 1px solid #999999;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
}

.shop-navigation a, .shop-dropdown-items a {
  text-decoration: none;
}

.shop-navigation a:hover, .shop-dropdown-items a:hover, .shop-navigation a:active, .shop-dropdown-items a:active, .shop-navigation a:focus, .shop-dropdown-items a:focus {
  color: #3C83B0;
  text-decoration: none;
}

.shop-navigation .new-item, .shop-dropdown-items .new-item {
  position: absolute;
  font-size: 14px;
  top: -10px;
  margin-left: 30px;
  text-shadow: 2px 2px 2px #FFF;
  color: red;
  font-weight: 700;
}

.shop-dropdown-items {
  padding: 20px !important;
}

.shop-dropdown-items .content {
  display: inline-block;
  vertical-align: top;
  padding: 0 20px 15px 0;
}

.shop-dropdown-items img {
  height: 75px;
  width: 125px;
  margin: 0 auto;
}

.shop-dropdown-items p {
  width: 125px;
  margin: 0 auto;
  font-size: 13px;
  font-weight: 600;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.moved-title {
  color: #333333;
  font-size: 21px;
  line-height: 28px;
  font-family: 'Open Sans', sans-serif;
}

.moved-title a.text-primary {
  color: #333333;
}

.moved-title a.text-primary:hover {
  color: #3f86b3;
}

.shop-landing {
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
}

.shop-landing.home-lifestyle {
  background-image: url("/themes/photo-club/images/de/shop/produkte/home-lifestyle/background.jpg");
}

.shop-landing.photo-gifts {
  background-image: url("/themes/photo-club/images/de/shop/produkte/fotogeschenke/background.jpg");
}

.shop-landing.with-text {
  position: relative;
  background-image: url("/themes/photo-club/images/de/shop/produkte/mit-text/background.jpg");
}

.shop-landing.with-text .button {
  text-align: center;
  position: absolute;
  bottom: 50px;
  width: 100%;
}

.shop-landing.with-text label {
  padding: 20px;
  font-size: 20px;
}

.shop-landing.wall-deco {
  background-image: url("/themes/photo-club/images/de/shop/produkte/wand-deko/background.jpg");
}

.modal-button label {
  float: none;
}

.pc-shop-logo {
  margin-left: 15px;
}

.force-pointer-event {
  pointer-events: auto !important;
}

#toast-holder {
  position: fixed;
  bottom: 0;
  left: 43%;
}

#toast-holder .toast-message {
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  background-color: #222;
  background-color: rgba(34, 34, 34, 0.9);
  border: 1px solid #222;
  opacity: 1;
  transition: all .3s;
  transform: translateY(0);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  width: 250px;
  margin-bottom: 10px;
}

@keyframes slideInFromTop {
  0% {
    max-height: 0;
  }
  20% {
    max-height: 5px;
  }
  40% {
    max-height: 10px;
  }
  60% {
    max-height: 15px;
  }
  80% {
    max-height: 20px;
  }
  100% {
    max-height: 25px;
  }
}

#top-banner {
  animation: 0.5s ease-out 0s 1 slideInFromTop;
  position: relative;
  top: 0;
  text-align: center;
  width: 100%;
  background-color: #cc1717;
}

#top-banner .navbar-collapse {
  padding-top: 0;
}

@media (min-width: 992px) {
  .shop-landing {
    display: block;
    height: 500px;
  }
  .shop-landing.blanket {
    background-image: url("/themes/photo-club/images/de/shop/produkte/decke/background.jpg");
  }
  .shop-landing.tote-bag {
    background-image: url("/themes/photo-club/images/de/shop/produkte/tote-bag/background.jpg");
  }
  .shop-landing.canvas {
    background-image: url("/themes/photo-club/images/de/shop/wall-with-shelf.jpg");
    background-position: center bottom;
  }
  .shop-landing.wall-cloth {
    background-image: url("/themes/photo-club/images/de/shop/produkte/wandtuch/background.jpg");
  }
  .shop-landing.forex {
    background-image: url("/themes/photo-club/images/de/shop/produkte/forex/background.jpg");
  }
  .shop-landing.acrylic-glass {
    background-image: url("/themes/photo-club/images/de/shop/produkte/acryl/background.jpg");
  }
  .shop-landing.acrylic-block {
    background-image: url("/themes/photo-club/images/de/shop/produkte/acrylblock/background.jpg");
  }
  .shop-landing.cushion {
    background-image: url("/themes/photo-club/images/de/shop/produkte/kissen/teaser-desktop.jpg");
  }
  .shop-landing.photo-calendar {
    background-image: url("/themes/photo-club/images/de/shop/produkte/fotokalender/background.jpg");
  }
  .shop-landing.photo-book {
    background-image: url("/themes/photo-club/images/de/shop/produkte/fotobuch/background.jpg");
    background-size: cover;
  }
  .shop-landing.bilderbox {
    background-image: url("/themes/photo-club/images/de/shop/produkte/bilderbox/background.jpg");
  }
  .shop-landing.phone-case {
    background-image: url("/themes/photo-club/images/de/shop/produkte/handyhulle/background.jpg");
  }
  .shop-landing.mug {
    background-image: url("/themes/photo-club/images/de/shop/produkte/tasse/background.jpg");
  }
  .shop-landing.alu-dibond {
    background-image: url("/themes/photo-club/images/de/shop/produkte/alu-dibond/background.jpg");
  }
  .shop-landing.acrylic-dibond {
    background-image: url("/themes/photo-club/images/de/shop/produkte/acryl-alu/background.jpg");
  }
  .shop-landing.canvas-plus {
    background-image: url("/themes/photo-club/images/de/shop/produkte/leinwand-plus/background.jpg");
  }
  .shop-landing.canvas-big {
    background-image: url("/themes/photo-club/images/de/shop/produkte/riesenleinwand/background.jpg");
  }
  .shop-landing.holz {
    background-image: url("/themes/photo-club/images/de/shop/produkte/holz/background.jpg");
  }
  .shop-landing.towel {
    background-image: url("/themes/photo-club/images/de/shop/produkte/handtuch/background.jpg");
  }
  .shop-landing .content {
    padding-top: 70px;
    padding-bottom: 120px;
  }
  .shop-landing .right-side {
    background-color: #ffffff;
  }
  .shop-landing .cushion-button {
    padding-top: 270px;
  }
  .navbar-second {
    height: auto;
  }
  .navbar-second.navbar-default .navbar-nav > .open > a {
    background-color: transparent;
    color: #3f86b3;
  }
  .shop-dropdown-items .container {
    /* two times the padding for bootstrap containers */
    padding-left: 30px;
  }
  .shop-dropdown-items p {
    font-size: 11px;
    font-weight: 400;
    color: black;
  }
}

@media (max-width: 992px) {
  .dashboard-title {
    margin-top: 15px;
  }
  .navbar .navbar-header {
    margin-left: 15px;
  }
  .shop-landing .mug-button .badges {
    top: 120px;
  }
  .shop-landing .mug-button label {
    font-size: 11px;
  }
  .mobile-menu-icon {
    display: inline-block;
    vertical-align: middle;
  }
  .navbar-second .navbar-header {
    margin-top: 13px;
  }
  .navbar-second .navbar-header .navbar-toggle {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  iframe {
    width: 100%;
  }
  .shop-landing {
    height: auto;
  }
  .shop-landing .right-side label[for=custom-image-classic] {
    margin-bottom: 10px;
  }
  .shop-landing.home-lifestyle, .shop-landing.photo-gifts, .shop-landing.with-text, .shop-landing.wall-deco {
    height: 200px;
  }
  .p-top-200-mobile {
    padding-top: 200px;
  }
  .shop-products h1 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar {
    margin-bottom: 0;
  }
  .navbar-collapse {
    padding-top: 0;
  }
  .navbar-collapse.collapse {
    padding-top: 0;
  }
  .collapsing {
    height: 0 !important;
  }
  .navbar-toggle {
    float: left;
    margin-top: 0;
    margin-bottom: 0;
  }
  .icon-container {
    width: 30px;
    height: auto;
  }
  .mobile-menu-close-icon {
    display: none;
  }
  .icon-bar {
    display: none !important;
  }
  .collapsed .icon-bar {
    display: block !important;
  }
  .collapsed .close {
    display: none;
  }
  .navbar-default .navbar-toggle:hover,
  .navbar-default .navbar-toggle:focus {
    background-color: white;
  }
  .navbar-nav .open .dropdown-menu {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
    left: 0;
    margin-top: 14px;
    min-width: auto;
    border-radius: unset;
  }
  .trusted-shop .content {
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
  .shop-dropdown-items {
    padding: 0 !important;
  }
  .shop-dropdown-items .content {
    display: block;
    padding: 0;
  }
  .shop-dropdown-items .content img {
    display: none;
  }
  .shop-landing .mug-button label {
    font-size: 15px;
  }
  .badges {
    right: 0;
    top: -130px;
    bottom: auto;
    white-space: nowrap;
  }
  .badges.badges--longer .subtext {
    font-size: 12px;
  }
  .badges:before {
    border-top: 39px solid transparent;
    border-bottom: 39px solid transparent;
  }
  .badges .size {
    font-size: 12px;
  }
  .badges .price {
    font-size: 20px;
  }
  .badges .sub {
    font-size: 12px;
  }
  .blanket .badges {
    top: 70px;
  }
  .shop-navigation, .shop-dropdown-items {
    width: 100%;
    background-color: white;
  }
  .shop-navigation *, .shop-dropdown-items * {
    color: #B2B2B2;
  }
  .shop-navigation .dropdown > a, .shop-dropdown-items .dropdown > a {
    pointer-events: none;
    text-align: left;
    padding: 10px !important;
  }
  .shop-navigation .dropdown > a span, .shop-dropdown-items .dropdown > a span {
    display: block;
    position: relative;
    font-size: 12px;
    text-transform: capitalize;
  }
  .shop-navigation .dropdown > a span i, .shop-dropdown-items .dropdown > a span i {
    color: #B2B2B2 !important;
    position: absolute;
    display: table;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 5px;
  }
  .shop-navigation li, .shop-dropdown-items li {
    display: block;
  }
  .navbar-second {
    z-index: 1000;
    position: relative;
    box-shadow: none;
    border-top: 1px solid #ccc;
  }
  .navbar-second .navigation {
    margin-top: 0;
    max-width: 65%;
    display: inline-block;
  }
  .navbar-second > .container > .row {
    position: static;
  }
  .navbar-second .navbar-header {
    margin-top: 13px;
    min-height: 41px;
    float: none;
  }
  .navbar-second .navbar-header button {
    border: 1px solid #B7B7B7;
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
    border-collapse: collapse;
    transition: border-bottom 1s ease-out;
  }
  .navbar-second .navbar-header.children-collapsed button {
    transition: border-bottom 1s ease-out;
    border-bottom: 1px solid #B7B7B7;
    margin-bottom: 1px;
    border-radius: 4px;
  }
  .navbar-second .navbar-header,
  .navbar-second #products-navbar {
    margin-left: 10px;
    padding-right: 15px;
  }
  .navbar-second .navbar-header {
    margin-left: 15px;
  }
  .navbar-second .shop-navigation, .navbar-second .shop-dropdown-items {
    border: 1px solid #B7B7B7;
  }
  .navbar-second .navbar-toggle {
    border: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-right: 0;
  }
  .navbar-second .navbar-toggle.collapsed {
    border-radius: 4px;
  }
  .navbar-second li.dropdown {
    padding-right: 0 !important;
    position: relative;
    text-align: center;
    border-bottom: 1px solid #B7B7B7;
    display: block;
  }
  .navbar-second li.dropdown:last-child {
    border-bottom: none;
  }
  .navbar-second li.dropdown .dropdown-menu {
    background-color: #F1F1F2;
    min-width: auto;
    position: relative;
    box-shadow: none;
    float: none;
    display: block !important;
    left: 0;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    border: none;
    overflow: hidden;
  }
  .navbar-second li.dropdown .dropdown-menu.shop-dropdown-items a {
    display: block;
    padding: 13px 10px;
  }
  .navbar-second li.dropdown .dropdown-menu.shop-dropdown-items .content {
    border-bottom: 1px solid #B7B7B7;
  }
  .navbar-second li.dropdown .dropdown-menu.shop-dropdown-items p {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    width: auto;
    text-align: left;
    font-weight: 400;
  }
  .navbar-second li.dropdown .dropdown-menu.shop-dropdown-items .row > div:first-child > .content:first-child {
    border-top: 1px solid #B7B7B7;
  }
  .navbar-second li.dropdown .dropdown-menu.shop-dropdown-items .row > div:last-child > .content:last-child {
    border-bottom: 0;
  }
  .navbar-second li.dropdown .dropdown-toggle {
    background-color: #3F3F3F;
  }
  .navbar-second li.dropdown.open .dropdown-menu {
    max-height: 500px;
    transition: max-height 0.2s ease-in;
    display: inline-block !important;
    margin-bottom: -3px;
  }
  .navbar-second li.dropdown.open i:before {
    content: "\f106";
  }
  .navbar-default .navbar-collapse,
  .navbar-default .navbar-form {
    border: none;
  }
  #top-banner .text-right, #top-banner .text-left {
    text-align: center;
  }
}

@media (max-width: 400px) {
  .badges, .newsletter .badges {
    width: 80px;
  }
  .badges.no-discount, .newsletter .badges.no-discount {
    width: auto;
  }
}

@media (max-width: 320px) {
  .navbar-nav .open .dropdown-menu {
    position: absolute;
  }
}

@media (max-width: 992px) {
  .bilderbox {
    display: table;
  }
  .trusted-shop {
    display: inline-block;
    padding-bottom: 0;
  }
  .trusted-shop .content {
    margin-bottom: 20px;
  }
  .trusted-shop .content table td {
    font-size: 11px;
  }
  .trusted-shop .table > thead > tr > th {
    padding: 2px;
  }
  .shop-landing .right-side {
    padding: 13px;
  }
  .shop-landing .right-side label[for=custom-image-classic] {
    margin-bottom: 10px;
  }
  /** Navigation for shop tablet **/
  .navbar-second {
    z-index: 1000;
  }
  .navbar-second li.dropdown {
    padding-right: 0 !important;
    position: relative;
    text-align: center;
    border-bottom: 1px solid #B7B7B7;
    display: block;
  }
  .navbar-second li.dropdown .dropdown-menu {
    background-color: #F1F1F2;
    min-width: auto;
    position: relative;
    box-shadow: none;
    float: none;
    display: none !important;
    left: 0;
    max-height: 0;
    transition: max-height 0.2s ease-out;
    border: none;
    overflow: hidden;
  }
  .navbar-second li.dropdown.open .dropdown-menu {
    max-height: 500px;
    transition: max-height 0.2s ease-in;
    display: inline-block !important;
    background-color: #575757;
    margin-top: 0;
  }
  .navbar-second li.dropdown.open .dropdown-menu .container {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-second .navbar-nav .open .dropdown-menu {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
    width: 30%;
    left: 0;
  }
  .navbar-second .shop-navigation, .navbar-second .shop-dropdown-items, .navbar-second .shop-dropdown-items {
    border: 1px solid #B7B7B7;
  }
  .navbar-second .shop-navigation, .navbar-second .shop-dropdown-items {
    border-bottom: 0;
  }
  .navbar-second .navigation {
    margin-top: 0;
    display: inline-block;
  }
  .navbar-second .shop-navigation, .navbar-second .shop-dropdown-items, .navbar-second .shop-dropdown-items {
    width: 100%;
    background-color: #575757;
  }
  .navbar-second .shop-navigation .dropdown > a, .navbar-second .shop-dropdown-items .dropdown > a, .navbar-second .shop-dropdown-items .dropdown > a {
    pointer-events: none;
    text-align: left;
    padding: 10px !important;
  }
  .navbar-second #cart-icon {
    float: right;
    margin: 15px auto auto;
    bottom: 0;
    background-color: white;
    padding: 5px;
    width: auto;
  }
  .navbar-toggle {
    margin-bottom: 3px;
    display: block;
  }
  .navbar-header {
    float: none;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-nav {
    float: none !important;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .collapse.in {
    display: block !important;
  }
}

@media (min-width: 767px) {
  .hidden-xs .navbar-collapse {
    float: left;
  }
}

.mixpix-product-page img {
  max-width: 100%;
  margin: auto;
}

.mixpix-product-page .shop-landing {
  background-image: url("/themes/photo-club/images//shop/products/mixpix/header.jpg");
  background-position: bottom right;
}

.mixpix-product-page .shop-landing .right-side {
  padding: 15px 35px;
  margin-right: -10px;
  max-width: 465px;
}

.mixpix-product-page .shop-landing .right-side h3 {
  color: #4A4A4A;
  font-family: "Open Sans";
  font-size: 26px;
  font-weight: bold;
  line-height: 32px;
}

.mixpix-product-page .shop-landing .right-side ul {
  padding-left: 25px;
  margin-bottom: 20px;
}

.mixpix-product-page .shop-landing .right-side ul li {
  color: #4A4A4A;
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 21px;
}

.mixpix-product-page .shop-landing .right-side label {
  width: auto;
  padding: 15px 40px;
  font-size: 16px;
}

.mixpix-product-page .badges {
  border-color: #F10408;
  background-color: #F10408;
  height: 88px;
  bottom: 30px;
  top: auto;
  width: auto;
  right: 0;
}

.mixpix-product-page .badges:before {
  border-right: 26px solid #F10408;
  border-bottom: 41px solid transparent;
  border-top: 46px solid transparent;
}

.mixpix-product-page .badges .size {
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.mixpix-product-page .badges .price {
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  text-align: center;
}

.mixpix-product-page .badges .sub {
  color: #FFFFFF;
  font-family: "Open Sans";
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.mixpix-product-page .preview-container {
  position: relative;
  margin-left: -40px;
  margin-top: 10px;
  display: inline-block;
}

.mixpix-product-page .preview-container img {
  margin: 0;
}

.mixpix-product-page .trusted-shop {
  text-align: center;
  width: 100%;
}

.mixpix-product-page .trusted-shop .col-md-4, .mixpix-product-page .trusted-shop .col-sm-4 {
  width: auto;
  float: none;
  display: inline-block;
  vertical-align: middle;
}

.mixpix-product-page .trusted-shop .content {
  width: 310px;
}

.mixpix-product-page .mixpix-body {
  background-color: #fff;
  text-align: center;
}

.mixpix-product-page .mixpix-body h2 {
  color: #4A4A4A;
  font-family: "Open Sans";
  font-size: 34px;
  line-height: 46px;
  text-align: center;
  margin-top: 45px;
  margin-bottom: 0;
}

.mixpix-product-page .mixpix-body .sidelines {
  margin-top: 13px;
  margin-bottom: 45px;
  color: #4A4A4A;
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.mixpix-product-page .mixpix-body .mixpix-block-container {
  padding: 55px 0 56px;
  font-size: 0;
}

.mixpix-product-page .mixpix-body .mixpix-block-container .mixpix-block {
  display: inline-block;
  max-width: 320px;
  margin: 0 9px;
  text-align: left;
  vertical-align: top;
}

.mixpix-product-page .mixpix-body .mixpix-block-container .mixpix-block img {
  margin-bottom: 19px;
}

.mixpix-product-page .mixpix-body .mixpix-block-container .mixpix-block strong {
  display: block;
  color: #4A4A4A;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 5px;
}

.mixpix-product-page .mixpix-body .mixpix-block-container .mixpix-block p {
  color: #4A4A4A;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 18px;
}

.mixpix-product-page .mixpix-body .mixpix-blocks-button {
  margin-bottom: 40px;
}

.mixpix-product-page .mixpix-body .gif-paragraph {
  color: #4A4A4A;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  max-width: 488px;
  margin: 0 auto 70px;
}

.mixpix-product-page .mixpix-body .video-p-container {
  text-align: left;
}

.mixpix-product-page .mixpix-body .video-p-container label {
  margin-bottom: 50px;
}

.mixpix-product-page .mixpix-body .video-paragraph {
  color: #4A4A4A;
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 18px;
  padding: 37px 25px 0 0;
  margin-bottom: 15px;
}

.mixpix-product-page .mixpix-body .video-block {
  box-sizing: border-box;
  margin-top: 12px;
  margin-bottom: 190px;
}

@media (max-width: 992px) {
  .mixpix-product-page .shop-landing {
    background-image: none;
  }
}

@media (max-width: 767px) {
  .mixpix-product-page .badges {
    right: -15px;
  }
  .mixpix-product-page .preview-container {
    margin: 25px auto 20px;
    width: 100%;
  }
  .mixpix-product-page .shop-landing .right-side {
    margin: auto;
    padding: 15px 8px;
  }
  .mixpix-product-page .shop-landing .right-side h3 {
    color: #4A4A4A;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: bold;
    line-height: 27px;
    margin-bottom: 5px;
  }
  .mixpix-product-page .shop-landing .right-side ul {
    padding-left: 20px;
    margin-bottom: 10px;
  }
  .mixpix-product-page .shop-landing .right-side ul li {
    color: #4A4A4A;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  .mixpix-product-page .shop-landing .right-side ul li:before {
    position: absolute;
  }
  .mixpix-product-page .shop-landing .right-side label {
    padding: 9px 10px;
    line-height: 30px;
    font-size: 15px;
    height: auto;
    width: 100%;
  }
  .mixpix-product-page .trusted-shop {
    margin-top: 15px;
    display: block;
  }
  .mixpix-product-page .trusted-shop .content {
    margin-bottom: 27px;
  }
  .mixpix-product-page .mixpix-body h2 {
    color: #4A4A4A;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    margin-top: 20px;
  }
  .mixpix-product-page .mixpix-body .sidelines {
    color: #4A4A4A;
    font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    position: relative;
    margin: 7px 20px 25px;
  }
  .mixpix-product-page .mixpix-body .sidelines span {
    margin: 0 40px;
  }
  .mixpix-product-page .mixpix-body .sidelines:before {
    left: 0;
    right: 0;
    width: 100%;
    margin: auto;
  }
  .mixpix-product-page .mixpix-body .mixpix-block-container {
    padding-top: 0;
    padding-bottom: 0;
  }
  .mixpix-product-page .mixpix-body .mixpix-block-container .mixpix-block {
    margin: 0;
  }
  .mixpix-product-page .mixpix-body .mixpix-block-container .mixpix-block img {
    margin-bottom: 22px;
  }
  .mixpix-product-page .mixpix-body .mixpix-block-container .mixpix-block strong {
    padding: 0 8px;
  }
  .mixpix-product-page .mixpix-body .mixpix-block-container .mixpix-block p {
    padding: 0 8px;
    margin-bottom: 55px;
  }
  .mixpix-product-page .mixpix-body .mixpix-block-container .mixpix-block:last-child p {
    margin-bottom: 32px;
  }
  .mixpix-product-page .mixpix-body .mixpix-blocks-button {
    margin-bottom: 40px;
  }
  .mixpix-product-page .mixpix-body .gif-paragraph {
    margin-bottom: 55px;
  }
  .mixpix-product-page .mixpix-body .video-p-container {
    text-align: center;
  }
  .mixpix-product-page .mixpix-body .video-p-container label {
    margin-bottom: 50px;
  }
  .mixpix-product-page .mixpix-body .video-paragraph {
    color: #4A4A4A;
    font-family: "Open Sans";
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 25px 10px 0;
  }
  .mixpix-product-page .mixpix-body .video-block {
    margin: 12px auto 0;
  }
  .mixpix-product-page .mixpix-body .mixpix-animated {
    margin-bottom: 20px;
  }
}
