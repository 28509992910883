@import "../../variables";

.about-pages {
  .terms {
    ol {
      counter-reset: item;
      padding-left: 10px;
      padding-bottom: 10px;
    }

    li {
      padding-bottom: 7px;
    }

    ol > li {
      counter-increment: item;
    }

    ol ol > li {
      display: block;
      &:before {
        content: counters(item, ".") ". ";
        margin-left: -20px;
      }
    }
  }

  .search-button {
    padding-top: 7px;
    padding-bottom: 8px;
  }

  .hr-line {
    border-color: $mainColor;
    margin: 40px 0;
  }

  .link {
    color: $activeColor;
  }

  .contact-us {
    padding-bottom: 70px;
    .btn-primary {
      padding: 10px 15px;
      font-weight: normal;
    }
  }

  .contact-us-info {
    .col-sm-12 {
      padding-bottom: 40px;
    }
  }

  table {
    thead {
      tr {
        color: #ffffff;
      }
    }
    tbody {
      tr {
        td {
          border: none;
        }
      }
      tr:nth-of-type(odd) {
        background-color: #ffffff;
      }
    }
    th {
      background-color: $mainColor;
    }
  }

  .more-links {
    * {
      font-size: 14px;
    }

    padding-bottom: 0;
    a {
      color: $mainColor;
      font-size: 14px;
      font-weight: 600;
      &.active {
        color: $secondaryColor
      }
    }
    ul {
      margin: 16px 0;
      list-style: none;
      padding-left: 0;
      li {
        padding-bottom: 8px;
      }
    }
  }

  .container {
    padding-bottom: 60px;
  }

  h1 {
    font-size: 36px;
    font-weight: 800;
    padding-top: 0;
    margin-top: 0;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: 15px;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 10px;
  }

  .icon {
    padding: 70px 0 50px;
  }

  .content {
    p {
      margin-bottom: 25px;
    }
    &.payments {
      p {
        margin-bottom: 40px;
      }
      img {
        padding-bottom: 8px;
      }
    }
    &.contact {
      padding-left: 50px;
      padding-right: 50px;
      h3 {
        margin-top: 50px;
        i {
          color: $primaryColor;
          margin-right: 4px;
          font-size: 20px;
        }
      }
      ul {
        list-style: none;
        padding-left: 0;
        li {
          padding-left: 0;
          a {
            color: $mainColor;
            text-decoration: underline;
          }
        }
      }
    }
    margin-bottom: 50px;

    .box {
      background-color: #EDEDED;
      padding: 15px;
      margin-bottom: 16px;
    }

    .section {
      padding-bottom: 24px;
    }
    p {
      color: #000000;
    }

    ul {
      padding-left: 20px;
      color: #000;
      li {
        padding-left: 14px;
        padding-bottom: 8px;
      }
    }
  }

  @media (max-width: 768px) {
    .icon {
      padding: 30px 0 20px;
    }

    h1 {
      text-align: left;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1px;
    }

    .hr-line {
      margin: 20px 0;
    }

    .content {
      &.contact {
        padding-left: 15px;
        padding-right: 0;

        h3 {
          margin-top: 20px;
        }
      }
    }
  }
}