.confirm-page {
  max-width: 435px;
  margin: auto;

  .white-block {
    padding: 24px 24px 20px;
    margin-bottom: 20px;

    img {
      display: block;
      width: 48px;
      height: 48px;
      margin: 0 auto 27px;
    }

    p {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 16px;
      margin-bottom:20px;
    }

    ul {
      padding-left:24px;

      li {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 16px;
        padding:4px;

        a {
          color: $activeColor;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.welcome {
    p {
      text-align: center;
    }
  }
}