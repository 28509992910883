@import "../../variables";

$priceColor: $secondaryColor;
$mobileMenuBorderColor: #B7B7B7;
$mobileMenuBackgroundColor: #F1F1F2;
$mobileMenuTextColor: $mainColor;
$mobileMenuLeftPadding: 10px;
.btn.btn-pc-primary {
  border-radius: 4px;
  font-weight: 600;
  background-color: #49A0D5;
  border-color: #49A0D5;

  &:hover, &:active, &:active:focus, &.active, &:focus {
    background-color: #3C83B0;
    border-color: #49A0D5;
  }
}

.loading-overlay .table .table-cell p {
  margin-top: 30px;
  font-size: 16px;
}

#shop-iframe {
  border: none;
  width: 100%;
}

#shop-app {
  .fine-print {
    margin-top: 25px;
  }
}

.bigger-line-height {
  line-height: 2em;
}

.sidelines {
  position: relative;
  text-align: center;
  margin: 50px 0;
  font-size: 18px;
  font-weight: 600;
  span {
    padding: 0 20px;
    min-width: 25%;
    display: inline-block;
    position: relative;
    background-color: #fff;
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    width: 83.3333333%;
    top: 50%;
    margin-left: 8.333333%;
    background-color: #aaa;
    left: 0;
  }
}

#checkout-cart-icon {
  position: absolute;
  top: 0;
  right: 5%;
  height: 100%;

  > div {
    display: table;
    height: 100%;
  }

  a {
    color: $darkNavTextColor;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 1px;
    display: table-cell;
    vertical-align: middle;

    .fa-shopping-cart {
      position: absolute;
      right: 0;
      top: 49%;
    }
  }

  a:hover {
    color: $primaryColor;
  }

  i {
    font-size: 20px;
  }
}

#cart-icon {
  float: right;
  padding-top: 10px;
  padding-bottom: 12px;
  text-align: center;
}

#cart-icon, #checkout-cart-icon {
  font-size: 20px;

  .la-shopping-cart:after {
      content: attr(data-count);
      display: inline-block;
      position: relative;
      background: $activeColor;
      height: 1.5rem;
      width: 1.5rem;
      left: -0.8rem;
      bottom: 1.1rem;
      text-align: center;
      font-size: 1.2rem;
      border-radius: 100%;
      color: white;
      padding: 2px;
      font-family: Lato, sans-serif;
      text-align: center;
  }
}

.checkout-height {
  height: 100% !important;
  padding-bottom: 0 !important;
}

.master-body {
  padding-top: 0;
}

.navbar-fixed-top {
  margin-bottom: 0;
}

.navbar {
  border: none;
}

.navbar-second {
  border: none;
  font-size: 12px;
  font-weight: 600;
  height: 64px;
  box-shadow: none;
  .navbar-collapse {
    padding-left: 0;
    padding-top: 0;
  }
  .navigation {
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  .navbar-nav > li > a {
    color: $shopNavigationTextColor;
  }
}

.grey-block {
  background-color: #F9F9F9;
  border: solid 1px #E1E1E1;
}

.img-width {
  width: 100%;
}

.img-center {
  margin: 0 auto;
}

.badges {
  right: 100%;
  bottom: 0;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  padding: 6px 15px 6px 5px;
  border: 2px solid $secondaryColor;
  border-left-width: 1px;
  background-color: $secondaryColor;
  font-size: inherit;
  position: absolute;
  border-radius: 0;
  width: 150px;

  &.badges--longer {
    width: 210px;

    .price {
      .bigger-line-height {
        line-height: 12px;
      }
    }

    .subtext {
      font-size: 16px;
      white-space: nowrap;
    }
  }

  &:before {
    left: -27px;
    border-top: 52px solid transparent;
    border-bottom: 52px solid transparent;
    border-right: 26px solid $secondaryColor;
    content: '';
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    top: -2px;
  }
  .size {
    font-size: 16px;
  }
  .price {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
  }
  .sub {
    font-size: 16px;
    text-decoration: line-through;
  }
}

.newsletter {
  .badges {
    &.badges--longer {
      width: 170px;
    }
  }

  .shop-landing, &.shop-landing {
    .right-side {
      h3 {
        font-size: 22px;
      }
    }
  }
  h2 {
    font-size: 45px;
  }

  img.campaignDeals {
    width: 100%;
  }
  .custom-button {
    margin-top: 10px;
    margin-right: 20px;
  }
}

.shop-landing {
  .fine-print {
    margin-bottom: 60px;
  }
  .mug-text {
    padding-top: 20px;
    span {
      font-size: 33px;
      font-weight: 800;
    }
  }
  .cushion-text {
    font-size: 16px;
    padding: 25px;
    margin-top: 3px;
    text-align: center;
    span {
      display: block;
      font-size: 24px;
      font-weight: 800;
    }
  }

  .mug-button {
    .badges {
      top: 205px;
      right: 10px;
      bottom: auto;
      width: auto;
      .price {
        font-size: 24px;
      }
      .size {
        font-size: 14px;
      }
      .sub {
        font-size: 14px;
      }
      &:before {
        border-top: 46px solid transparent;
        border-bottom: 46px solid transparent;
      }
    }
    h2 {
      color: #4a4a4a;
      margin: 20px 40px 20px 0;
      font-weight: bold;
      font-size: 1.5em;
    }
    label {
      width: 80%;
      font-weight: 700;
      font-size: 15px;
      color: #fff;
      letter-spacing: normal;
      padding: 10px 0;
      height: auto;
    }
  }
  .cushion-button {
    label {
      width: 60%;
    }
  }
  .red {
    color: #F10006;
    font-weight: 700;
  }
  .product-circle {
    position: absolute;
    top: -15px;
    left: -15px;
    height: 92px;
    width: 92px;
  }
  .right-side {
    padding: 25px;
    h3 {
      margin-top: 0;
      color: #4A403F;
      font-size: 24px;
      letter-spacing: 0.5px;
      span {
        font-weight: 800;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      padding-left: 1.3em;
      overflow: hidden;
      li {
        color: #4A4A4A;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
        &:before {
          color: $secondaryColor;
          content: "\f00c"; /* FontAwesome Unicode */
          font-family: FontAwesome;
          display: inline-block;
          margin-left: -19px;
          margin-right: 10px;
          float: left;
          height: 23px;
        }
      }
    }
    .link {
      color: $primaryColor;
      text-decoration: underline;
    }
    .bottom {
      padding-top: 50px;
    }
    label {
      width: 100%;
      padding: 15px;
      height: 55px;
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.big_upload_button {
  width: 100%;
  padding: 15px;
  height: 55px;
  font-size: 18px;
  font-weight: 600;
}

.trusted-shop {
  margin-top: 55px;
  padding-bottom: 50px;
  .content {
    position: relative;
    width: 100%;
    height: 310px;
    @extend .grey-block;
    img {
      max-width: 45%;
      padding: 0 0 10px 10px;
    }
    table {
      margin-bottom: 0;
      td {
        color: #4A4A4A;
        font-size: 14px;
        padding: 2px 8px;
        border-top: none;
      }
      th {
        color: #848484;
        font-size: 14px;
        font-weight: bold;
        border-bottom: none;
      }
      th:nth-child(3) {
        background: #848484;
        color: #fff;
        -webkit-border-radius: 5px 5px 0 0;
        -moz-border-radius: 5px 5px 0 0;
        border-radius: 5px 5px 0 0;
        text-align: center;
      }
      thead tr {
        background-color: transparent !important;
      }
      tr:nth-child(odd) {
        background-color: #fff;
      }
      tr:nth-child(even) {
        background-color: #f3f3f3;
      }
      tr:nth-child(odd) .highlight {
        color: #fff;
        background: $secondaryColor;
        text-align: center;
        font-weight: 700;
      }
      tr:nth-child(even) .highlight {
        color: #fff;
        background: #F97F7E;
        text-align: center;
        font-weight: 700;
      }
    }
    .images {
      padding-bottom: 30px;
    }
    span {
      color: #4A4A4A;
      font-size: 11px;
      font-weight: bold;
    }
    .href {
      position: absolute;
      bottom: 10px;
      right: 0;
    }
    a {
      color: $primaryColor;
    }
    .photo-quality {
      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  h5 {
    color: #4A4A4A;
    font-size: 17px;
    font-weight: 600;
    padding: 13px;
    margin-top: 0;
    text-align: center;
  }
  p {
    color: #4A4A4A;
    font-size: 14px;
    text-align: center;
    padding: 0 13px 0;
  }
}

.shop-products {
  background-color: #fff;
  padding-bottom: 70px;
  color: #4A4A4A;
  font-size: 16px;
  .badges {
    right: 90px;
  }
  .mug-badges {
    .badges {
      height: 105px;
    }
    .price {
      color: #ffffff;
    }
  }
  .modal-body {
    .images-with-text {
      padding-bottom: 15px;
    }
  }
  .frame-img {
    img {
      height: 45px;
      width: 45px;
      margin: 0 auto;
    }
  }
  .seo-image {
    display: none;
  }
  img {
    width: 100%;
  }
  ul {
    padding-bottom: 10px;
  }
  li {
    padding-bottom: 5px;
  }
  .icon {
    position: relative;
    display: block;
    width: 52px;
    height: 34px;
    border-radius: 8px;
    margin: 25px auto 0;
    &:before {
      position: absolute;
      top: 4px;
      left: 4px;
      content: '';
      width: 44px;
      height: 26px;
      background-color: #fff;
      border-radius: 4px;
    }
  }

  .icon-text {
    line-height: 18px;
    min-height: 40px;
    display: block;
    vertical-align: middle;
    margin-bottom: 15px;
  }

  .stands {
    padding-top: 30px;
  }

  .image-tabs {
    padding-top: 15px;
  }

  .colors {
    .box {
      padding-top: 20px;
    }
    .gold {
      background-color: #FFC600;
      background-image: linear-gradient(to top, #FFC600, #FFF370);
    }

    .silver {
      background-color: #BDBDBD;
      background-image: linear-gradient(to top, #BDBDBD, #F4F4F4);
    }

    .shblack {
      background-color: #131313;
      background-image: linear-gradient(to top, #131313, #C9C7C7);
    }

    .black {
      background: #333;
    }

    .white {
      border: 1px solid #333;
      &:after {
        width: 40px;
        height: 22px;
        border-radius: 4px;
        border: 1px solid #333;
        position: absolute;
        top: 5px;
        left: 5px;
        content: '';
      }
    }

    .wood {
      background: #73472E;
    }

    .wood_lighter {
      background: #B4936A;
    }

    .wood_darker {
      background: #E6A45C;
    }

    .wood_red {
      background: #97451C;
    }
  }
  .product-image-choose {
    border: 1px solid #aaa;
    padding: 10px 0;
    margin-bottom: 10px;
    font-size: 14px;
    border-radius: 4px;
    &:hover {
      color: $primaryColor;
      border-color: $primaryColor;
    }
  }
  .custom-button {
    padding: 50px 0 10px;
    label {
      float: none;
      padding: 10px 20px;
      font-size: 18px;
    }
  }
  .custom-height {
    height: 360px !important;
  }
  .product {
    height: 460px;
    position: relative;
    @extend .grey-block;
    .price {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding-bottom: 0;
    }
    img {
      height: 260px;
      object-fit: cover;
    }
  }
  .img {
    width: 100%;
  }
  .active {
    color: $primaryColor;
    border-color: $primaryColor;
  }
  h1 {
    padding-top: 75px;
    padding-bottom: 50px;
    text-align: center;
    font-weight: 400;
    span {
      font-weight: 800;
    }
  }
  h4 {
    color: #4A4A4A;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 10px;
  }
  .text {
    font-size: 14px;
    padding: 20px 11px;
    margin-bottom: 0;
  }
  .product-main-text {
    font-size: 14px;
  }
  .product-text {
    font-size: 14px;
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .price {
    color: $secondaryColor;
    font-size: 28px;
    font-weight: 600;
    padding: 0 10px 10px;
    span {
      font-size: 22px;
    }
  }
  .bottom {
    padding-bottom: 25px;
  }
  button, label {
    float: right;
    padding: 8px 17px;
    color: #FFFFFF;
    font-size: 14px;
  }

  .modal-container,
  .modal-wrapper .close-modal {
    max-width: 700px;
    width: 90%;
    .modal-body {
      margin: 0;
    }

    h3 {

    }
    .modal-button {
      padding: 15px 0 25px;
    }
    button {
      width: 140px;
      float: none;
    }
    .btn-primary, .btn-default {
      text-transform: none;
    }
  }
}

.payments {
  h4 {
    padding-top: 40px;
    padding-bottom: 50px;
    color: #4A403F;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  input::placeholder {
    color: #AAAAAA;
    font-size: 14px;
    font-style: italic;
  }
  .master-card {
    .amazon {
      padding-top: 10px;
    }
    img {
      max-height: 110px;
      max-width: 100%;
      padding-bottom: 60px;
      margin: 0 auto;
    }
  }
  .pay-container {
    @extend .grey-block;
    height: 200px;
    padding: 10px 20px 20px;
    input[type=email] {
      height: 35px;
      margin-right: 20px;
      border: 1px solid #E1E1E1;
    }
    input[type="checkbox"] {
      margin-left: -20px;
      margin-top: 2px;
    }
    .checkbox {
      margin-top: 0;
    }
    .icons {
      margin-left: 15px;
    }
    button {
      height: 35px;
      font-size: 16px;
      font-weight: normal;
    }
    h4 {
      padding-top: 0;
      padding-bottom: 0;
    }
    .offer {
      color: #4A403F;
      font-size: 18px;
      font-weight: 600;
    }
    label {
      padding-top: 20px;
      color: #4A4A4A;
      font-size: 12px;
    }
    .icon {
      color: #4A4A4A;
      font-size: 18px;
      padding: 10px 30px 20px 0
    }
    .checkbox a {
      color: $primaryColor;
      font-size: 12px;
      text-decoration: underline;
    }
  }
}

/* CSS used here will be applied after bootstrap.css */
.navbar-second {
  margin-bottom: 0;
  background-color: white !important;
  li.dropdown {
    &.open,
    &:hover {
      position: static;
      .dropdown-menu {
        display: table;
        width: 100%;
        text-align: center;
        left: 0;
        right: 0;
        box-shadow: none;
        > li {
          display: table-cell;
        }
      }
    }
  }

  .dropdown-menu {
    top: unset !important;
    width: 100%;
    background-color: #FAFAFA;
    border-radius: 0;
    border-top: 1px solid #999999;
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
  }
}

.shop-navigation {
  a {
    text-decoration: none;
    &:hover, &:active, &:focus {
      color: $activeColor;
      text-decoration: none;
    }
  }
  .new-item {
    position: absolute;
    font-size: 14px;
    top: -10px;
    margin-left: 30px;
    text-shadow: 2px 2px 2px #FFF;
    color: red;
    font-weight: 700;
  }
}

.shop-dropdown-items {
  @extend .shop-navigation;
  padding: 20px !important;
  .content {
    display: inline-block;
    vertical-align: top;
    padding: 0 20px 15px 0;
  }
  img {
    height: 75px;
    width: 125px;
    margin: 0 auto;
  }
  p {
    width: 125px;
    margin: 0 auto;
    font-size: 13px;
    font-weight: 600;
  }
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.moved-title {
  color: #333333;
  font-size: 21px;
  line-height: 28px;
  font-family: 'Open Sans', sans-serif;

  a.text-primary {
    color: #333333;
    &:hover {
      color: $primaryColor;
    }
  }
}

.shop-landing {
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  &.home-lifestyle {
    background-image: url($imageFolder + 'de/shop/produkte/home-lifestyle/background.jpg');
  }
  &.photo-gifts {
    background-image: url($imageFolder + 'de/shop/produkte/fotogeschenke/background.jpg');
  }
  &.with-text {
    position: relative;
    background-image: url($imageFolder + 'de/shop/produkte/mit-text/background.jpg');
    .button {
      text-align: center;
      position: absolute;
      bottom: 50px;
      width: 100%;
    }
    label {
      padding: 20px;
      font-size: 20px;
    }
  }
  &.wall-deco {
    background-image: url($imageFolder + 'de/shop/produkte/wand-deko/background.jpg');
  }
}

.modal-button {
  label {
    float: none;
  }
}

.pc-shop-logo{
  margin-left: 15px;
}

.force-pointer-event {
  pointer-events: auto !important;
}

#toast-holder {
  position: fixed;
  bottom: 0;
  left: 43%;

  .toast-message {
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    background-color: #222;
    background-color: rgba(34,34,34,.9);
    border: 1px solid #222;
    opacity: 1;
    transition: all .3s;
    transform: translateY(0);
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    width: 250px;
    margin-bottom: 10px;
  }
}

@keyframes slideInFromTop {
  0% {
    max-height: 0;

  }
  20% {
    max-height: 5px;
  }
  40% {
    max-height: 10px;
  }
  60% {
    max-height: 15px
  }

  80% {
    max-height: 20px;
  }
  100% {
    max-height: 25px;
  }
}

#top-banner {
  animation: 0.5s ease-out 0s 1 slideInFromTop;
  position: relative;
  top: 0;
  text-align: center;
  width: 100%;
  background-color: $newsletterTopBar;

  .navbar-collapse {
    padding-top: 0;
  }
}

@import "responsive";
@import "products/mixpix";
