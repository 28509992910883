.btn-primary {
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  border-radius: 40px;
  background-color: $primaryColor;
  color: #FFFFFF;
  font-size: 14px;
  letter-spacing: 1px;
  border: 1px solid $primaryColor;
  text-transform: uppercase;
  font-weight: 700;
  &:hover, &:focus, &.focus, &:active, &:active:hover {
    background-color: $activeColor;
    border-color: $activeColor;
    color: #ffffff !important;
  }
  &.btn-lg {
    padding:11px 12px;
  }
}

.btn-social {
  font-size:12px;
  letter-spacing:1px;
  padding-left:45px !important;
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  pointer-events: none;
  background-color: #999999;
  border-color: transparent;
  opacity: 1;

  &:hover {
    background-color: #999;
    border:1px solid #999;
  }
}

.btn.w100 {
  width:100%;
  padding-left: 0;
}

.btn-default {
  @extend .btn-primary;
  background-color: #fff;
  color: $mainColor;
  border-color: $mainColor;
  &:hover, &:focus, &.focus, &:active, &:active:hover {
    background-color: #fff;
    border-color: #000;
    color: #000 !important;
  }
}

.btn-social {
  position: relative;
  padding-left: 44px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover, &:focus {
    color: #fff;
  }

}

.btn-social > :first-child {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 32px;
  font-size: 1.6em;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .btn-primary {
    white-space: normal;
    height:auto;
    min-height:34px;
  }
}

.disabled-style {
  background-color: #999999;
  border-color: transparent;
  opacity: 1;
  &:hover, &:active, &:focus {
    background-color: #999999 !important;
  }
}