.home-subtitle {
  font-size: 48px;
  font-weight: 500;
  color: #fff;
}

.home-background-image {
  background-size: cover;
  background-position: center;
  width: 100%;
}

.home-background-image .home-subtitle .highlight {
  color: #FA6362;
}

.home-background-image .home-subtitle .increased_size {
  font-size: 78.5px;
  font-weight: 500;
}

.home-background-image .home-divider {
  background-color: #fff;
  max-width: 550px;
  width: 100%;
}

.home-background-image .description {
  font-size: 22px;
  color: #fff;
  margin-bottom: 60px;
}

.home-background-image .btn {
  padding: 13px 42px;
  margin-bottom: 160px;
  background-color: #FA6362;
  border-color: #FA6362;
  font-size: 18px;
  transition: all .3s;
}

.home-background-image .btn:hover, .home-background-image .btn:active {
  background-color: rgba(250, 99, 98, 0.8) !important;
  border-color: rgba(250, 99, 98, 0.8) !important;
}

@media screen and (min-width: 541px) {
  .home-background-image {
    background-image: url("/themes/photo-club/images/home/new/01_desktop/01_desktop_sections_1920x500px.jpg");
  }
}

@media screen and (max-width: 540px) {
  .home-background-image {
    background-image: url("/themes/photo-club/images/home/new/02_mobile/01_mobile_sections_500x400px.jpg");
  }
}

.locale_de .home-subtitle .increased_size {
  font-size: 80.5px;
}

.locale_de .home-background-image .description {
  font-size: 24.5px;
}

@media screen and (max-width: 768px) {
  .locale_de .home-background-image .description {
    font-size: 11.5px;
  }
}

.photokina-banner {
  background-color: #7CC8D4;
  height: 65px;
}

.photokina-banner .btn {
  padding: 9px 73px;
  border-color: #FA6362;
  background-color: #FA6362;
  font-size: 9px;
}

.photokina-banner .btn:hover, .photokina-banner .btn:active, .photokina-banner .btn:active:hover {
  background-color: #FA5362;
}

.home-info {
  background-color: #fafafa;
}

.home-info .title {
  font-size: 72px;
  color: #4A403F;
  font-weight: 900;
  line-height: 70px;
}

.home-info .description {
  font-size: 22px;
  color: #4A403F;
}

.home-info .text-area {
  margin-top: 100px;
}

.home-info > div {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.home-info > div:nth-child(1) {
  background-image: url("/themes/photo-club/images/home/new/01_desktop/02_desktop_sections_1920x500px.jpg");
}

.home-info > div:nth-child(1) .text-area {
  margin: 185px auto;
}

.home-info > div:nth-child(2) {
  background-image: url("/themes/photo-club/images/home/new/01_desktop/03_desktop_sections_1920x500px.jpg");
}

.home-info > div:nth-child(2) .row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.home-info > div:nth-child(2) .text-area {
  margin: 185px auto;
  margin-left: auto;
  margin-right: 0;
}

.home-info > div:nth-child(2) .text-area p {
  text-align: right;
}

.home-info > div:nth-child(3) {
  background-image: url("/themes/photo-club/images/home/new/01_desktop/04_desktop_sections_1920x500px.jpg");
}

.home-info > div:nth-child(3) .text-area {
  margin: 185px auto;
}

.home-info > div:nth-child(4).mask_banner.extend-lp-mask {
  background-image: url("/themes/photo-club/images/auth/new/extend/mask_banner.jpg");
  background-position: center;
}

@media screen and (min-width: 769px) {
  .home-info > div:nth-child(4).mask_banner.extend-lp-mask {
    background-position: 85% !important;
  }
}

@media screen and (max-width: 768px) {
  .home-info > div:nth-child(4).mask_banner.extend-lp-mask p {
    text-shadow: 0 3px 8px rgba(0, 0, 0, 0.32);
    color: #fff;
  }
}

.home-info > div:nth-child(4).mask_banner.extend-lp-mask .text-area {
  margin: 185px auto;
  margin-left: auto;
  margin-right: 0;
  float: none !important;
}

.home-info > div:nth-child(4).mask_banner.extend-lp-mask .text-area p {
  text-align: right;
}

.home-info > div:nth-child(4).mask_banner.mobilcomdebitel-lp-mask {
  background-image: url("/themes/photo-club/images/auth/new/mobilcomdebitel/Mask_banner_new.jpg");
  background-position: center;
}

@media screen and (min-width: 769px) {
  .home-info > div:nth-child(4).mask_banner.mobilcomdebitel-lp-mask {
    background-position: 85% !important;
  }
}

@media screen and (max-width: 768px) {
  .home-info > div:nth-child(4).mask_banner.mobilcomdebitel-lp-mask p {
    text-shadow: 0 3px 8px rgba(0, 0, 0, 0.32);
    color: #fff;
  }
}

.home-info > div:nth-child(4).mask_banner.mobilcomdebitel-lp-mask .text-area {
  margin: 185px auto;
  margin-left: auto;
  margin-right: 0;
  float: none !important;
}

.home-info > div:nth-child(4).mask_banner.mobilcomdebitel-lp-mask .text-area p {
  text-align: right;
}

.home-info > div:nth-child(4).mask_banner.sparkasse-lp-mask {
  background-image: url("/themes/photo-club/images/auth/new/sparkasse/mask_banner.jpg");
  background-position: center;
}

@media screen and (min-width: 769px) {
  .home-info > div:nth-child(4).mask_banner.sparkasse-lp-mask {
    background-position: 85% !important;
  }
}

@media screen and (max-width: 768px) {
  .home-info > div:nth-child(4).mask_banner.sparkasse-lp-mask p {
    text-shadow: 0 3px 8px rgba(0, 0, 0, 0.32);
    color: #fff;
  }
}

.home-info > div:nth-child(4).mask_banner.sparkasse-lp-mask .text-area {
  margin: 185px auto;
  margin-left: auto;
  margin-right: 0;
  float: none !important;
}

.home-info > div:nth-child(4).mask_banner.sparkasse-lp-mask .text-area p {
  text-align: right;
}

@media screen and (max-width: 640px) {
  .home-info > div:nth-child(1) {
    background-image: url("/themes/photo-club/images/home/new/02_mobile/02_mobile_sections_500x400px.jpg");
  }
  .home-info > div:nth-child(1) .text-area {
    padding-left: 60px;
    margin: 30px auto 340px;
  }
  .home-info > div:nth-child(2) {
    background-image: url("/themes/photo-club/images/home/new/02_mobile/03_mobile_sections_500x400px.jpg");
  }
  .home-info > div:nth-child(2) .row {
    justify-content: flex-start;
    align-items: flex-start;
  }
  .home-info > div:nth-child(2) .text-area {
    margin: 30px auto 340px;
  }
  .home-info > div:nth-child(2) .text-area.partner-page {
    margin: 30px auto 380px;
  }
  .home-info > div:nth-child(2) .text-area p {
    text-align: left;
  }
  .home-info > div:nth-child(3) {
    background-image: url("/themes/photo-club/images/home/new/02_mobile/04_mobile_sections_500x400px.jpg");
  }
  .home-info > div:nth-child(3) .text-area {
    margin: 30px auto 340px;
  }
}

.home-logo {
  width: 300px;
  padding-top: 90px;
  margin: 0 auto 100px;
}

.membership-subtitle {
  color: #454553;
}

.membership-plans {
  margin-top: 50px;
  margin-bottom: 100px;
}

.membership-plans .membership-plan {
  width: 295px;
  margin: auto;
  position: relative;
}

.membership-plans .buttons {
  height: 50px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.membership-plans .buttons.special {
  border-color: #7CC8D4;
  background-color: #7CC8D4;
}

.membership-plans .buttons.standard {
  border-color: #454553;
  background-color: #454553;
}

.membership-plans .buttons .text {
  font-weight: 900;
  color: #FFFFFF;
  font-size: 12px;
  text-align: center;
  padding-top: 18px;
}

.membership-plans .content {
  height: 450px;
  border: 1px solid #454553;
  border-radius: 14px;
  box-sizing: border-box;
  padding: 30px;
}

.membership-plans .content ul {
  padding-left: 25px;
}

.membership-plans .content ul li {
  padding-top: 15px;
  font-weight: 600;
  font-size: 14px;
}

.membership-plans .content .bottom {
  position: absolute;
  bottom: 30px;
  left: 30px;
  width: 80%;
}

.membership-plans .content .bottom p {
  font-size: 18px;
  font-weight: bold;
  color: #454553;
}

.membership-plans .content .bottom p.special {
  color: #D8130F;
}

.membership-plans .content .bottom span {
  font-size: 12px;
  font-weight: 900;
  color: #CCCCD0;
  line-height: 25px;
}

.membership-plans .content .bottom .button {
  padding: 10px 0;
  width: 100%;
}

.membership-plans .content .bottom .button.special {
  background-color: #D8130F;
  border-color: #D8130F;
}

@media screen and (max-width: 992px) {
  .home-background-image .description {
    font-size: 18px;
  }
  .mob-logo {
    width: 100%;
  }
  .home-logo {
    padding-top: 30px;
    max-width: 200px;
    margin-bottom: 60px;
  }
  .home-background-image .home-subtitle {
    font-size: 22px;
    text-align: center;
  }
  .home-background-image .home-subtitle .increased_size {
    font-size: 37.5px;
  }
  .home-background-image .description {
    font-size: 11px;
    margin-bottom: 30px;
  }
  .home-background-image .home-divider {
    max-width: 278px;
  }
  .home-background-image .btn {
    font-size: 14px;
    padding: 8px 20px;
  }
  .home-info .title {
    line-height: normal;
    font-size: 42px;
    margin-bottom: 5px;
  }
  .home-info .description {
    font-size: 12px;
    font-weight: 600;
  }
  .membership-subtitle {
    font-size: 28px;
  }
  .membership-plans {
    margin-top: 10px;
  }
}
