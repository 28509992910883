.about-pages .terms ol {
  counter-reset: item;
  padding-left: 10px;
  padding-bottom: 10px;
}

.about-pages .terms li {
  padding-bottom: 7px;
}

.about-pages .terms ol > li {
  counter-increment: item;
}

.about-pages .terms ol ol > li {
  display: block;
}

.about-pages .terms ol ol > li:before {
  content: counters(item, ".") ". ";
  margin-left: -20px;
}

.about-pages .search-button {
  padding-top: 7px;
  padding-bottom: 8px;
}

.about-pages .hr-line {
  border-color: #4A403F;
  margin: 40px 0;
}

.about-pages .link {
  color: #3C83B0;
}

.about-pages .contact-us {
  padding-bottom: 70px;
}

.about-pages .contact-us .btn-primary {
  padding: 10px 15px;
  font-weight: normal;
}

.about-pages .contact-us-info .col-sm-12 {
  padding-bottom: 40px;
}

.about-pages table thead tr {
  color: #ffffff;
}

.about-pages table tbody tr td {
  border: none;
}

.about-pages table tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.about-pages table th {
  background-color: #4A403F;
}

.about-pages .more-links {
  padding-bottom: 0;
}

.about-pages .more-links * {
  font-size: 14px;
}

.about-pages .more-links a {
  color: #4A403F;
  font-size: 14px;
  font-weight: 600;
}

.about-pages .more-links a.active {
  color: #FA6362;
}

.about-pages .more-links ul {
  margin: 16px 0;
  list-style: none;
  padding-left: 0;
}

.about-pages .more-links ul li {
  padding-bottom: 8px;
}

.about-pages .container {
  padding-bottom: 60px;
}

.about-pages h1 {
  font-size: 36px;
  font-weight: 800;
  padding-top: 0;
  margin-top: 0;
}

.about-pages h2 {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  padding-bottom: 15px;
}

.about-pages h3 {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

.about-pages .icon {
  padding: 70px 0 50px;
}

.about-pages .content {
  margin-bottom: 50px;
}

.about-pages .content p {
  margin-bottom: 25px;
}

.about-pages .content.payments p {
  margin-bottom: 40px;
}

.about-pages .content.payments img {
  padding-bottom: 8px;
}

.about-pages .content.contact {
  padding-left: 50px;
  padding-right: 50px;
}

.about-pages .content.contact h3 {
  margin-top: 50px;
}

.about-pages .content.contact h3 i {
  color: #3f86b3;
  margin-right: 4px;
  font-size: 20px;
}

.about-pages .content.contact ul {
  list-style: none;
  padding-left: 0;
}

.about-pages .content.contact ul li {
  padding-left: 0;
}

.about-pages .content.contact ul li a {
  color: #4A403F;
  text-decoration: underline;
}

.about-pages .content .box {
  background-color: #EDEDED;
  padding: 15px;
  margin-bottom: 16px;
}

.about-pages .content .section {
  padding-bottom: 24px;
}

.about-pages .content p {
  color: #000000;
}

.about-pages .content ul {
  padding-left: 20px;
  color: #000;
}

.about-pages .content ul li {
  padding-left: 14px;
  padding-bottom: 8px;
}

@media (max-width: 768px) {
  .about-pages .icon {
    padding: 30px 0 20px;
  }
  .about-pages h1 {
    text-align: left;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
  }
  .about-pages .hr-line {
    margin: 20px 0;
  }
  .about-pages .content.contact {
    padding-left: 15px;
    padding-right: 0;
  }
  .about-pages .content.contact h3 {
    margin-top: 20px;
  }
}
