.registration-page {

  .white-block {
    padding: 16px;
    margin-bottom: 20px;

    h4 {
      color: $mainColor;
      font-size: 18px;
      font-weight: 600;
      line-height: 16px;
      padding-bottom: 15px;
      border-bottom: 2px solid #999;
      margin: 0 0 15px;
    }

    ul {
      padding-left: 40px;
      margin-top: 17px;
    }

    .registration-price {
      text-align: center;
      margin: 22px 0 6px;
    }
  }

  .policy {
    text-align: right;

    button {
      margin: 0 0 20px;
    }
  }

}

.conditions.custom-checkbox-wrapper {
  margin-bottom: 20px;

  label {
    padding-left: 20px;
    margin-bottom: 0;

    &:hover {
      color: #999;
    }
    a {
      color: $activeColor;
      font-weight: 700;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.registration-price {
  position: relative;
  margin-bottom: 20px;

  span:first-child {
    font-size: 48px;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 40px;
  }
  span:last-child {
    height: 17px;
    display: block;
  }
  small {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 16px;
    &.crm {
      color: $descriptionColor;
      font-weight: 600;
    }
  }
  .info-popup {
    position: absolute;
    top: 0;
  }
  .strike {
    text-decoration: line-through;
  }
}

.already-member {
  color: #999;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  text-align: center;

  a {
    color: $activeColor;
    &:hover {
      text-decoration: underline;
    }
  }
}

.registration-tile {
  max-width: 424px;
  margin: 20px auto;
  border-radius: 0 4px 4px 4px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  color: $mainColor;
  text-align: center;

  h3 {
    margin: 0 0 20px;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    &.logo-style {
      font-size: 24px;
      margin: 0 0 5px;
      line-height: 38px;
    }
  }

  .btn {
    width: 100%;
    margin-bottom: 20px;
  }

  .crm-logo {
    margin-bottom: 5px;
  }

}

.registration-tile-new {
  max-width: 500px;
  margin: 100px auto 0;
  border-radius: 4px;
  background: url($imageFolder + '/auth/new/login-register-background.jpg') no-repeat center center;
  background-size: cover;
  padding: 10px 30px 15px 40px;
  color: $mainColor;
  .price-badge {
    float: right;
  }
  .logo {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 220px;
    :not(.activate_mf) {
      float: left;
    }
  }
  h1 {
    font-size: 27px;
    font-weight: 300;
    color: #211B1B;
    img {
      margin-right: 5px;
      margin-left: 5px;
      margin-bottom: 11px;
    }
  }
  .price-text {
    text-align: right;
    font-size: 41px;
    line-height: 40px;
    font-weight: 900;
    color: #211B1B;
    margin-bottom: 0;
    span {
      font-size: 40px;
      font-weight: bold;
    }
  }
  .price-description {
    color: #211B1B;
    font-size: 16px;
    font-weight: 600;
    text-align: right;
  }
  .btn-primary {
    padding: 16px 12px;
    margin: 20px 0 40px;
    width: 100%;
  }
  ul {
    list-style-type: circle;
    padding-left: 20px;
    margin-top: 40px;
    li {
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
    }
  }
}

.password-form-group {
  position: relative;

  a {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    text-align: center;
    opacity: 0.5;
    font-size: 14px;

    &:before {
      padding-top: 9px;
      display: block;
    }
    &:hover {
      opacity: 1;
    }

  }
}

#payment-form, #my-data-form {

  .label-title {
    color: #999999;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    height: 32px;
    margin: 8px 0;
    padding-right: 0;
    display: table;

    span {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .form-group {
    margin-bottom: 0;
  }

  .form-control {
    margin: 8px 0;
    color: #555 !important;
  }

  select {
    padding-right: 0;
    &.form-control {
      text-transform: uppercase;
      color: #555;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 16px;

      option {
        &:disabled {
          color: #C9C9C9;
        }
      }
    }
  }

  .custom-radio {
    color: $mainColor;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    margin: 4px 10px 4px 0;

    label {
      cursor: pointer;
      line-height: 16px;

      &:before {
        margin-right: 0;
      }
    }
  }

  ::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: uppercase;
    color: #C9C9C9;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
  }

  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: uppercase;
    color: #C9C9C9;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
  }

  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: uppercase;
    color: #C9C9C9;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
  }

  :-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: uppercase;
    color: #C9C9C9;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
  }

}

@media screen and (max-width: 550px) {
  .registration-tile-new {
    margin: 60px 0 20px;
    padding: 20px;
    .logo {
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
      float: unset;
    }
    .price-badge {
      width: 100px;
      position: absolute;
      right: 30px;
      top: 115px;
    }
    h1 {
      width: 150px;
      margin: 20px auto;
      font-size: 18px;
      line-height: 30px;
      img {
        margin-right: unset;
        margin-left: unset;
        margin-bottom: 5px;
      }
    }
    .price-text {
      text-align: center;
    }
    .price-description {
      text-align: center;
    }
    ul {
      margin-top: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .conditions.custom-checkbox-wrapper {
    margin: 20px 0;
  }

  .registration-tile {
    h3 {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 24px;
    }
  }

  .registration-price {
    span:first-child {
      font-size: 40px;
      font-weight: 800;
      letter-spacing: 2px;
      line-height: 40px;
    }
    .info-popup {
      font-size: 18px;
    }
  }

  .registration-page {

    .white-block {
      margin-bottom: 30px;
      margin-top: 10px;

      h4 {
        font-size: 18px;
        font-weight: 600;
        line-height: 16px;
        margin-bottom: 8px;
      }

      ul {
        margin-top: 10px;
        padding-left: 23px;
      }
    }

    .policy {
      text-align: left;
      margin-top: -15px;

      button {
        margin: 20px 0;
        display: block;
        width: 100%;
      }
    }

  }

  #payment-form, #my-data-form {
    .label-title {
      height: 30px;
      line-height: 30px;
      padding-top: 7px;
      margin: 0;
    }
    .form-control {
      margin: 0 0 8px;
    }
  }

}