@import "../variables";

.home-subtitle {
  font-size: 48px;
  font-weight: 500;
  color: $white;
}

.home-background-image {
  background-size: cover;
  background-position: center;

  width: 100%;

  .home-subtitle {
    .highlight {
      color: $secondaryColor;
    }

    .increased_size {
      font-size: 78.5px;
      font-weight: 500;
    }
  }

  .home-divider {
    background-color: $white;

    max-width: 550px;
    width: 100%;
  }

  .description {
    font-size: 22px;
    color: $white;
    margin-bottom: 60px;
  }
  .btn {
    padding: 13px 42px;
    margin-bottom: 160px;
    background-color: $secondaryColor;
    border-color: $secondaryColor;
    font-size: 18px;
    transition: all .3s;
  }

  .btn:hover, .btn:active {
    background-color: $secondaryColorActive;
    border-color: $secondaryColorActive;
  }

  @media screen and (min-width: 541px) {
    background-image: url($imageFolder + 'home/new/01_desktop/01_desktop_sections_1920x500px.jpg');
  }

  @media screen and (max-width: 540px) {
    background-image: url($imageFolder + 'home/new/02_mobile/01_mobile_sections_500x400px.jpg');
  }
}

.locale_de {
  .home-subtitle {
    .increased_size {
      font-size: 80.5px;
    }
  }

  .home-background-image {
    .description {
      font-size: 24.5px;
      @media screen and (max-width: 768px) {
        font-size: 11.5px;
      }
    }
  }

}

.photokina-banner {
  background-color: #7CC8D4;
  height: 65px;
  .btn {
    padding: 9px 73px;
    border-color: $secondaryColor;
    background-color: $secondaryColor;
    font-size: 9px;
    &:hover, &:active, &:active:hover {
      background-color: #FA5362;
    }
  }
}

.home-info {
  background-color: #fafafa;
  .title {
    font-size: 72px;
    color: $mainColor;
    font-weight: 900;
    line-height: 70px;
  }
  .description {
    font-size: 22px;
    color: $mainColor;
  }
  .text-area {
    margin-top: 100px;
  }

  &>div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &:nth-child(1) {
      background-image: url($imageFolder + 'home/new/01_desktop/02_desktop_sections_1920x500px.jpg');

      .text-area {
        margin: 185px auto;
      }
    }

    &:nth-child(2) {
      background-image: url($imageFolder + 'home/new/01_desktop/03_desktop_sections_1920x500px.jpg');

      .row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
      }

      .text-area {
        margin: 185px auto;
        margin-left: auto;
        margin-right: 0;

        p {
          text-align: right;
        }
      }
    }

    &:nth-child(3) {
      background-image: url($imageFolder + 'home/new/01_desktop/04_desktop_sections_1920x500px.jpg');

      .text-area {
        margin: 185px auto;
      }
    }

    &:nth-child(4).mask_banner.extend-lp-mask {
      background-image: url($imageFolder + 'auth/new/extend/mask_banner.jpg');
      background-position: center;

      @media screen and (min-width: 769px) {
        background-position: 85% !important;
      }

      @media screen and (max-width: 768px) {
        p {
          text-shadow: 0 3px 8px rgba(0,0,0,.32);
          color: #fff;
        }
      }

      .text-area {
        margin: 185px auto;
        margin-left: auto;
        margin-right: 0;
        float: none !important;

        p {
          text-align: right;
        }
      }
    }

    &:nth-child(4).mask_banner.mobilcomdebitel-lp-mask {
      background-image: url($imageFolder + 'auth/new/mobilcomdebitel/Mask_banner_new.jpg');
      background-position: center;

      @media screen and (min-width: 769px) {
        background-position: 85% !important;
      }

      @media screen and (max-width: 768px) {
        p {
          text-shadow: 0 3px 8px rgba(0,0,0,.32);
          color: #fff;
        }
      }

      .text-area {
        margin: 185px auto;
        margin-left: auto;
        margin-right: 0;
        float: none !important;

        p {
          text-align: right;
        }
      }
    }

    &:nth-child(4).mask_banner.sparkasse-lp-mask {
      background-image: url($imageFolder + 'auth/new/sparkasse/mask_banner.jpg');
      background-position: center;

      @media screen and (min-width: 769px) {
        background-position: 85% !important;
      }

      @media screen and (max-width: 768px) {
        p {
          text-shadow: 0 3px 8px rgba(0,0,0,.32);
          color: #fff;
        }
      }

      .text-area {
        margin: 185px auto;
        margin-left: auto;
        margin-right: 0;
        float: none !important;

        p {
          text-align: right;
        }
      }
    }

    @media screen and (max-width: 640px) {
      &:nth-child(1) {
        background-image: url($imageFolder + 'home/new/02_mobile/02_mobile_sections_500x400px.jpg');

        .text-area {
          padding-left: 60px;
          margin: 30px auto 340px;
        }
      }

      &:nth-child(2) {
        background-image: url($imageFolder + 'home/new/02_mobile/03_mobile_sections_500x400px.jpg');

        .row {
          justify-content: flex-start;
          align-items: flex-start;
        }

        .text-area {
          margin: 30px auto 340px;

          &.partner-page {
            margin: 30px auto 380px;
          }

          p {
            text-align: left;
          }
        }
      }

      &:nth-child(3) {
        background-image: url($imageFolder + 'home/new/02_mobile/04_mobile_sections_500x400px.jpg');

        .text-area {
          margin: 30px auto 340px;
        }
      }
    }
  }
}

.home-logo {
  width: 300px;
  padding-top: 90px;
  margin: 0 auto 100px;
}

.membership-subtitle {
  color: #454553;
}

.membership-plans {
  margin-top: 50px;
  margin-bottom: 100px;

  .membership-plan {
    width: 295px;
    margin: auto;
    position: relative;
  }

  .buttons {
    height: 50px;
    border-radius: 4px;
    margin-bottom: 10px;
    &.special {
      border-color: #7CC8D4;
      background-color: #7CC8D4;
    }
    &.standard {
      border-color: #454553;
      background-color: #454553;
    }
    .text {
      font-weight: 900;
      color: #FFFFFF;
      font-size: 12px;
      text-align: center;
      padding-top: 18px;
    }
  }
  .content {
    height: 450px;
    border: 1px solid #454553;
    border-radius: 14px;
    box-sizing: border-box;
    padding: 30px;

    ul {
      padding-left: 25px;
      li {
        padding-top: 15px;
        font-weight: 600;
        font-size: 14px;
      }
    }
    .bottom {
      position: absolute;
      bottom: 30px;
      left: 30px;
      width: 80%;
      p {
        font-size: 18px;
        font-weight: bold;
        color: #454553;
        &.special {
          color: #D8130F;
        }
      }
      span {
        font-size: 12px;
        font-weight: 900;
        color: #CCCCD0;
        line-height: 25px;
      }
      .button {
        padding: 10px 0;
        width: 100%;
        &.special {
          background-color: #D8130F;
          border-color: #D8130F;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .home-background-image {
    .description {
      font-size: 18px;
    }
  }

  .mob-logo {
    width: 100%;
  }

  .home-logo {
    padding-top: 30px;
    max-width: 200px;
    margin-bottom: 60px;
  }

  .home-background-image {
    .home-subtitle {
      font-size: 22px;
      text-align: center;

      .increased_size {
        font-size: 37.5px;
      }
    }

    .description {
      font-size: 11px;
      margin-bottom: 30px;
    }

    .home-divider {
      max-width: 278px;
    }

    .btn {
      font-size: 14px;
      padding: 8px 20px;
    }
  }

  .home-info {
    .title {
      line-height: normal;
      font-size: 42px;
      margin-bottom: 5px;
    }
    .description {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .membership-subtitle {
    font-size: 28px;
  }

  .membership-plans {
    margin-top: 10px;
  }
}