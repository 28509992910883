@media screen and (max-width: 1800px) {
  .container-fix {
    max-width: 1170px;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .col-md-5ths {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-5ths {
    width: 20%;
    float: left;
  }
}

@media (max-width: 992px) {

  html {
    body {
      .wrap {
        padding-bottom:280px;
        &.home--wrap {
          padding-bottom:280px;
        }
      }
    }
  }

  .no-navigation {
    .wrap {
      padding-bottom:220px;
      &.home--wrap {
        padding-bottom:220px;
      }
    }
    .pc-footer {
      height:220px;
      margin-top:-220px;
    }
  }
}

@media (max-width: 767px) {

  #preview-row #product-row {
    padding-left: 0 !important;
  }

  .info-popup {
    padding:5px;
  }

  .modal-mask {

    .modal-wrapper {
      padding:0 15px;

      .modal-container {
        padding: 0 !important;

        .modal-header {
          h3 {
            font-size: 16px;
            font-weight: 800;
            line-height:20px;
          }
        }

        .modal-body {

          label {
            margin-bottom: 0;
          }

          h4 {
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            margin: 15px auto;
          }

          .social-media {
            margin-left: 0;

            .media {
              width: 120px;
              height: 120px;
              margin: 0 0 6px 6px;
            }

          }

        }

      }
    }

  }

  // for col-**-**
  .col-xs-no-padding {
    padding: 0 !important;
    .row {
      // to get rid of necessary side margin
      margin: 0;
    }
  }

  .col-sm-5ths {
    width: 20%;
    float: left;
  }

  html {
    body {
      .wrap {
        padding-bottom: 355px;
        &.home--wrap {
          padding-bottom: 355px;
        }
      }
    }
  }

  &.no-navigation {
    .wrap {
      padding-bottom:295px;
      &.home--wrap {
        padding-bottom:295px;
      }
    }
    .pc-footer {
      height:235px;
      margin-top:-235px;
    }
  }

  .notifications {
    width: 100% !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto;

    span {
      width: 100%;
    }
  }

  h1 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .title-line {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 24px;
    &:after {
      margin-top: 20px;
    }
  }

  #toast-holder {
    left: 0 !important;
    right: 0;

    .toast-message {
      text-align: left;
      width: 100% !important;
    }
  }

}

@media screen and (max-width: 479px) {
  .info-popup {
    &.active {
      position: absolute;
      left:0;
      right:0;
    }

    i, span {
      width:auto;
      left:10px;
      right:10px;
      top:-25px;

      &:after, &:before {
        display: none;
      }
    }
    &:hover {
      &:not(.active) {
        i, span {
          display: none;
        }
      }
    }
  }
}