.loading-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  z-index: 9998;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  color: #fff;

  .table {
    display: table;
    height: 100%;
    text-align: center;

    .table-cell {
      display: table-cell;
      vertical-align: middle;

      .centered {
        position: relative;
        height: 185px;
        width: 134px;
        margin: auto;

        .loading_filled {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          display: inline-block;
          width: 134px;
          height: 0;
          margin: auto;
          overflow: hidden;
          -webkit-transition: height 0.1s;
          -moz-transition: height 0.1s;
          -ms-transition: height 0.1s;
          -o-transition: height 0.1s;
          transition: height 0.1s;

          img {
            position: relative;
            top: -185px;
            -webkit-transition: top 0.1s;
            -moz-transition: top 0.1s;
            -ms-transition: top 0.1s;
            -o-transition: top 0.1s;
            transition: top 0.1s;
          }
        }
      }

      img {
        height: 185px;
        width: 134px;
      }

      p {
        color: #FFFFFF;
        font-size: 28px;
        font-weight: 600;
      }

      .percents {
        color: #FFFFFF;
        font-size: 36px;
        font-weight: 800;
      }

    }
  }
}