.responsive-table {
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 8px 0;

    &:not(:first-child) {
      padding: 8px;
    }
    &:last-child {
      text-align: right;
    }
  }

  .btn-primary {
    position: relative;
    width: 180px;

    span {
      background-color: #fff;
      z-index: 10;
      color: $mainColor;
      position: absolute;
      padding: 13px 16px 13px;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 16px;
      text-transform: none;
      white-space: normal;
      text-align: left;
      width: 280px;
      left: -80px;
      top: 100%;
      margin-top: 20px;
      -webkit-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.3);
      display: none;

      &:after, &:before {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 8px;
        margin-left: -8px;
      }
      &:before {
        border-color: rgba(0, 0, 0, 0);
        border-bottom-color: rgba(0, 0, 0, 0.1);
        border-width: 9px;
        margin-left: -9px;
      }
    }

    &:hover {
      span {
        display: block;
      }
    }
  }

  thead {
    td {
      color: #999999;
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 16px;
      padding: 4px;
    }
  }

  tbody {
    tr {
      td {
        border-top: 1px solid #999;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 16px;

        &.date-column {
          white-space:nowrap;
        }

        &.name-column {
          padding-bottom:0;

          .input-inline-edit {
            display: inline-block;
            overflow: hidden;
            vertical-align: top;
            white-space: nowrap;
            max-width:180px;
            width:100%;

            span,
            input {
              max-width:150px;
              display: inline-block;
              vertical-align: top;
              overflow: hidden;
              text-overflow: ellipsis;
              font-weight:600;
            }
            input {
              max-width:140px;
              text-overflow: inherit;
            }
          }
        }


        &.image-column {
          width:100px;
          height:100px;

          div {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 40;
            overflow: hidden;

            img {
              position: absolute;
              top: -9999px;
              bottom: -9999px;
              left: -9999px;
              right: -9999px;
              margin: auto;
              max-width:200px;
            }
            &:hover {
              img {
                max-width:100px;
              }
            }
          }
        }

        &.social-column {
          div {
            display: inline-block;
          }
          input {
            border: none;
          }
          i {
            cursor: pointer;
            padding-right:8px;
          }
        }

        &.actions-column {
          white-space: nowrap;
          a {
            padding:8px;
            display: inline-block;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 992px) {
  .responsive-table {
    display: block;

    thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tbody {
      tr {
        border: none;

        &:not(:last-child) {
          border-bottom: 1px solid #999;
        }

        td {
          border: none;
          position: relative;
          padding:16px 0;

          &:not(:first-child) {
            padding:12px 0;
          }

          &:last-child {
            text-align: left;
          }

          &:before {
            width: 70px;
            display: inline-block;
            white-space: nowrap;
            content: attr(data-title);
            color: #999999;
            font-size: 10px;
            font-weight: 600;
            letter-spacing: 1px;
            line-height: 16px;
          }

          .btn {
            width:70%;
            max-width:250px;

            &:hover {
              span {
                display: none;
              }
            }
          }


          &.image-column {
            width:auto;
            height:auto;
            padding-bottom: 0;

            div {
              width:100px;
              height:100px;
              display: inline-block;
              vertical-align: top;
            }
          }

          &.social-column {

            div {
              display: inline-block;
            }
          }

          &.actions-column {
            a {
              padding:0 8px;
            }
          }

        }
      }
    }

  }
}