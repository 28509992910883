.product-design {
  color: $mainColor;

  #preview-wrapper {
    .canvas-container {
      margin: 15px auto 0;
    }
    .zoom-input {

      // rewrite hack for photo club
      .col-sm-2,
      .col-xs-2 {
        width: 0;
      }

      // rewrite hack for photo club
      .range { // col-sm-10 & col-xs-10
        width: 100%;
        text-align: center;

        span {
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          padding-right: 24px;
        }

        [type=range] {
          display: inline-block;
          -webkit-appearance: none;
          width: 202px;
          height: 10px;
          background: #f1f1f2;
          outline: none;
          opacity: 0.7;
          margin: 17px auto 0;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          border-radius: 10px;
          -webkit-box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
          -moz-box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.3);
          box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.3);

          &:hover {
            opacity: 1;
          }

          &::-webkit-slider-thumb {
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            border: 1px solid #000;
            -webkit-appearance: none;
            appearance: none;
            width: 24px;
            height: 24px;
            background: #fff;
            cursor: pointer;
          }

          &::-moz-range-thumb {
            border: 1px solid #000;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            width: 24px;
            height: 24px;
            background: #fff;
            cursor: pointer;
          }
        }
      }

      .btn-rotate {
        display: none;
      }
    }

  }

  .white-block {
    padding: 0 16px 16px;

    .money-button {
      max-width: 440px;
      margin: auto;

      button {
        display: block;
        width: 100%;
      }
    }

    .options {
      padding: 0 24px;

      h4 {
        font-size: 18px;
        font-weight: 600;
        line-height: 16px;
        margin: 24px 0 15px;
      }
      ul {
        padding-left: 0;
        margin-bottom: 0;

        li {
          list-style-type: none;
          .custom-radio {
            label {
              font-size: 12px;
              font-weight: 600;
              letter-spacing: 1px;
              line-height: 16px;
              padding: 18px 24px;
              cursor: pointer;
              width: 100%;
              border: 1px solid transparent;
              position: relative;

              &.active,
              &:hover {
                border: 1px solid $activeColor;
                cursor: pointer;
              }
            }
          }

          &.active {
            .custom-radio {
              label {
                border: 1px solid $activeColor;
                cursor: pointer;
              }
            }
          }
        }
      }
      .format-select {
        ul {
          li {
            .custom-radio {
              label {
                span {
                  position: absolute;
                  right: 24px;
                  color: #999;
                }
              }
            }
          }
        }
      }
      .border-select {
        ul {
          li {
            padding-right: 20px;
            display: inline-block;

            .custom-radio {
              label {
                width: 120px;
                height: 112px;
                vertical-align: middle;
                white-space: nowrap;
                padding: 80px 10px 0;

                img {
                  left: 0;
                  right: 0;
                  margin: auto;
                  top: 5px;
                  position: absolute;
                  width: 100%;
                  display: block;
                  max-width: 140px;

                }
              }
            }
          }
        }
      }
    }
  }

  #dpi-alert-box {
    margin: 30px auto 0;
    opacity: 0.8;
    text-align: center;
    width: 260px;
    padding: 5px;
    word-break: keep-all;
  }

}

@media screen and (max-width: 991px) {
  .product-design {

    .modal-container {
      width: auto;
    }

    .preview-container {
      position: relative;
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 20;
      }
    }

    .canvas-container {
      width: 80% !important;
      max-width: 400px;
      height: auto !important;

      .lower-canvas {
        position: relative !important;
      }

      canvas {
        width: 100% !important;
        height: auto !important;
      }
    }

    .preview-modal-footer {
      text-align: center;
      display: flex;
      flex-direction: row;

      .btn-default {
        font-weight: 600;
        line-height: 16px;
        border: none;
        text-transform: none;
        color: $activeColor;
        font-size: 12px;
        letter-spacing: 1px;
        text-decoration: none;
      }

      button {
        flex-grow: 1;
      }
    }

    .white-block {
      padding: 16px;
      margin-bottom: 16px;

      #preview-wrapper {
        margin: 0 15px;

        .zoom-input {
          .range {
            display: none;
          }
        }

      }
      .change-section-offer {
        text-align: center;
        color: $mainColor;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 16px;
        margin: 22px 0 0;
        a {
          color: $activeColor;
        }
      }

      .options {
        .format-select,
        .border-select {
          padding: 0;
        }
        h4 {
          font-size: 14px;
          font-weight: 600;
          line-height: 24px;
          margin: 0;
        }

        .border-select {
          ul {
            text-align: left;
            margin-top: 16px;

            li {
              padding-right: 0;
              width: 50%;
            }
          }
        }
      }

    }

    .modal-body {
      margin: 0;
    }

  }
}

@media screen and (max-width: 768px) {

  .product-design {

    .preview-modal-footer {
      .btn-default {
        font-size: 12px;
      }

      button {
        flex-grow: 1;
      }
    }
    .canvas-container {
      canvas {
        margin: auto !important;
        display: block !important;
      }
    }

    #preview-wrapper {
      margin: 0;

      .preview-label {
        p {
          width: 197px;
          font-size: 10px;
          font-weight: 600;
          line-height: 16px;
          text-align: center;
          margin: 17px auto 0;
        }
      }

      .zoom-input {
        .range {
          [type=range] {
            width: 162px;
          }
        }
      }
    }

    .white-block {
      #preview-wrapper {
        margin: 0;
      }

      .options {
        .format-select,
        .border-select {
          padding: 0 16px;
          ul {
            li {
              .custom-radio {
                &.borders {
                  label {
                    padding: 140px 3px 0;
                    height: 170px;
                    width: 250px;
                    img {
                      margin: 0;
                      top: 0;
                      max-width: 240px;
                    }
                  }
                }
              }
            }
          }
        }
        h4 {
          margin: 24px 0 0;
        }
      }

    }

  }
}

@media screen and (max-width: $screen-xs-min) {
  .product-design {

    .white-block {

      .options {

        .border-select {
          ul {

            li {
              .custom-radio {
                &.borders {
                  label {
                    padding: 112px 3px 0;
                    height: 138px;
                  }
                }
              }
            }
          }
        }
      }
    }

  }

}