.mixpix-product-page {

  img {
    max-width: 100%;
    margin: auto;
  }

  .shop-landing {
    background-image: url($imageFolder + '/shop/products/mixpix/header.jpg');
    background-position: bottom right;

    .right-side {
      padding: 15px 35px;
      margin-right: -10px;
      max-width: 465px;

      h3 {
        color: #4A4A4A;
        font-family: "Open Sans";
        font-size: 26px;
        font-weight: bold;
        line-height: 32px;
      }

      ul {
        padding-left: 25px;
        margin-bottom: 20px;

        li {
          color: #4A4A4A;
          font-family: "Open Sans";
          font-size: 16px;
          line-height: 21px;
        }
      }

      label {
        width: auto;
        padding: 15px 40px;
        font-size: 16px;
      }
    }
  }

  .badges {
    border-color: #F10408;
    background-color: #F10408;
    height: 88px;
    bottom: 30px;
    top: auto;
    width: auto;
    right: 0;

    &:before {
      border-right: 26px solid #F10408;
      border-bottom: 41px solid transparent;
      border-top: 46px solid transparent;
    }
    .size {
      color: #FFFFFF;
      font-family: "Open Sans";
      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }
    .price {
      color: #FFFFFF;
      font-family: "Open Sans";
      font-size: 24px;
      font-weight: bold;
      line-height: 33px;
      text-align: center;
    }
    .sub {
      color: #FFFFFF;
      font-family: "Open Sans";
      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }
  }

  .preview-container {
    position: relative;
    margin-left: -40px;
    margin-top: 10px;
    display: inline-block;

    img {
      margin: 0;
    }
  }

  .trusted-shop {
    text-align: center;
    width: 100%;

    .col-md-4, .col-sm-4 {
      width: auto;
      float: none;
      display: inline-block;
      vertical-align: middle;
    }
    .content {
      width: 310px;
    }
  }

  .mixpix-body {
    background-color: #fff;
    text-align: center;

    h2 {
      color: #4A4A4A;
      font-family: "Open Sans";
      font-size: 34px;
      line-height: 46px;
      text-align: center;
      margin-top: 45px;
      margin-bottom: 0;
    }

    .sidelines {
      margin-top: 13px;
      margin-bottom: 45px;
      color: #4A4A4A;
      font-family: "Open Sans";
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    .mixpix-block-container {
      padding: 55px 0 56px;
      font-size: 0;

      .mixpix-block {
        display: inline-block;
        max-width: 320px;
        margin: 0 9px;
        text-align: left;
        vertical-align: top;
        img {
          margin-bottom: 19px;
        }
        strong {
          display: block;
          color: #4A4A4A;
          font-family: "Open Sans";
          font-size: 16px;
          font-weight: bold;
          line-height: 22px;
          margin-bottom: 5px;
        }
        p {
          color: #4A4A4A;
          font-family: "Open Sans";
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .mixpix-blocks-button {
      margin-bottom: 40px;
    }

    .gif-paragraph {
      color: #4A4A4A;
      font-family: "Open Sans";
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      max-width: 488px;
      margin: 0 auto 70px;
    }

    .video-p-container {
      text-align: left;

      label {
        margin-bottom: 50px;
      }
    }

    .video-paragraph {
      color: #4A4A4A;
      font-family: "Open Sans";
      font-size: 14px;
      line-height: 18px;
      padding: 37px 25px 0 0;
      margin-bottom: 15px;
    }

    .video-block {
      box-sizing: border-box;
      margin-top: 12px;
      margin-bottom: 190px;
    }

  }
}

@media (max-width: 992px) {

  .mixpix-product-page {

    .shop-landing {
      background-image: none;
    }
  }

}

@media (max-width: 767px) {
  .mixpix-product-page {

    .badges {
      right: -15px;
    }

    .preview-container {
      margin: 25px auto 20px;
      width: 100%;
    }

    .shop-landing {

      .right-side {
        margin: auto;
        padding: 15px 8px;

        h3 {
          color: #4A4A4A;
          font-family: "Open Sans";
          font-size: 20px;
          font-weight: bold;
          line-height: 27px;
          margin-bottom: 5px;
        }

        ul {
          padding-left: 20px;
          margin-bottom: 10px;
          li {
            color: #4A4A4A;
            font-family: "Open Sans";
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 8px;
            &:before {
              position: absolute;
            }
          }
        }

        label {
          padding: 9px 10px;
          line-height: 30px;
          font-size: 15px;
          height: auto;
          width: 100%;
        }
      }
    }

    .trusted-shop {
      margin-top: 15px;
      display: block;
      .content {
        margin-bottom: 27px;
      }
    }

    .mixpix-body {

      h2 {
        color: #4A4A4A;
        font-family: "Open Sans";
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        margin-top: 20px;
      }

      .sidelines {
        color: #4A4A4A;
        font-family: "Open Sans";
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        position: relative;
        margin: 7px 20px 25px;

        span {
          margin: 0 40px;
        }

        &:before {
          left: 0;
          right: 0;
          width: 100%;
          margin: auto;
        }
      }

      .mixpix-block-container {
        padding-top: 0;
        padding-bottom: 0;

        .mixpix-block {
          margin: 0;

          img {
            margin-bottom: 22px;
          }
          strong {
            padding: 0 8px;
          }
          p {
            padding: 0 8px;
            margin-bottom: 55px;
          }
          &:last-child {
            p {
              margin-bottom: 32px;
            }
          }
        }
      }

      .mixpix-blocks-button {
        margin-bottom: 40px;
      }

      .gif-paragraph {
        margin-bottom: 55px;
      }

      .video-p-container {
        text-align: center;

        label {
          margin-bottom: 50px;
        }
      }

      .video-paragraph {
        color: #4A4A4A;
        font-family: "Open Sans";
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        padding: 25px 10px 0;

      }

      .video-block {
        margin: 12px auto 0;
      }

      .mixpix-animated {
        margin-bottom: 20px;
      }

    }
  }
}
