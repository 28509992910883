.product-select {

  .uploaded-image {

    .image-block {
      text-align: center;

      .image-wrap {
        margin:24px 9px 24px 19px;
        overflow: hidden;
        img {
          width:100%;
          
          &.rotate-90 {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            transform: rotate(90deg);
          }
          &.rotate-180 {
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transform: rotate(180deg);
          }
          &.rotate-270 {
            -webkit-transform: rotate(270deg);
            -moz-transform: rotate(270deg);
            -ms-transform: rotate(270deg);
            -o-transform: rotate(270deg);
            transform: rotate(270deg);
          }
        }
      }

      .image-links {
        position: absolute;
        left:100%;
        bottom:20px;
        width:100%;
        z-index: 20;
        text-align: left;

        ul {
          padding-left: 0;
          margin-bottom: 0;
          white-space: nowrap;

          li {
            list-style-type:none;
            display: inline-block;

            &:not(:first-child) {
              &:before {
                content:'|';
                padding-right:4px;
                color: $activeColor;
              }
            }

            input {
              display: none;
            }

            a,label {
              color: $activeColor;
              font-size:12px;
              font-weight:600;
              letter-spacing: 1px;
              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }

          }
        }
      }
    }
    .image-info-block {
      padding:24px 24px 24px 0;

      .image-head {
        color: $mainColor;
        font-size: 18px;
        font-weight: 600;
        white-space: nowrap;

        .input-group {
          max-width:300px;
        }

        .image-name {
          display: inline-block;
          vertical-align: middle;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 90%;
        }

        a {
          color: $stepBg;
          font-size:12px;
          display: inline-block;
          vertical-align: middle;
        }

        button {
          height:34px; // bootstrap input height
          min-width:auto;
        }

      }
      .image-info {
          color: $stepBg;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 1px;

        span {
          &:not(:first-child) {
            &:before {
              content: '|';
              padding-right:4px;
            }
          }
        }
      }
    }
  }

  .product-list {

    h4 {
      color: $mainColor;
      font-size: 18px;
      font-weight: 600;
      line-height: 16px;
      margin:41px 0 8px;
    }

    .product-slider {
      height: 220px;
      width:100%;
      position: relative;
      padding:0 46px;

      .swiper-container {
        padding:15px 2px;

        .swiper-slide {
          width: auto;

          .product-block {
            display: inline-block;
            height:184px;
            width:152px;
            position: relative;
            text-align: center;
            &:hover,
            &.active {
              border: 1px solid $activeColor;
              cursor: pointer;
            }
            img {
              display: block;
              margin:24px auto 0;
            }
            span {
              position: absolute;
              bottom:21px;
              left:0;
              right:0;
              font-size: 12px;
              font-weight: 600;
              letter-spacing: 1px;
              text-align: center;
            }
          }

        }
      }

      .slider-button-next,
      .slider-button-prev {
        position: absolute;
        top: -2px;
        bottom: 0;
        z-index: 10;
        cursor: pointer;
        color: $mainColor;
        font-size: 28px;
        width: 30px;

        &.swiper-button-disabled {
          opacity:0.3;
        }
        i {
          background: #fff;
          border:1px solid $mainColor;
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          text-align: center;
          top: 0;
          bottom: 0;
          height: 184px;
          line-height: 184px;
        }
      }

      .slider-button-next {
        right: 0;
      }
      .slider-button-prev {
        left:0;
      }

    }

    ul {
      padding-left:0;
      li {
        list-style-type: none;
        .custom-radio {
          label {
            padding:18px 15px;
            font-size: 12px;
            font-weight: 600;
            letter-spacing: 1px;
            width:100%;
          }
        }

        &.active {
          .custom-radio {
            label {
              border: 1px solid $activeColor;
              cursor: pointer;
            }
          }
        }
      }
    }

  }
}

@media screen and (max-width: 767px) {
  .product-select {

    .uploaded-image {
      position: relative;
      margin:0 15px;

      .image-block {
        position: initial;
        padding-bottom:60px;

        .image-wrap {
          margin:16px 0 0;
        }

        .image-links {
          text-align: center;
          left:0;
          bottom:13px;
          right:0;
          padding:0 16px 0;
          margin:auto;

          ul {
            border-top: 1px solid $stepBg;
            padding-top:10px;
            li {
              a,label {
                font-size: 12px;
                font-weight: 600;
                letter-spacing: 1px;
                display: inline-block;
              }
            }
          }
        }
      }

      .image-info-block {
        padding:8px 12px 12px 0;
        .image-head {
          margin-right:16px;
          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

    }
    .product-list {
      margin:15px 0 32px;
      background-color: #ffffff;
      -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
      padding:15px;

      ul {
        margin-bottom: 0;
      }

      h4 {
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        margin:0 0 17px;
        text-align: center;
      }

    }
  }
}