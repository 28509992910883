.images {

  .payout-modal {
    .modal-container, .modal-wrapper .close-modal {
      width: 700px;
    }
    .modal-body {
      padding: 0 15px;;
    }
  }

  .conditions {
    margin-top:0;
  }

  .succeed-message {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 23px;

    &:before {
      font-size: 18px;
      line-height: 18px;
      color: $secondaryColor;
      font: normal normal normal 14px/1 FontAwesome;
      content: '\f00c';
      padding: 8px;
    }
  }

  .description {
    color: $mainColor;
    font-size: 24px;
    margin-top: 0;
    letter-spacing: 1px;
    line-height: 32px;
    font-weight: 600;

    strong {
      font-weight: 700;
    }
  }

  .btn.upload-button {
    text-transform: uppercase;
    background-color: $activeColor;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: 19px 39.5px 21px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    border: none;

    input {
      display: none;
    }
  }

  .sm-upload-button {
    text-align: right;

    .btn.upload-button {
      color: #FFFFFF;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      text-align: center;
      padding: 10px 15px;
      margin-bottom:20px;
    }
  }

  .btn-custom {
    //color: $primaryColor;
    //border-color: $primaryColor;
  }

  .make-money-uvp {
    padding-left: 0;
    color: $mainColor;
    font-size: 12px;

    li {
      list-style: none;
      padding: 12px 0;
      color: $mainColor;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 16px;
      span {
        font-weight: 800;
      }
      &:before {
        content: "\f058";
        height: 16px;
        width: 12px;
        margin-right: 8px;
        color: $stepBg;
        font-family: FontAwesome, serif;
        font-size: 12px;
      }
    }
  }

  .images-list {
    padding: 0 16px 8px;
    margin-bottom: 20px;

    .load-more {
      color: $activeColor;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 16px;
      display: block;
      width: 100%;
      text-align: center;
      border: none;
      padding: 13px 0;
      border-top: 1px solid #999;
    }
  }

  .upload-image {
    margin-bottom: 20px;

    .button label {
      font-size: 12px;
      padding: 6px 12px;
    }
  }

  .bonus-block {
    padding:16px;
  }

  .modal-body {
    label {
      font-size: 12px;
      display: inline-block;
      position: relative;
      font-weight:600;
      color: $mainColor;
      padding-left: 0;
    }

    input[type="radio"] {
      position: absolute;
      opacity: 0;
      + label {
        font-weight:600;
        &:before {
          content: '';
          border-radius: 100%;
          border: 1px solid #999;
          display: inline-block;
          width: 20px;
          height: 20px;
          position: relative;
          margin-right: 10px;
          vertical-align: bottom;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }
      &:checked {
        + label {
          font-weight:600;
          &:before {
            background-color: $activeColor;
            box-shadow: inset 0 0 0 5px #fafafa;
          }
        }
      }
      &:focus {
        + label {
          &:before {
            outline: none;
          }
        }
      }
      + label {
        margin-bottom: 0;
        &:empty {
          &:before {
            margin-right: 0;
          }
        }
      }
    }

  }

  #share-app {
    h1 {
      margin-top: 0;
      padding-top: 0;
      font-size:24px;
      display: block !important;
    }
    form {
      margin-bottom: 0;
    }
    .control-buttons {
      .form-group:last-child {
        margin-bottom: 0;
      }
      #fb-post-controls {
        .form-group:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .more-details {
    padding-bottom: 0 !important;

    &:before {
      display: none;
    }
    .more-details__link {
      border-top: 1px solid #999999;
      padding: 10px 0;
      display: block;
      text-align: center;
      color: $activeColor;

      &.no-border {
        border-top: none;
      }
    }
  }

}

@media screen and (max-width: $screen-md-min) {
  .images {
    i, .input-inline-edit a  {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 767px) {
  .images {
    padding-bottom: 30px;

    .btn.upload-button {
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      padding:10px 0;
    }

    .payout-modal {
      .modal-container, .modal-wrapper .close-modal {
        width: 300px;
      }
    }
    .succeed-message {
      font-size: 14px;
      line-height: 24px;
    }

    .description {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    .step1-image {
      img {
        max-width: 160px;
        padding: 0 0 20px;
      }
    }

    .upload-image {
      .product-box {
        text-align: center;
        img {
          margin: auto;
        }
      }

      .button {
        text-align: center;
        padding: 15px 16px 20px;
      }

    }
  }
}