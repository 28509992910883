// All important, because rewriting by configurator_creame_alt.css
.preview-login-button {
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
  background-color: $primaryColor !important;
  border: 1px solid $primaryColor !important;
  &:hover, &:focus, &.focus, &:active, &:active:hover {
    background-color: $activeColor !important;
    border-color: $activeColor !important;
    color: #ffffff !important;
  }
}

.nav > li {
    > a {
    &:hover {
      background-color: transparent !important;
    }

    &:focus {
      background-color: transparent !important;
    }
  }

  span, span > a {
    font-weight: 600;
  }
}

.dropdown-menu {
  background-color: $darkerNavBackground;
  *, li a {
    color: $darkNavTextColor;
  }

  li:first-child {
    border-bottom: 1px solid $darkNavTextColor;
  }
}

.navbar {
  background-color: $topBarBackground !important;
  color: #4A403F;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 5px;
  border-radius: unset !important;
  min-height: 36px !important;

  .navbar-header {
    float: left;
    margin-left: 15px;
    height: $navbarHeight;
  }

  &.only-logo {
    .navbar-header {
      float: inherit;
      text-align: center;

      &.award2018-header {
        text-align: left;
      }

      .navbar-brand {
        float: inherit;
        display: inline-block;
        img {
          display: inline-block;
        }
      }

    }
  }

  .email {
    word-spacing: 4px;
    position: relative;

    a, &.open {
      color: $darkNavTextColor;

      &:hover {
        color: $darkNavTextColor !important;
      }
    }

    .dropdown-menu {
      padding: 0;
      margin-top: 9px;
      min-width: 120px;

      > li > a {
        padding: 14px 20px;
        font-size: 12px;
        text-align: right;
        line-height: 1em;
      }
    }
  }
  .navigation {
    text-transform: uppercase;
    letter-spacing: 1px;

    li a {
      color: white;
    }
  }
  .navigation .active span {
    color: $activeColor;
    padding-bottom: 2px;
  }
}

#auth-app, #main-grid {
  > .navbar {
    margin-bottom: 0;
    box-shadow: none;
    border: 0;

    .navbar-nav > li > a {
      padding-top: 0;
      padding-bottom: 0;
    }

    .navbar-brand {
      height: auto;
    }
  }
}

.navbar-collapse {
  padding-top: 8px;
}

.navbar-nav > li {
  padding-right: 5px;
  position: inherit;
  display: inline-block;
  .primary {
    color: $activeColor;
  }
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: transparent;
  color: $activeColor;
}

.navbar-collapse {
  margin-left: 15px;
}

.navbar-fixed-bottom {
  margin-bottom: 0;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.3);

  .navbar-collapse {
    display: inline-block;
  }
}

.navbar-nav > li > a {
  &:hover {
    color: $activeColor !important;
  }
}

.logo-navigation {
  padding: 0 !important;
  float: none;
  display: inline-block;
  margin-left: 0 !important;
  img {
    padding-top: 8px;
    padding-right: 10px;
    width: $logoWidth;

    &.photokina-logo {
      width: 120px;
    }
  }
}

/** up to tablet **/
@media (max-width: $screen-md-min) {
  .logo-navigation {
    img {
      padding-top: 14px;
    }
  }
  .navbar-collapse {
    padding-top: 0;
  }

  .navbar .not-registered {
    margin-top: 10px;
  }

  .navbar {
    .email {
      padding-top: 10px;
      a {
        padding-right:0;
      }
    }
  }

  #auth-app, #main-grid {
    .navbar {
      height: $mobileNavbarHeight;
      z-index: 2000;

      .navbar-collapse {
        padding-right: 0;
        margin-right: 0;
      }
      .container {
        margin-left: 0;
        margin-right: 0;
        width: 100%;

        .email {
          padding-right: 15px;
        }
      }
      .navbar-header {
        margin-left: 0;
      }
    }
  }


}

@media (max-width: 768px) {
  .navbar-nav {
    text-align: right;
    .not-registered {
      margin-top: 0;
    }
  }

  .navbar-right {
    margin: 0 -15px 0 0;
    float: right;
  }

  .nav > li > a {
    padding: 15px;
  }

  .navbar-collapse.collapse {
    padding-top: 0;
  }

  .collapsing {
    padding: 0;
    margin: 0;
  }

  .navbar-toggle {
    float: left;
    margin-top: 0;
    margin-bottom: 0;
  }

  .mobile-menu-icon {
    display: inline-block;
    vertical-align: middle;
  }

  .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: white;
  }

  .navbar-nav .open .dropdown-menu {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.2);
    right: 0;
    left: auto !important;
    position: absolute;
    z-index:2000;
  }

  .navbar-nav .open .dropdown-menu {
    background-color: $darkerNavBackground;

    li > a {
      line-height: 14px;

      text-align: center;
    }
  }

  .navbar-collapse {
    margin-left: 0;
  }

  .navbar-brand {
    margin: 0 -15px;
  }

  .navbar .navigation {
    font-size: 11px;
    font-weight: 600;
    text-transform: none;
    margin-left: 0;
    letter-spacing: 0;
  }

  .navbar-fixed-bottom {
    .nav > li > a {
      position: relative;
      display: block;
      padding: 0 10px 0;
    }
    i {
      display: block;
      text-align: center;
    }
    .navbar-nav {
      text-align: center;
      margin: 0;
      display: flex;
      width: 100%;
      align-items: baseline;
    }
    .navbar-collapse {
      padding: 0;
      width: 100%;
    }
    .nav > li {
      position: relative;
      display: inline-block;
      padding: 10px;
      flex-grow: 1;
      border-right: 1px solid $darkNavTextColor;
      width: 33.3333%;

      &:last-child {
        border-right: none;
      }

      &.active {
        border-bottom: 2px solid $activeColor;
      }
    }
  }
}

@media (min-width: 768px) {
  .navbar-fixed-bottom {
    .navbar-collapse {
      padding-top: 0;
    }
  }
}

#mobile-nav {
  .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  &.navbar {
    height: auto;
  }

  .navigation .active span {
   border: none;
  }
}
