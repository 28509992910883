.photo-view {

  .row:nth-child(2) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  body {
    background-color: black !important;
  }
  h2 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #4A403F;
  }
  .col-sm-12 {
    h1 {
      margin-top: 60px;
      font-weight: bold
    }

    h2 {
      margin-bottom: 20px;
    }
  }
  .container {
    padding-top: 25px;
    margin-bottom: 70px;
  }
  .custom-container {
    background-color: #f7f7f9;
    border-radius: 8px;
    padding: 0;
    overflow: hidden;
    color: #434c52;

    .custom-font:nth-child(5) {
      img {
        width: 100%;
      }
    }

    .custom-h3 + p {
      img {
        margin-top: 30px;
        margin-bottom: 50px;
      }
    }

    .custom-btn {
      width: 250px;
      background-color: #f7f7f9;
      border-width: 2px;
      padding: 11px;
      border-radius: 30px;
      text-transform: uppercase;
      border-color: #a8a8aa;
      color: #434c52;
      font-weight: 600;
      margin: 25px auto 60px auto;
      display: block;
      -webkit-transition: border-color .3s; /* Safari */
      transition: border-color .3s;

      &:hover {
          border-color: #434c52;

        }
    }
  }

  .custom-last-three-articles {
    background-color: #f7f7f9;
    border-radius: 8px;
    overflow: hidden;
    padding: 0;
    color: #434c52;

    @media only screen and (min-width: 992px) {
      width: 23%;
      margin-left: 3%;
    }

    .more-links {
      p {
        color: #434c52;
        letter-spacing: 2px;
        font-size: small;
        margin-bottom: 25px;
        margin-top: 25px;
      }
      .line {
        opacity: 0.3;
        margin: 0;
      }
      h3 {
        text-transform: uppercase;
        line-height: 23px;
        letter-spacing: 0px;
        margin-top: 25px;
        margin-bottom: 25px;
      }
      .text {
        display: none;
      }
      a {
        display: none;
      }
    }
  }
  .text {
    color: #4A4A4A;
    font-size: 14px;
    .paragraph {
      padding: 15px 0;
      img {
        margin: 10px auto;
      }
    }
    .custom-font {
      font-size: larger;
      font-weight: bolder;
      margin: 0 !important;
      padding: 0 !important;

      img {

      }
    }
    .description {
      //padding-top: 25px;
      //font-weight: bold;
    }
    h3 {
      color: #4A403F;
      font-size: 18px;
      font-weight: 600;
    }
    .custom-h3 {
      margin: 35px 0 18px 0;
    }
    .custom-h3 center {
      font-size: 22px;
      text-align: left;
      font-weight: 500;
    }
  }
  .custom {
    padding: 30px;
  }
  .upload-photo-image {
    margin: 10px 0 30px;
  }
  .photo-button {
    padding: 10px 0 30px;
    label {
      font-size: 16px;
    }
  }
  .product-box {
    padding: 30px 0 0;
    img {
      margin: 0 auto;
      max-width: 200px;
    }
  }

  .upload-image {

    .block-title {
      font-size: 18px;
      color: #4A403F;
      font-weight: 600;
      line-height: 16px;
      margin-top: 27px;
    }

    .block-subtitle {
      color: #999999;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 16px;
    }

    .product-box, .text-right {
      clear: both;
      width: 100%;
      text-align: center;
    }
    label {
      width: auto;
      padding: 15px 25px;
      font-size: 16px;
    }
    .button {
      line-height: normal;
      padding: 20px 0 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .photo-view {
    .upload-image {
      .block-title {
        color: #999999;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        margin-top: 10px;
      }
      .block-subtitle {
        color: #999999;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 1px;
        line-height: 16px;
      }
      .button {
        .btn-primary {
          width: 100%;
        }
      }
    }
  }
}